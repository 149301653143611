import React from 'react';
import MyAssessment from '../Components/My-assessment/myAssessment';
import SelectTest from '../Components/Test-library/Test-results/selectTest';
import AddQuestion from '../Components/Test-library/Test-results/addQuestion';
import InvitePreview from '../Components/Test-library/Test-results/invitePreview';
import CreateQuestion from '../Components/Test-library/Test-results/createQuestion';
import CandidateTest from '../Components/Candidate-Test/candidateTest';
import MyQuestions from '../Components/My-Questions/myQuestions';
import TestReport from '../Components/My-assessment/test-report';
import Reports from '../Components/Reports/reports';
import Users from '../Components/Users/users';
import Settings from '../Components/Settings/settings';
import RegisterTest from '../Components/Candidate-Test/registerTest';
import Accounts from '../Components/Settings/profile';
import TanentUsers from '../Components/TanentUsers/tanentUsers';
import Main from '../utils/CodeEditor/main';
import SelectCodingTest from '../Components/Test-library/selectCodingTest';
// import CreateCodingQuestion from '../Components/CreateCodingQuestion/createCodingQuestion';
import CreateCodingQuestion from '../Components/CreateCodingQuestion/createCodingQuestion';
import CreateCodeText from '../Components/CreateCodingQuestion/createCodeText';
import GappeoTest from '../Components/Gappeo Tests/gappeoTest';
import MyQuestions1 from '../Components/My-Questions1/myQuestions1';
import MyTests from '../Components/My-tests/mytests';
import AssessmentReport from '../Components/My-assessment/assessmentReport';
const TestLibrary = React.lazy(() => import('../Components/Test-library/testLibrary'));


const routeItems = [
    { path: '/home', component: <TestLibrary />, layout: 'mainLayout' },
    { path: '/assessments', component: <MyAssessment />, layout: 'mainLayout' },
    { path: '/assessments-report', component: <AssessmentReport />, layout: 'mainLayout' },
    { path: '/gappeo-tests', component: <GappeoTest />, layout: 'mainLayout' },
    { path: '/reports', component: <Reports />, layout: 'mainLayout' },
    { path: '/test-report', component: <TestReport />, layout: 'mainLayout' },
    { path: '/settings', component: <Settings />, layout: 'mainLayout' },
    { path: '/users', component: <Users />, layout: 'mainLayout' },
    { path: '/my-questions', component: <MyQuestions />, layout: 'mainLayout' },
    { path: '/select-coding-test', component: <SelectCodingTest />, layout: 'mainLayout' },
    { path: '/home/select-test', component: <SelectTest />, layout: 'mainLayout' },
    { path: '/home/add-question', component: <AddQuestion />, layout: 'mainLayout' },
    { path: '/home/invite-preview', component: <InvitePreview />, layout: 'mainLayout' },
    { path: '/create', component: <CreateQuestion />, layout: 'mainLayout' },
    { path: '/create-coding-question', component: <CreateCodingQuestion />, layout: 'mainLayout' },
    { path: '/profile', component: <Accounts />, layout: 'mainLayout' },
    { path: '/tanents', component: <TanentUsers />, layout: 'mainLayout' },
    { path: '/add-text', component: <CreateCodeText />, layout: 'mainLayout' },

    { path: '/candidate-test/:token', component: <CandidateTest />, layout: 'candidate-layout' },
    { path: '/candidate-invite-test/:token', component: <RegisterTest />, layout: 'candidate-layout' },
    { path: '/coding-test', component: <Main />, layout: 'candidate-layout' },
    { path: '/my-questions1', component: <MyQuestions1 />, layout: 'mainLayout' },
    { path: '/my-tests', component: <MyTests />, layout: 'mainLayout' }

]

export default routeItems