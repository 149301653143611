import { returnErrors } from './errorAction';
import { GET_PLANS, GET_ACCOUNT_DETAIL, VALIDATE_COUPON_CODE, APPLY_CODE, UPDATE_PROFILE } from '../Types/types';
import { getApiCall, postApiCall } from '../../utils/request';


export const getAccountDetail = () => async (dispatch) => {
    try {
        const response = await getApiCall(`setting/account-details`);
        if (response?.status === 200) {
            return dispatch({ type: GET_ACCOUNT_DETAIL, payload: response.data });
        }
        else {
            returnErrors(dispatch, response?.data?.responseMessages[0]?.message, response?.status)
        }
    }
    catch (error) {
        returnErrors(dispatch, error.response?.data?.responseMessages[0]?.message, error?.response?.status);
    }
}

export const getPlans = () => async (dispatch) => {
    try {
        const response = await getApiCall(`setting/get-payment-plans`);
        if (response?.status === 200) {
            return dispatch({ type: GET_PLANS, payload: response.data.paymentPlans });
        }
        else {
            returnErrors(dispatch, response?.data?.responseMessages[0]?.message, response?.status)
        }
    }
    catch (error) {
        returnErrors(dispatch, error.response?.data?.responseMessages[0]?.message, error?.response?.status);
    }
}

export const validateCoupon = (code) => async (dispatch) => {
    try {
        const response = await getApiCall(`setting/validate-coupon-code?CouponCode=${code}`);
        if (response?.status === 200) {

            return dispatch({ type: VALIDATE_COUPON_CODE, payload: response.data });
        }
        else {
            returnErrors(dispatch, response?.data?.responseMessages[0]?.message, response?.status)
        }
    }
    catch (error) {
        returnErrors(dispatch, error.response?.data?.responseMessages[0]?.message, error?.response?.status);
    }
}

export const applyCoupon = (code) => async (dispatch) => {
    try {
        const response = await getApiCall(`setting/apply-coupon-code?CouponCode=${code}`);
        if (response?.status === 200) {
            returnErrors(dispatch, 'Coupon applied succesfully', response?.status)
            return dispatch({ type: APPLY_CODE, payload: response.data });
        }
        else {
            returnErrors(dispatch, response?.data?.responseMessages[0]?.message, response?.status)
        }
    }
    catch (error) {
        returnErrors(dispatch, error.response?.data?.responseMessages[0]?.message, error?.response?.status);
    }
}

export const updateCompanyProfile = (obj) => async (dispatch) => {
    const data = new FormData();
    data.append('tenantId', obj?.tenantId);
    obj.image && data.append('file', obj.image);
    try {
        const response = await postApiCall(`setting/update-account-details?DisplayName=${obj.name}&websiteName=${obj.website}&languageId=${obj.languageId}`, data);
        if (response?.status === 200) {
            return dispatch({ type: UPDATE_PROFILE, payload: response.data });
        }
        else {
            returnErrors(dispatch, response?.data?.responseMessages[0]?.message, response?.status)
        }
    }
    catch (error) {
        returnErrors(dispatch, error.response?.data?.responseMessages[0]?.message, error?.response?.status);
    }
}


