import React, { useState, Fragment } from 'react'
import '../My-Questions1/myQuestions1.scss';
import { Table } from 'react-bootstrap';
import '../Users/users.scss';
import { useTranslation } from 'react-i18next';

const QuestionPage = ({ onBack }) => {

    const [edit, setedit] = useState([
        { id: 1, Title: 'How to implement object overloading', Type: 'MCQ', Duration: '1min', tags: '#java,#OOPS', EyeIcon: 24, Editicon: 32, Deleteicon: 32, },
        { id: 2, Title: 'How to implement object overloading', Type: 'Coding', Duration: "1min", tags: '#java,#OOPS', EyeIcon: 24, Editicon: 32, Deleteicon: 32, },
        { id: 3, Title: 'How to implement object overloading', Type: 'Audio', Duration: '1min', tags: '#java,#OOPS', EyeIcon: 24, Editicon: 32, Deleteicon: 32, },
        { id: 4, Title: 'How to implement object overloading', Type: 'Video', Duration: '1min', tags: '#java,#OOPS', EyeIcon: 24, Editicon: 32, Deleteicon: 32, },
    ]);
    const { t } = useTranslation();


    // const [edit , setedit] = useState([]);

    return (
        <div className='PageContext' >
            <Fragment>
                <div className='d-flex align-items-center Header'>
                    <img className='backarrow' src='/images/back.svg' onClick={onBack} alt='' />
                    <h2 className='PageHeading'>{t('My Questions',{ns: 'mytest'})}: Java</h2>
                    <button className='QueButton' >{t('ADD QUESTION',{ns: 'mytest'})}</button>
                </div>
                &nbsp;&nbsp;
                <div>
                    <input className='MyQuesSearch' placeholder={t('Search for questions or tags',{ns: 'mytest'})} />
                    &nbsp;&nbsp;
                    <div className='table-admin'>
                        <Table responsive>
                            <thead className='TableHeader'>
                                <tr>
                                    <th className='KnowledgeArea'>{t('Question Title',{ns: 'mytest'})}</th>
                                    <th>{t('Question Type',{ns: 'mytest'})}</th>
                                    <th>{t('Duration',{ns: 'mytest'})}</th>
                                    <th>{t('Tags',{ns: 'mytest'})}</th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                </tr>
                            </thead>
                            {edit.length === 0 ? (
                                <div className='NoDataMessage'>
                                    <h4>{t('There is no data in the table',{ns: 'mytest'})}.</h4>
                                </div>
                            ) : (

                                <tbody className="table-body">
                                    {edit.map(item => (
                                        <tr key={item.id} className="TableBody">
                                            <td className='edittext' >{item.Title}</td>
                                            <td className='edittext' >{item.Type}</td>
                                            <td className='edittext' >{item.Duration}</td>
                                            <td className='edittext' >{item.tags}</td>
                                            <td className='edittext editicon'><img src='/images/carbon_view-filled.svg' alt='' /></td>
                                            <td className='edittext editicon'><img src='/images/iconamoon_edit-fill.svg' alt='' /></td>
                                            <td className='edittext editicon'><img src='/images/mdi_delete.svg' alt='' /></td>
                                        </tr>
                                    ))}
                                </tbody>
                            )}
                        </Table>
                    </div>
                </div>
            </Fragment>
        </div>
    )
}

export default QuestionPage
