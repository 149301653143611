import { RESET_GAPPEO_TEST, GET_CATEGORIES, GET_GAPPEO_TESTS, GET_NEXT_GAPPEO_TESTS, EMPTY_GAPPEO_TEST, RESET_SAMPLE_QUESTIONS, GET_SAMPLE_QUESTIONS, FILTERED_QUESTIONS } from '../Types/types';



const intialState = {
    isLoading: true,
    gappeoTests: [],
    quesTypes: [{
        type: 'MCQ',
        id: 0
    },
    {
        type: 'Coding',
        id: 1
    },
    {
        type: 'BigFive',
        id: 2
    },
    {
        type: 'MBTI',
        id: 3
    }
    ],
    sampleQuestions: [],
    loading: true
};

export default function gappeoTestReducer(state = intialState, action) {
    let stateCopy = JSON.parse(JSON.stringify(state));
    switch (action.type) {
        case RESET_GAPPEO_TEST:
            stateCopy.isLoading = true;
            return stateCopy;
        case GET_CATEGORIES:
            stateCopy.categories = action.payload.categories;
            return stateCopy;
        case GET_GAPPEO_TESTS:
            stateCopy.gappeoTests = action.payload.testList;
            stateCopy.languageData = action.payload.languages;
            stateCopy.total = action.payload.totalCount;
            stateCopy.isLoading = false;
            return stateCopy;
        case GET_NEXT_GAPPEO_TESTS:
            stateCopy.gappeoTests = stateCopy.gappeoTests.concat(action.payload.testList);
            stateCopy.total = action.payload.totalCount;
            stateCopy.isLoading = false;
            return stateCopy;
        case EMPTY_GAPPEO_TEST:
            stateCopy.gappeoTests = stateCopy.gappeoTests.concat(action.payload);
            stateCopy.isLoading = false;
            return stateCopy;
        case GET_SAMPLE_QUESTIONS:
            stateCopy.sampleQuestions = action.payload;
            stateCopy.sampleQuestionsCopy = action.payload;
            stateCopy.loading = false;
            return stateCopy;
        case RESET_SAMPLE_QUESTIONS:
            stateCopy.loading = true;
            return stateCopy
        case FILTERED_QUESTIONS:
            if (action.payload === 0) {
                stateCopy.sampleQuestions = stateCopy.sampleQuestionsCopy;
            } else {
                const filtered = stateCopy.sampleQuestionsCopy?.filter(el => el.questionLevelId === action.payload);
                stateCopy.sampleQuestions = filtered;
            }
            stateCopy.loading = false;
            return stateCopy;
        default:
            return stateCopy;
    }
}