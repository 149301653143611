import React, { Fragment, useEffect, useState } from 'react';
import '../My-assessment/myAssessment.scss';
import './gappeoTest.scss';
import { useSelector, useDispatch } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';
import { resetGappeoTest, getGappeoTests, getNextGappeoTests, getCategory, getSampleQuestions, resetQuestions, getFilterQuestion } from '../../Redux/ActionCreators/gappeoTestAction';
import { Table, } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { Modal, Card } from 'react-bootstrap';
import { getQuestionLevels } from '../../Redux/ActionCreators/testLibraryAction';
import { useTranslation } from 'react-i18next';





export default function GappeoTest() {
    const [page, setPage] = useState(1)
    const [query, setQuery] = useState('');
    const [testType, setTestType] = useState(0);
    const [categoryId, setCategoryId] = useState(0);
    const [isOpen, setIsOpen] = useState(false);
    const [isView, setIsView] = useState(false);
    const { t } = useTranslation();
    const [viewDetail, setViewDetail] = useState('');
    const [level, setLevel] = useState(0)
    const rows = 20
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { isLoading, categories, gappeoTests, total, languageData, sampleQuestions, loading } = useSelector(state => state.gappeoTest);
    const { questionLevel } = useSelector(state => state.testLibrary);


    useEffect(() => {
        dispatch(getGappeoTests(0, testType, categoryId, query, page, rows));
        dispatch(getCategory());
    }, [])

    const timerRef1 = React.useRef();

    const searchTest = (val) => {
        clearTimeout(timerRef1.current);
        setQuery(val);
        setPage(1);
        timerRef1.current = setTimeout(() => delaySearch(val), 800);
    }

    const delaySearch = async (val) => {
        await dispatch(resetGappeoTest());
        await dispatch(getGappeoTests(0, testType, categoryId, val, page, rows));
    }

    const selectCategory = async (val) => {
        setCategoryId(val);
        setPage(1);
        await dispatch(resetGappeoTest());
        await dispatch(getGappeoTests(0, testType, val, query, 1, rows));
    }

    const changeType = async (val) => {
        setTestType(val);
        setPage(1);
        await dispatch(resetGappeoTest());
        await dispatch(getGappeoTests(0, val, categoryId, query, 1, rows));
    }



    const fetchData = () => {
        setPage(page + 1);
        dispatch(getNextGappeoTests(0, testType, categoryId, query, page + 1, rows));
    }

    const goToSelectTest = (items, type) => {
        console.log(items)
        if (type === 'lang') {
            navigate(`/home/select-test`, {
                state: {
                    detail: items,
                    gappeoTest: true,
                    next: true
                }
            })
        } else {
            navigate(`/home/select-test`, {
                state: {
                    gappeoTest: false,
                    detail: { referenceTypeId: 1, referenceId: items.testID, referenceName: items.testName, testType: testType },
                    next: true
                }
            })
        }
    }

    const openSampleQues = async (data) => {
        setIsOpen(true);
        window.scrollTo(0, 0);
        window.onbeforeunload = function () {
            window.scrollTo(0, 0);
        };
        dispatch(getQuestionLevels());
        setLevel(0);
        await dispatch(resetQuestions());
        await dispatch(getSampleQuestions(data?.testID, 0, 1, 30));
    }

    const backToMain = () => {
        setIsOpen(false);
    }

    const changeLevel = async (id) => {
        setLevel(id);
        await dispatch(resetQuestions());
        await dispatch(getFilterQuestion(id));
    }

    const viewQuestion = (el) => {
        setIsView(true);
        setViewDetail(el);
    }





    return (
        <div className='gappeo-my-tests' style={{ height: '100vh' }}>
            {!isOpen ?
                <Fragment>
                    <div className='d-flex justify-content-center align-items-center'>
                        <h1>                          </h1>
                        <h1 className='text-center'>
                            {t('Gappeo Tests', { ns: 'mytest' })}
                        </h1>
                    </div>
                    <p className='caption'>{t('Readymade tests that you can use to create custom assessments', { ns: 'mytest' })}</p>
                    <div className='search-tests mt-3'>
                        {window.innerWidth < 767 && <p>{t('Test Type',{ns: 'mytest'})} : </p>}

                        <div className={window.innerWidth > 767 ? "d-flex align-items-center  mb-3" : "d-flex align-items-center overflow-auto  mt-2  mb-3"}>

                            {window.innerWidth > 767 && <p>{t('Test Type',{ns: 'mytest'})} : </p>}

                            <div className='ps-3' >
                                <div className={testType === 0 ? 'type-btn active-type' : 'type-btn'} onClick={() => changeType(0)}>
                                    <img src='/images/core.svg' width={20} alt='' /> &nbsp;MCQ
                                </div>
                            </div>
                            <div className='ps-3'>
                                <div className={testType === 1 ? 'type-btn active-type' : 'type-btn'} onClick={() => changeType(1)}>
                                    <img src='/images/coding.svg' width={20} alt='' /> &nbsp;{t('Coding', { ns: 'mytest' })}
                                </div>
                            </div>
                            <div className='ps-3'>
                                <div className={testType === 2 ? 'type-btn active-type' : 'type-btn'} onClick={() => changeType(2)}>
                                    <img src='/images/personality.svg' width={15} alt='' /> &nbsp;{t('Psychometric', { ns: 'mytest' })}
                                </div>
                            </div>
                        </div>
                        <div className='d-flex align-items-center'>
                            <div className="has-search"  >
                                <span className="form-control-feedback" >
                                    <img src="/images/search.svg" alt='' width={12} />
                                </span>
                                <input type="search" className="form-control" value={query} placeholder={t('Search for test', { ns: 'mytest' })} onChange={(e) => searchTest(e.target.value)} />
                            </div>
                            <div style={{ width: '25%' }}>
                                <select value={categoryId} onChange={(e) => selectCategory(e.target.value)}>
                                    <option hidden>{t('Test Category', { ns: 'mytest' })}</option>
                                    {
                                        categories && categories?.map(el => {
                                            return (
                                                <option value={el.categoryId}>{el.categoryName}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                        </div>
                    </div>
                    {isLoading ?
                        t('Loading..',{ns: 'home'}) :
                        <div className="table-admin" >
                            <InfiniteScroll
                                dataLength={gappeoTests && gappeoTests?.length} //This is important field to render the next data
                                next={total > gappeoTests?.length ? fetchData : ''}
                                hasMore={true}

                            >
                                {
                                    (testType === 1 ?
                                        <div className='list-skill'>
                                            {languageData?.map((el, i) => {
                                                return (
                                                    <div className='list-skill-item'
                                                        onClick={() => goToSelectTest(el, 'lang')}
                                                    >
                                                        <div>
                                                            <img src='/images/plus-circle.svg' alt='' />
                                                        </div>
                                                        < div className='d-flex flex-column align-items-start ms-3' >

                                                            <div className='skill-type' style={{ width: window.innerWidth > 767 ? '' : '75%' }}>
                                                                {<img src='/images/coding.svg' width={12} alt='' />}&nbsp;&nbsp;{'Coding Language'}
                                                            </div>
                                                            <h4>{el.displayLanguage}</h4>
                                                        </div>

                                                    </div>
                                                )
                                            })}
                                        </div>
                                        :
                                        (gappeoTests?.length > 0 ?
                                            <Table responsive>
                                                <thead>
                                                    <th className='text-left'>{t('Test Name', { ns: 'mytest' })} </th>
                                                    <th className='text-left'>{t('Category', { ns: 'mytest' })}</th>
                                                    <th className='text-left'>{t('Question Level | Type', { ns: 'mytest' })}</th>
                                                    <th className='text-left'>{t('Question | Time', { ns: 'mytest' })}</th>
                                                    <th className='text-left'>{t('Action', { ns: 'mytest' })}</th>
                                                </thead>
                                                <tbody className="table-body">
                                                    {
                                                        gappeoTests && gappeoTests?.map(el => {
                                                            return (
                                                                <tr>
                                                                    <td>
                                                                        <h4>{el.testName}</h4>
                                                                    </td>
                                                                    <td >
                                                                        <h5>
                                                                            {el.categoryId === 16 ? <img src='/images/cultural.svg' alt='' /> : el.categoryId === 17 ? <img src='/images/behavioural.svg' alt='' /> : el.categoryId === 3 ? <img src='/images/coding.svg' alt='' width={10} /> : el.categoryId === 18 ? <img src='/images/personality.svg' alt='' /> : <img src='/images/core.svg' alt='' />}&nbsp;{el.categoryName}
                                                                        </h5>
                                                                    </td>
                                                                    <td>
                                                                        <h5>
                                                                            {el.testLevelName} | {el.testTypeName}
                                                                        </h5>
                                                                    </td>
                                                                    <td >
                                                                        <h5>
                                                                            {el.questionCount} Qs | {el.duration} Mins
                                                                        </h5>
                                                                    </td>


                                                                    <td className='cursor-pointer'>
                                                                        <div className='d-flex align-items-center'>
                                                                            <div className='create' onClick={() => openSampleQues(el)}>
                                                                                <img className='' src='/images/sample-icon.svg' width={25} alt='' />
                                                                            </div>&nbsp;&nbsp;
                                                                            <div className='create' onClick={() => goToSelectTest(el, 'assessment')}>
                                                                                <img className='' src='/images/add-icon1.svg' width={25} alt='' />
                                                                            </div>
                                                                        </div>
                                                                    </td>

                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            </Table>
                                            :
                                            <div className='empty-list'>
                                                <img src='/images/magnifier.svg' alt='' />
                                                <div className='mt-2'>
                                                    <h3>{t('There is no tests', { ns: 'mytest' })}</h3>
                                                </div>
                                            </div>)
                                    )

                                }
                            </InfiniteScroll>
                        </div>
                    }
                </Fragment>
                :
                <Fragment>
                    <div style={{ width: '60%' }} className='d-flex justify-content-between align-items-center'>
                        <img src='/images/back.svg' alt='' className='cursor-pointer' onClick={() => backToMain()} />
                        <h1 className='text-center'>
                            {t('Sample Questions', { ns: 'mytest' })}
                        </h1>
                    </div>
                    <h5 className='text-center'><strong >{sampleQuestions[0]?.skillName}</strong></h5>
                    <p className='caption'>{t('These are only sample questions', { ns: 'mytest' })}</p>
                    <div className='search-tests mt-3'>
                        {window.innerWidth < 767 && <p>Test Level : </p>}

                        <div className={window.innerWidth > 767 ? "d-flex align-items-center  mb-3" : "d-flex align-items-center overflow-auto  mt-2  mb-3"}>

                            {window.innerWidth > 767 && <p>Test Level : </p>}
                            <div className='ps-3'>
                                <div className={Number(level) === 0 ? 'type-btn active-type' : 'type-btn'} onClick={() => changeLevel(0)}>
                                    {t('All', { ns: 'mytest' })}
                                </div>
                            </div>
                            {
                                questionLevel && questionLevel?.map(el => {
                                    return (
                                        <div className='ps-3' >
                                            <div className={Number(level) === Number(el.questionLevelID) ? 'type-btn active-type' : 'type-btn'} onClick={() => changeLevel(el.questionLevelID)}>
                                                {el.questionLevelName}
                                            </div>

                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                    <div className='table-admin'>
                        {loading ?
                            t('Loading..',{ns: 'home'}) :
                            sampleQuestions?.length ?
                                <Table responsive>
                                    <thead>
                                        <th className='text-left'>{t('Question', { ns: 'mytest' })} </th>
                                        <th className='text-left'>{t('Level', { ns: 'mytest' })}</th>
                                        <th className='text-left'>{t('Topic', { ns: 'mytest' })}</th>
                                        <th className='text-center'>{t('View', { ns: 'mytest' })}</th>
                                    </thead>
                                    <tbody className="table-body">
                                        {
                                            sampleQuestions && sampleQuestions?.map(el => {
                                                return (
                                                    <tr>
                                                        <Fragment>
                                                            <td style={{ width: '35%' }}>
                                                                <h5 className='elipsis-2' dangerouslySetInnerHTML={{ __html: el?.question }} />
                                                            </td>
                                                            <td style={{ width: '16%', textAlign: 'left' }}>
                                                                <div>
                                                                    <span> {el?.questionLevelName}</span>
                                                                </div>
                                                            </td>

                                                            <td style={{ width: '30%', textAlign: 'left' }}>
                                                                <div>
                                                                    {el?.skillTopicName}
                                                                </div>
                                                            </td>
                                                            <td style={{ width: 50 }}>
                                                                <img src='/images/view-icon.svg' width={16} className='cursor-pointer' alt='' onClick={() => viewQuestion(el)} />
                                                            </td>
                                                        </Fragment>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </Table> :
                                <div className='empty-list'>
                                    <img src='/images/magnifier.svg' alt='' />
                                    <div className='mt-2'>
                                        <h3>{t('No Questions added',{ns: 'myTest'})}</h3>
                                    </div>
                                </div>
                        }
                    </div>
                </Fragment>
            }

            <Modal
                show={isView}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                dialogClassName={"sample-question-modal"}
            >
                <Modal.Header>
                    {t('Sample Questions',{ns: 'myTest'})}
                    <div className='sticky-close'>
                        <img src='/images/close-white.svg' alt='' onClick={() => setIsView(false)} />
                    </div>
                </Modal.Header>
                <Modal.Body>
                    {loading ?
                        t('Loading..',{ns: 'home'}) :
                        <div className='ques-div' >
                            <Card.Header>
                                <div
                                    className='d-flex align-items-center justify-content-between'
                                >
                                    <span dangerouslySetInnerHTML={{ __html: viewDetail?.question }} />

                                </div>
                            </Card.Header>
                            <Card.Body>
                                <ul className='ques-options'>

                                    {
                                        viewDetail?.questionOptions?.map(option => {
                                            return (
                                                <li className={option.iscorrect ? 'correct' : ''}>
                                                    <div>
                                                        {option.questionOption}
                                                    </div>
                                                    {option.iscorrect ? <img src='/images/correct.svg' alt='' /> : null}
                                                </li>
                                            )
                                        })
                                    }
                                </ul>

                            </Card.Body>
                        </div>
                    }

                </Modal.Body>

            </Modal>
        </div >
    )
}
