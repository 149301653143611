import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { getInvitationTemplates, closeInviteModal, updateTemplate } from '../../../Redux/ActionCreators/testLibraryAction';
import { TemplateEditor } from '../../../utils/editor';
import { useTranslation } from 'react-i18next';

export default function Template({ testId }) {
    const { templateType, templates, editTemplate, instruction, templateId } = useSelector(state => state.testLibrary);
    const [edit, setEdit] = useState(false);
    const { t } = useTranslation();
    const dispatch = useDispatch();


    const saveTemplate = async () => {

        let obj = {
            "templateId": templateId,
            "template": templates,
            additionalInstruction: instruction
        }
        const res = await dispatch(updateTemplate(obj));
        if (res?.type === 'UPDATE_TEMPLATE') {
            dispatch(getInvitationTemplates(testId, templateType, false));
            setEdit(false);
            !edit && await dispatch(closeInviteModal());
        }
    }

    return (
        <div className='template-details'>
            <div className='template-header'>
                <img className='cursor-pointer' src='/images/back.svg' onClick={() => dispatch(closeInviteModal())} alt='' />
                <h4>{editTemplate ? 'Edit Template' : (edit) ? 'Edit Template' : 'View Template'}</h4>
                {editTemplate ?
                    <button onClick={saveTemplate}>{t('Save',{ns: 'home'})}</button> :
                    (edit) ?
                        <button onClick={saveTemplate}>{t('Save',{ns: 'home'})}</button> :
                        <button onClick={() => {
                            setEdit(true)
                            // dispatch(getInvitationTemplates(testId, templateType, true))
                        }}>{t('Edit',{ns: 'home'})}</button>
                }
            </div>
            <div className='template-desc'>
                <div className='template-image text-center'>
                    <img src='/images/editor-img.svg' width={595} />
                    <div className='template-text'>
                        <h3>GAPPEO</h3>
                        <p>{t('Skill Test Platform',{ns: 'home'})} </p>
                    </div>
                </div>
                {editTemplate ?
                    <div style={{ padding: '0px 227px' }}>
                        <TemplateEditor content={instruction} type='template' />
                    </div> :
                    edit ?
                        <div style={{ padding: '0px 227px' }}>
                            <TemplateEditor content={instruction} type='template' />
                        </div>
                        : <div dangerouslySetInnerHTML={{ __html: templates }} />


                }
            </div>
        </div >
    )
}
