import React, { useState , Fragment } from 'react'
import '../My-Questions1/myQuestions1.scss';
import { Table } from 'react-bootstrap';
import QuestionPage from './questionPage';
import { useTranslation } from 'react-i18next';


const MyQuestions1 = () => {

    const [data , setData] = useState([
        { id: 1, knowledge: 'Java', mcq: 24 , coding: 32 , audio: 40 , video: 24 , essay: 32 },
        { id: 2, knowledge: 'Pythn', mcq: 24, coding: 32 , audio: 40 , video: 24 , essay: 32  },
        { id: 3, knowledge: 'C++ 3', mcq: 24, coding: 32 , audio: 40 , video: 24 , essay: 32  },
        { id: 4, knowledge: 'AI/ML', mcq: 24, coding: 32 , audio: 40 , video: 24 , essay: 32  },
    ]);
    const { t } = useTranslation();

    // const [data , setData] = useState([]);

    const [isView, setIsView] = useState(false);

    const handleEdit = () => {
        setIsView(true);
    };

    const handleBack = () => {
        setIsView(false);
    };


  return (
    <div className='PageContext'>
            {isView ? (
                <QuestionPage onBack={handleBack} />
            ) : (
                <Fragment>
                    <div className='d-flex Header'>
                        <h2 className='PageHeading'>{t('My Questions',{ns: 'mytest'})}</h2>
                        <button className='QueButton'>{t('ADD QUESTION',{ns: 'mytest'})}</button>
                    </div>
                    &nbsp;&nbsp;
                    <div>
                        <input className='MyQuesSearch' placeholder={t('Search',{ns: 'mytest'})} />
                        &nbsp;&nbsp;
                        <div className='table-admin'>
                            <Table responsive>
                                <thead className='TableHeader'>
                                    <tr>
                                        <th className='KnowledgeArea'>{t('Knowledge Area',{ns: 'mytest'})}</th>
                                        <th>MCQ</th>
                                        <th>{t('Coding',{ns: 'mytest'})}</th>
                                        <th>{t('Audio',{ns: 'mytest'})}</th>
                                        <th>{t('Video',{ns: 'mytest'})}</th>
                                        <th>{t('Essay',{ns: 'mytest'})}</th>
                                        <th>{t('View/Edit',{ns: 'mytest'})}</th>
                                    </tr>
                                </thead>
                                {data.length === 0 ? (
                                    <div className='NoDataMessage'>
                                        <h4>{t('There is no data in the table',{ns: 'mytest'})}.</h4>
                                    </div>
                                ) : (
                                    <tbody className='table-body'>
                                        {data.map(item => (
                                            <tr key={item.id} className='TableBody'>
                                                <td className='edittext'>{item.knowledge}</td>
                                                <td className='edittext'>{item.mcq}</td>
                                                <td className='edittext'>{item.coding}</td>
                                                <td className='edittext'>{item.audio}</td>
                                                <td className='edittext'>{item.video}</td>
                                                <td className='edittext'>{item.essay}</td>
                                                <td className='edittext viewevent' onClick={handleEdit}>{t('View/Edit',{ns: 'mytest'})}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                )}
                            </Table>
                        </div>
                    </div>
                </Fragment>
            )}
        </div>
  )
}

export default MyQuestions1;
