import React from 'react';
import { Drawer } from '@mui/material';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { getTotal } from './miscelleneous';
import { useTranslation } from 'react-i18next';

export default function TestDetailDrawer({
    openDetail, setOpenDetail, handleDragEnd, removeSkill, selectedTests, testDetails
}) {
    const { t } = useTranslation();
    return (
        <div>
            {
                ['bottom'].map((anchor) => (
                    <React.Fragment key={anchor}>
                        <Drawer
                            anchor={anchor}
                            open={openDetail}
                            className='selected-test-drawer'
                        >
                            <div className='selected-list-header'>
                                <div className='close' onClick={() => setOpenDetail(false)}><img src='/images/close-white.svg' alt='' width='18px' /></div>
                                <h4 className='mt-4'>{t('Test Summary')}</h4>
                                {testDetails?.testName &&
                                    <h4 className='d-flex justify-content-center align-items-center' style={{ color: '#fff', fontSize: 18 }}>{testDetails?.testName}</h4>}
                                <h5>{selectedTests?.length} {t('test')} | {getTotal(selectedTests, 'ques')} Qs | {getTotal(selectedTests, 'time')} {t('Mins')}</h5>
                                {/* <p>Level:Advanced</p> */}
                            </div>
                            <div className='selected-list'>

                                <h1>{t('Selected Tests')}</h1>
                                {/* <p>{selectedTests?.length} test | {getTotal(selectedTests, 'ques')} Qs | {getTotal(selectedTests, 'time')} Mins</p> */}
                                <DragDropContext onDragEnd={handleDragEnd}>
                                    <Droppable droppableId={'2111'}>
                                        {
                                            (provided) => (
                                                <div {...provided.droppableProps} ref={provided.innerRef}>
                                                    {selectedTests && selectedTests?.map((el, index) => {
                                                        return (
                                                            <Draggable key={el.testID} draggableId={el.testID.toString()} index={index} >
                                                                {
                                                                    (provided) => (
                                                                        <div {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef} className='selected-list-item'>
                                                                            <div className='d-flex'>
                                                                                <img src='/images/drag.svg' alt='' width={16} />&nbsp;&nbsp;
                                                                                <div>
                                                                                    <h1>{el.testName}</h1>
                                                                                    <p>{el.testLevelName} | {t('Core',{ns: 'home'})} | {el.questionCount} Qs | {el.duration} {t('Mins',{ns: 'home'})} | {el.languageId === 1 ? 'en' : el.languageId === 2 ? 'es' : ((el.languageId === 3) || (el.languageId === 4)) ? 'pt' : 'it'}</p>
                                                                                </div>
                                                                            </div>
                                                                            <img src='/images/delete-icon.svg' onClick={() => removeSkill(el)} alt='' width={16} />
                                                                        </div>
                                                                    )
                                                                }
                                                            </Draggable>
                                                        )
                                                    })}
                                                    {provided.placeholder}
                                                </div>

                                            )}
                                    </Droppable>
                                </DragDropContext>

                            </div>

                        </Drawer>
                    </React.Fragment>))
            }
        </div>
    )
}
