import React, { useEffect, useState } from 'react';
import '../My-assessment/myAssessment.scss';
import { Table } from 'react-bootstrap';
import InfiniteScroll from 'react-infinite-scroll-component';
import { getReport, getNextReport, getOldReport, getOldNextReport, resetLoading } from '../../Redux/ActionCreators/reportAction';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { convertDate, formatAMPM } from '../../utils/miscelleneous';
import { useTranslation } from 'react-i18next';
import moment from 'moment';


export default function Reports() {
    const [activeTab, setActiveTab] = useState('new');
    const [page, setPage] = useState(1);
    const [oldpage, setOldPage] = useState(1);
    const [query, setQuery] = useState('');
    const [sortVal, setSortVal] = useState(0);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { isReportLoading, testReport, totalCount, oldTestReport } = useSelector(state => state.report);

    const rows = 12;
    const timerRef1 = React.useRef(null);

    const tenantID = JSON.parse(localStorage.getItem('profileDetails')).tenentId;

    useEffect(() => {
        if (window.location.search.split('=')[1]) {
            setActiveTab('old');
        }
    }, [])


    useEffect(() => {

        dispatch(resetLoading());
        if (activeTab === 'new') {
            setQuery('');
            dispatch(getReport(0, '', 1, rows, sortVal));
        } else {
            setQuery('');
            dispatch(getOldReport(tenantID, '', 1, rows));
        }
    }, [activeTab])

    const onTabChange = (e) => {
        setActiveTab(e);

    }

    const fetchData = () => {
        if (activeTab === 'new') {
            setPage(page + 1);
            dispatch(getNextReport(0, '', page + 1, rows, sortVal));
        } else {
            setOldPage(oldpage + 1);
            dispatch(getOldNextReport(tenantID, '', oldpage + 1, rows));
        }
    }

    const searchReport = (val) => {
        clearTimeout(timerRef1.current);
        setQuery(val);
        setPage(1);
        setOldPage(1);
        timerRef1.current = setTimeout(() => delaySearch(val), 800);
    }

    const delaySearch = async (val) => {
        if (activeTab === 'new') {
            await dispatch(getReport(0, val, 1, rows, sortVal));
        } else {
            dispatch(getOldNextReport(tenantID, val, 1, rows));
        }
    }


    const openOneReport = (id, type) => {
        navigate('/test-report', { state: { id: id, type: type, myTest: false, path: 'report' } })
    }

    const selectSort = async (val) => {
        setSortVal(val);
        setPage(1);
        await dispatch(getReport(0, val, 1, 12, val));
    }

    return (
        <div className='gappeo-my-tests'>
            <h1 className='d-flex justify-content-center'>
                {t('Candidate Reports', { ns: 'mytest' })}</h1>
            <div className='report-btns d-flex align-items-center'>
                <button className={activeTab === 'new' ? 'active' : ''} onClick={() => onTabChange('new')}>{t('Reports', { ns: 'mytest' })}</button>&nbsp;&nbsp;
                <button className={activeTab === 'old' ? 'active' : ''} onClick={() => onTabChange('old')}>{t('Old Reports', { ns: 'mytest' })}</button>&nbsp;
            </div>
            <div className='search-test mt-1 d-flex align-items-end'>
                <div className={activeTab === 'new' ? "has-search" : 'has-search mt-3'}  >
                    <span className="form-control-feedback" >
                        <img src="/images/search.svg" alt='' width={12} />
                    </span>
                    <input type="text" className="form-control" value={query} placeholder={t('Search candidate by Name or Email Address', { ns: 'mytest' })} onChange={(e) => searchReport(e.target.value)} />
                </div>
                {activeTab === 'new' ?
                    <div style={{ width: '30%' }} className='ms-2'>
                        <p>{t('Sort by', { ns: 'mytest' })}</p>
                        <select className='form-control' value={sortVal} onChange={(e) => selectSort(e.target.value)}  >
                            <option value={1}>{t('Score', { ns: 'mytest' })}</option>
                            <option value={2}>{t('Test Attempt Date', { ns: 'mytest' })}</option>
                        </select>
                    </div> :
                    null
                }
            </div>
            {
                activeTab === 'new' ?
                    <div className="table-admin mt-2">
                        <InfiniteScroll
                            dataLength={testReport && testReport?.length} //This is important field to render the next data
                            next={totalCount > testReport?.length ? fetchData : ''}
                            hasMore={true}
                        >
                            {isReportLoading ?
                                t('Loading..', { ns: 'home' })
                                :
                                testReport?.length > 0 ?
                                    <Table responsive>
                                        <thead>
                                            <th>{t('Name', { ns: 'mytest' })}</th>
                                            <th>{t('Email', { ns: 'mytest' })}</th>
                                            <th>{t('Test', { ns: 'mytest' })}</th>
                                            <th>{t('Test Attempt Date', { ns: 'mytest' })}</th>
                                            <th>{t('Score', { ns: 'mytest' })}</th>
                                            <th>{t('Status', { ns: 'mytest' })}</th>
                                            <th>{t('View', { ns: 'mytest' })}</th>
                                        </thead>
                                        <tbody className="table-body">
                                            {
                                                testReport && testReport?.map((el) => {
                                                    return (
                                                        <tr>
                                                            <td>{el.name}</td>
                                                            <td>{el.emailId}</td>
                                                            <td>{el.testName}</td>
                                                            <td>{el.testAttemptDate ? convertDate(el.testAttemptDate) : ''}<br />
                                                                {el.testAttemptDate ? formatAMPM(moment.utc(el.testAttemptDate).local().format()) : ''}
                                                            </td>
                                                            <td>{el.status === 3 ? el.score : 'NA'}</td>
                                                            <td>{el.status === 3 ? 'Completed' : el.status === 2 ? 'In Progress' : 'Invited'}</td>
                                                            <td >{el.status === 3 ?
                                                                <img src='/images/view-icon.svg' alt='' className='cursor-pointer' onClick={() => openOneReport(el.inviteId, 'new')} /> :
                                                                'NA'
                                                            }</td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </Table> :
                                    <div className='empty-list'>
                                        <img src='/images/magnifier.svg' alt='' />
                                        <div className='mt-2'>
                                            <h3>{t('There is no Test report yet', { ns: 'mytest' })}</h3>
                                        </div>
                                    </div>
                            }
                        </InfiniteScroll>
                    </div> :
                    <div className="table-admin mt-2">
                        <InfiniteScroll
                            dataLength={oldTestReport && oldTestReport?.length} //This is important field to render the next data
                            next={fetchData}
                            hasMore={true}
                        >
                            {isReportLoading ?
                                t('Loading..', { ns: 'home' })
                                :
                                oldTestReport?.length > 0 ?
                                    <Table responsive>
                                        <thead>
                                            <th>{t('Name', { ns: 'mytest' })}</th>
                                            <th>{t('Email', { ns: 'mytest' })}</th>
                                            <th>{t('Test', { ns: 'mytest' })}</th>
                                            <th>{t('Test Attempt date', { ns: 'mytest' })}</th>
                                            <th>{t('Score', { ns: 'mytest' })}</th>
                                            <th>{t('View', { ns: 'mytest' })}</th>
                                        </thead>
                                        <tbody className="table-body">
                                            {
                                                oldTestReport && oldTestReport?.map((el) => {
                                                    return (
                                                        <tr>
                                                            <td>{el.name}</td>
                                                            <td>{el.emailId}</td>
                                                            <td>{el.testName}</td>
                                                            <td>{el.testAttemptDate ? convertDate(el.testAttemptDate) : ''}<br />
                                                                {el.testAttemptDate ? formatAMPM(moment.utc(el.testAttemptDate).local().format()) : ''}
                                                            </td>
                                                            <td>{el.scores}</td>
                                                            <td ><img src='/images/view-icon.svg' alt='' className='cursor-pointer' onClick={() => openOneReport(el.candidateSendInviteId, 'old')} /></td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </Table> :
                                    <div className='empty-list'>
                                        <img src='/images/magnifier.svg' alt='' />
                                        <div className='mt-2'>
                                            <h3>{t('There is no Test report yet', { ns: 'mytest' })}</h3>
                                        </div>
                                    </div>
                            }
                        </InfiniteScroll>
                    </div>

            }
        </div>
    )
}
