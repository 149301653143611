import { LOGIN_USER } from '../Types/types';

let intialState = {

}


export default function authReducer(state = intialState, action) {
    let stateCopy = JSON.parse(JSON.stringify(state));
    switch (action.type) {
        case LOGIN_USER:
            stateCopy.isAuthorized = true;
            localStorage.setItem('token', action.payload.token);
            localStorage.setItem('email', action.payload.emailId);
            localStorage.setItem('name', action.payload.name);
            localStorage.setItem('userId', action.payload.userId);
            localStorage.setItem('emailVerified', action.payload.emailVerified);
            localStorage.setItem('profileDetails', JSON.stringify(action.payload));
            localStorage.setItem('role', action.payload.roleId);
            localStorage.setItem('logo', action.payload.logoImage);
            localStorage.setItem('i18nextLng', action.payload.languageCode);
            stateCopy.user = action.payload;
            // window.location.href = action.payload?.role.includes('admin') ? '/manage-content' : '/my-learning';
            window.location.reload();
            return stateCopy;
        default:
            return stateCopy;
    }
}
