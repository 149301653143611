import { returnErrors } from './errorAction';
import { RESET_LOADING, GET_REPORT, GET_REPORT1, GET_NEXT_REPORT, GET_DETAILED_REPORT, GET_OLD_REPORT, GET_OLD_NEXT_REPORT } from '../Types/types';
import { getApiCall, getV2ApiCall, postApiCall } from '../../utils/request';

export const getReport = (id, query, page, row, sort) => async (dispatch) => {
    try {
        const response = await getApiCall(`candidate/list-report?TestId=${id}&SearchText=${query}&PageNumber=${page}&DisplayRows=${row}&sortby=${sort}`);
        if (response?.status === 200) {
            return dispatch({ type: GET_REPORT, payload: response.data });
        } else if (response?.status === 204) {
            dispatch({ type: GET_REPORT1, payload: [] });
        }
        else {
            returnErrors(dispatch, response?.data?.responseMessages[0]?.message, response?.status)
        }
    }
    catch (error) {
        returnErrors(dispatch, error.response?.data?.responseMessages[0]?.message, error?.response?.status);
    }
}

export const getNextReport = (id, query, page, row, sort) => async (dispatch) => {
    try {
        const response = await getApiCall(`candidate/list-report?TestId=${id}&SearchText=${query}&PageNumber=${page}&DisplayRows=${row}&sortby=${sort}`);
        if (response?.status === 200) {
            return dispatch({ type: GET_NEXT_REPORT, payload: response.data });
        }
        else {
            returnErrors(dispatch, response?.data?.responseMessages[0]?.message, response?.status)
        }
    }
    catch (error) {
        returnErrors(dispatch, error.response?.data?.responseMessages[0]?.message, error?.response?.status);
    }
}

export const getOldReport = (id, query, page, row) => async (dispatch) => {
    try {
        const response = await getApiCall(`candidate/candidate-report?tenentId=${id}&SearchText=${query}&Pagenumber=${page}&PageSize=${row}&IsTestAttempt=true`);
        if (response?.status === 200) {
            return dispatch({ type: GET_OLD_REPORT, payload: response.data });
        } else if (response?.status === 204) {
            dispatch({ type: GET_OLD_REPORT, payload: [] });
        }
        else {
            returnErrors(dispatch, response?.data?.responseMessages[0]?.message, response?.status)
        }
    }
    catch (error) {
        returnErrors(dispatch, error.response?.data?.responseMessages[0]?.message, error?.response?.status);
    }
}

export const getOldNextReport = (id, query, page, row) => async (dispatch) => {
    try {
        const response = await getApiCall(`candidate/candidate-report?tenentId=${id}&SearchText=${query}&Pagenumber=${page}&PageSize=${row}&IsTestAttempt=true`);
        if (response?.status === 200) {
            return dispatch({ type: GET_OLD_NEXT_REPORT, payload: response.data });
        }
        else {
            returnErrors(dispatch, response?.data?.responseMessages[0]?.message, response?.status)
        }
    }
    catch (error) {
        returnErrors(dispatch, error.response?.data?.responseMessages[0]?.message, error?.response?.status);
    }
}


export const getDetailedReport = (id) => async (dispatch) => {
    try {
        const response = await getV2ApiCall(`candidate/detailed-report?InviteId=${id}`);
        console.log(response.data.report)
        if (response?.status === 200) {
            return dispatch({ type: GET_DETAILED_REPORT, payload: response.data.report });
        }
        else {
            returnErrors(dispatch, response?.data?.responseMessages[0]?.message, response?.status)
        }
    }
    catch (error) {
        dispatch({ type: GET_DETAILED_REPORT, payload: null });
        returnErrors(dispatch, error.response?.data?.responseMessages[0]?.message, error?.response?.status);
    }
}


// export const getDetailedReport = (id) => async (dispatch) => {
//     try {
//         const response = await getApiCall(`candidate/detailed-report?InviteId=${id}`);
//         if (response?.status === 200) {
//             return dispatch({ type: GET_DETAILED_REPORT, payload: response.data.report });
//         }
//         else {
//             returnErrors(dispatch, response?.data?.responseMessages[0]?.message, response?.status)
//         }
//     }
//     catch (error) {
//         returnErrors(dispatch, error.response?.data?.responseMessages[0]?.message, error?.response?.status);
//     }
// }

export const getDetailedOldReport = (id) => async (dispatch) => {
    try {
        const response = await getApiCall(`candidate/candidate-report-details?CandidateSendInviteId=${id}`);
        if (response?.status === 200) {
            return dispatch({ type: GET_DETAILED_REPORT, payload: response.data });
        }
        else {
            returnErrors(dispatch, response?.data?.responseMessages[0]?.message, response?.status)
        }
    }
    catch (error) {
        returnErrors(dispatch, error.response?.data?.responseMessages[0]?.message, error?.response?.status);
    }
}

export const resetLoading = () => async (dispatch) => {
    try {
        return dispatch({ type: RESET_LOADING });
    }
    catch (error) {
        returnErrors(dispatch, error.response?.data?.responseMessages[0]?.message, error?.response?.status);
    }
}

export const updatedScore = (obj) => async (dispatch) => {
    try {
        const response = await postApiCall(`test/update-candidate-question-score`, obj);
        console.log(response.data)
    }
    catch (error) {
        returnErrors(dispatch, error.response?.data?.responseMessages[0]?.message, error?.response?.status);
    }
}