import React from 'react'
import { useTranslation } from 'react-i18next';

export default function Footer() {
    const { t } = useTranslation();
    return (
        <div className='footer'>
            <h6>{t('Powered by',{ns: 'profile'})}</h6>
            <img src='/images/gappeo-logo.svg' alt='' />
        </div>
    )
}
