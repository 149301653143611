/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment, useEffect, useState } from 'react';
import "../Login/login.scss";
import { BallBeat } from '../../Loader/loader';
import Snackbar from '@mui/material/Snackbar';
import { userSignup } from '../../Redux/ActionCreators/authAction';
import { clearErrors } from '../../Redux/ActionCreators/errorAction';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Alert } from '@mui/material';
import { freshsales_new_contact } from '../../utils/freshSales';
import { useTranslation } from 'react-i18next';


function Signup() {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [otp, setOtp] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [verifyMsg, setVerifyMsg] = useState(false);
    const [nameError, setNameError] = useState('');
    const [emailError, setEmailError] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [successEnable, setSuccessEnable] = useState(false);
    const { t } = useTranslation();
    const [successMessageText, setSuccessMessageText] = useState('');
    const [responseStatus, setResponseStatus] = useState('');
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const error = useSelector(state => state.error)
    useEffect(() => {

        if (localStorage.getItem('token')) {
            (window.location.pathname === '/' || window.location.pathname === '/login' || window.location.pathname === '/signup' || window.location.pathname === '/coupon-redeem') && (
                navigate('/home')
            )
        } else {
            window.location.pathname === '/' && (
                navigate('/signup')
            )
        }

    }, [])

    const onNameChange = (e) => {
        setNameError('');
        setName(e.target.value);
    }

    const onEmailChange = (e) => {
        setEmailError('');
        setEmail(e.target.value);
    }

    const onChangePassword = (e) => {
        setPasswordError('');
        setPassword(e.target.value);
    }


    const signupUser = async () => {
        setIsLoading(true);
        setResponseStatus('')
        let emailvalue = email;
        const validemail = /^([\w.-]+)@(\[(\d{1,3}\.){3}|(?!hotmail|gmail|googlemail|yahoo|gmx|ymail|outlook|bluewin|protonmail|t\-online|web\.|online\.|aol\.|live\.)(([a-zA-Z\d-]+\.)+))([a-zA-Z]{2,63}|\d{1,3})(\]?)$/
        // eslint-disable-next-line no-useless-escape
        let emailrex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (name === '') {
            setNameError(t(`Name can't be blank`));
            setIsLoading(false);
            return false;
        }
        else if (!emailvalue) {
            setEmailError(t(`Email can't be blank`));
            setIsLoading(false);
            return false;
        }
        else if (!emailrex.test(String(emailvalue))) {
            setEmailError(t("Email is not valid"));
            setIsLoading(false);
            return false;
        }
        else if (!validemail.test(String(emailvalue.toLowerCase()))) {
            setEmailError(t("Please enter only work email address"));
            setIsLoading(false);
            return false;
        }
        else if (password === "") {
            setPasswordError(t('Please enter password'));
            setIsLoading(false);
        }
        else {
            let signup = {
                "name": name,
                "emailId": email,
                "password": password,
                "tenentTypeEnterprise": true,
                "emailVerificationUrl": window.location.protocol + '//' + window.location.host + "/login/id"
            }

            const res = await dispatch(userSignup(signup));
            if (res?.type === 'SIGNUP_USER') {
                await freshsales_new_contact(name, email, 'webfree');
                setTimeout(() => {
                    if (error.successMessage === false) {
                        setIsLoading(false);
                    }
                }, 1000);
            } else {
                setIsLoading(false);
            }

        }
    }


    const loginOnKeyPress = async (e) => {
        if (e.which === 13 || e.keyCode === 13) {
            setIsLoading(true);
            setResponseStatus('')
            let emailvalue = email;
            const validemail = /^([\w.-]+)@(\[(\d{1,3}\.){3}|(?!hotmail|gmail|googlemail|yahoo|gmx|ymail|outlook|bluewin|protonmail|t\-online|web\.|online\.|aol\.|live\.)(([a-zA-Z\d-]+\.)+))([a-zA-Z]{2,63}|\d{1,3})(\]?)$/
            // eslint-disable-next-line no-useless-escape
            let emailrex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (name === '') {
                setNameError(t(`Name can't be blank`));
                setIsLoading(false);
                return false;
            }
            else if (!emailvalue) {
                setEmailError(t('Email cant be blank'));
                setIsLoading(false);
                return false;
            }
            else if (!emailrex.test(String(emailvalue))) {
                setEmailError(t("Email is not valid"));
                setIsLoading(false);
                return false;
            }
            else if (!validemail.test(String(emailvalue.toLowerCase()))) {
                setEmailError(t("Please enter only work email address"));
                setIsLoading(false);
                return false;
            }
            else if (password === "") {
                setPasswordError(t('Please enter password'));
                setIsLoading(false);
            }
            else {
                let signup = {
                    "name": name,
                    "emailId": email,
                    "password": password,
                    "tenentTypeEnterprise": true,
                    "emailVerificationUrl": window.location.protocol + '//' + window.location.host + "/login/id"
                }

                const res = await dispatch(userSignup(signup));
                if (res?.type === 'SIGNUP_USER') {
                    await freshsales_new_contact(name, email, 'webfree');
                    setTimeout(() => {
                        if (error.successMessage === false) {
                            setIsLoading(false);
                        }
                    }, 1000);
                } else {
                    setIsLoading(false);
                }

            }
        }
    }

    const gotologin = () => {
        window.location.href = '/login'
    }

    const snackClosed = () => {
        dispatch(clearErrors());
        setSuccessEnable(false);
        setSuccessMessageText('')
    }
    return (
        <Fragment>
            <div className="row bg-img" style={{ background: `url('/images/bg-img1.png') no-repeat center center fixed`, height: '100vh', width: '100%', backgroundColor: '#fff', marginLeft: window.innerWidth > 767 ? -15 : 0 }} >
                <Snackbar
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center', }}
                    open={error.successMessage}
                    onClose={() => snackClosed()}
                    autoHideDuration={4000}
                >
                    <Alert onClose={snackClosed} severity="error" sx={{ width: '100%' }}>
                        {error.successMessageText}
                    </Alert>
                </Snackbar>
                <Snackbar
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center', }}
                    open={error.successEnable}
                    onClose={() => snackClosed()}
                    autoHideDuration={4000}
                >
                    <Alert onClose={snackClosed} severity="success" sx={{ width: '100%' }}>
                        {error.successMessageText}
                    </Alert>
                </Snackbar>
                <Snackbar
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center', }}
                    open={successEnable}
                    onClose={() => snackClosed()}
                    autoHideDuration={4000}
                >
                    <Alert onClose={snackClosed} severity="error" sx={{ width: '100%' }}>
                        {successMessageText}
                    </Alert>
                </Snackbar>


                <div className='login-holder'>
                    {
                        !verifyMsg ?
                            <Fragment>
                                <img src={'/images/logo.png'} alt='' height='70px' />
                                <h2 className='forgot-btn'>{t('Welcome to GAPPEO', { ns: 'profile' })}</h2>
                                <div style={{ marginTop: 30 }}>
                                    {
                                        <span style={{ fontWeight: 600, color: 'red', fontSize: 14, paddingTop: '5px', position: 'relative', marginBottom: -10 }}>{responseStatus}</span>
                                    }
                                    <div className="form-group mt-2">
                                        <div className="has-search"  >
                                            <span className="form-control-feedback" style={{ marginTop: 5 }}>
                                                <img src="/images/user.png" alt='' width={16} />
                                            </span>
                                            <input type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder={t('Name', { ns: 'profile' })} value={name} onChange={(e) => onNameChange(e)} onKeyPress={(e) => loginOnKeyPress(e)} />
                                        </div>
                                        <div className='error'>{nameError}</div>
                                    </div>
                                    <div className="form-group mt-2">
                                        <div className="has-search"  >
                                            <span className="form-control-feedback" style={{ marginTop: 5 }}>
                                                <img src="/images/email.png" alt='' />
                                            </span>
                                            <input type="email" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder={t('Enter work email address only', { ns: 'profile' })} value={email} onChange={(e) => onEmailChange(e)} onKeyPress={(e) => loginOnKeyPress(e)} />
                                        </div>
                                        <div className='error'>{emailError}</div>
                                    </div>
                                    <div className="form-group">
                                        <div className="has-search"  >
                                            <span className="form-control-feedback" >
                                                <img src="/images/lock.png" alt='' />
                                            </span>
                                            <input type="password" className="form-control" aria-describedby="emailHelp" value={password} placeholder={t('Password', { ns: 'profile' })} onChange={(e) => onChangePassword(e)} onKeyPress={(e) => loginOnKeyPress(e)} />
                                        </div>
                                        <div className='error'>{passwordError}</div>

                                    </div>

                                    <div className='position-relative'>
                                        <button type='submit' onClick={() => signupUser()} disabled={isLoading} className="login-button position-relative">
                                            {isLoading ? <BallBeat
                                                color={'#fff'}
                                                loading={true}
                                            /> : (t("SUBMIT"))}
                                        </button>

                                    </div>

                                    <p style={{ color: '#3D4143', fontSize: 14 }} className='mt-2'>{t('If user already registered', { ns: 'profile' })}</p>
                                    <h2 className='forgot-btn mt-0' onClick={gotologin}>{t('Login Here', { ns: 'profile' })}</h2>
                                </div>
                            </Fragment> :
                            <h4>{t('Email sent to your registered email. Please verify your email address', { ns: 'profile' })}</h4>
                    }
                </div>

            </div>

        </Fragment>
    )
}


export default Signup;