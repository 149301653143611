import React, { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import '../Gappeo Tests/gappeoTest.scss';
import './mytests.scss';
import { Table } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Card } from 'react-bootstrap'; // Ensure the component name is correctly capitalized
import SelectQuestions from './selectQuestions';
import Snackbar from '@mui/material/Snackbar';
import { Alert } from '@mui/material';
import Select, { components } from 'react-select';
import { resetQuestions, getv2AdditionalQuestion, getNextv2AdditionalQuestion, getQuestionLevels, selectOneQuestion, removeQuestion, saveSelectQuestion, checkValidTest, getTestById } from '../../Redux/ActionCreators/testLibraryAction';
import { resetMYTest, getMyTests, getNextMyTests, getQuestionSource, getSkills, getSkillTopics, getQuestionCount, savedTest, updateTest, emptyTopics } from '../../Redux/ActionCreators/myTestsAction';
import { getTotal } from '../../utils/miscelleneous';
import { useNavigate } from 'react-router-dom';
import EditTestModal from './editTestModal';
import { Modal } from 'react-bootstrap';
import { Dialog } from '@mui/material';
import { useTranslation } from 'react-i18next';

const DropdownIndicator = props => {
    return (
        <components.DropdownIndicator {...props}>
            <img src='/images/mdi_menu-down.svg' alt='' />
        </components.DropdownIndicator>
    );
};

function MyTests() {
    const [isLoading, setIsLoading] = useState(false);
    const [isNewTestPage, setIsNewTestPage] = useState(false);
    const [selectQuestionsMode, setSelectQuestionsMode] = useState(null);
    const [successMessageText, setsuccessMessageText] = useState('');
    const [successMessage, setSuccessMessage] = useState(false);
    const [testName, setTestName] = useState('');
    const [testNameErr, setTestNameErr] = useState('');
    const [shouldValidate, setShouldValidate] = useState(false);
    const [query, setQuery] = useState('');
    const [page, setPage] = useState(1);
    const [testType, setTestType] = useState(-1);
    const [qSource, setQSource] = useState('');
    const [skill, setSkill] = useState('');
    const [topic, setTopic] = useState('');
    const [level, setLevel] = useState('');
    const [qCount, setQCount] = useState('');
    const [countOption, setCountOption] = useState([]);
    const [automaticQues, setAutomaticQues] = useState([]);
    const [searchQues, setSearchQues] = useState('');
    const [quesPage, setQuesPage] = useState(1);
    const [isEdit, setIsEdit] = useState(false);
    const [duration, setDuration] = useState(0);
    const [editDetails, setEditDetails] = useState('');
    const [isEditTopic, setIsEditTopic] = useState(false);
    const { t } = useTranslation();
    const [editTopicDetail, setEditTopicDetail] = useState(null);
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { myTests, total, questionSource, skills, topics, count, isTestLoading, testTypes } = useSelector(state => state.myTests);
    const { questionLevel, isQuestionLoading, additionalQuestions, totalQues, selectedQuestion } = useSelector(state => state.testLibrary);
    const rows = 20

    useEffect(() => {
        dispatch(getMyTests(testType, query, page, rows));
        dispatch(getQuestionSource());
    }, [])

    const fetchData = () => {
        setPage(page + 1);
        dispatch(getNextMyTests(testType, query, page + 1, rows));
    }

    const timerRef1 = React.useRef(null);

    const searchTest = (val) => {
        clearTimeout(timerRef1.current);
        setQuery(val);
        setPage(1);
        timerRef1.current = setTimeout(() => delaySearch(val), 800);
    }

    const delaySearch = async (val) => {
        await dispatch(resetMYTest());
        await dispatch(getMyTests(testType, val, page, rows));
    }

    const selectTestType = async (val) => {
        setTestType(val);
        setPage(1);
        await dispatch(resetMYTest());
        await dispatch(getMyTests(val, query, 1, rows));
    }

    const createTest = () => {
        if (window.innerWidth > 767) {
            setIsNewTestPage(true);
            setSelectQuestionsMode(null);
            setTestName('');
            setTestNameErr('');
            setShouldValidate(false);
            setEditDetails(null);
            setAutomaticQues([]);
            setEditTopicDetail(null);
        } else {
            setSuccessMessage(true);
            setsuccessMessageText(t('You can create test from Desktop.Mobile view coming soon',{ns: 'mytest' }));
        }
    };

    useEffect(() => {
        if (!shouldValidate) return
        const delay = setTimeout(() => {
            validateNameViaApi(testName);
        }, 200);

        return () => clearTimeout(delay);
    }, [testName, shouldValidate]);



    const validateNameViaApi = async (input) => {
        const res = input !== '' && await dispatch(checkValidTest(input));
        if (res?.payload?.isValid) {
            setTestNameErr('');
        } else {
            setTestNameErr(t('Test Name already exist',{ns: 'mytest'}))
        }
    };

    const goBack = () => {
        setIsNewTestPage(false);
    };

    const goBackNewTest = () => {
        setIsNewTestPage(editDetails ? false : true);
        setSelectQuestionsMode(null);
        setTestType(-1);
    }

    const handleTestName = (val) => {
        setTestName(val);
        setTestNameErr('');
        setShouldValidate(true);
    }

    const setMode = async (mode) => {
        if (!testName) {
            setSuccessMessage(true);
            setsuccessMessageText(t('Please enter Test Name',{ns: 'mytest' }));
        }
        else {
            setSelectQuestionsMode(mode);
            setTestType(mode === 'auto' ? 4 : 5);
            setSkill('');
            setTopic('');
            if (mode === 'manual') {
                await dispatch(resetQuestions());
                await dispatch(getv2AdditionalQuestion('', skill?.value || 0, topic?.value || 0, level?.value || 0, 0, 1, rows));
                await dispatch(getSkills(1));
            }
        }
    };

    const fetchManualData = async () => {
        await setQuesPage(quesPage + 1);
        await getNextv2AdditionalQuestion(searchQues, skill?.value || 0, topic?.value || 0, level?.value || 0, 0, quesPage + 1, rows)
    }


    const setskill = async (e) => {
        setSkill(e)
        setTopic('');
        await dispatch(emptyTopics());
        if (e?.value) {
            await dispatch(getSkillTopics(1, e?.value));
        }
    }

    const setTags = async (e) => {
        await setTopic(e)
        await dispatch(getv2AdditionalQuestion(searchQues, skill?.value || 0, e?.value || 0, level?.value || 0, 0, 1, rows));
    }

    const handleSearch = async (val) => {
        setSearchQues(val);
        await dispatch(getv2AdditionalQuestion(val, skill?.value || 0, topic?.value || 0, level?.value || 0, 0, 1, rows));
    }

    const selectSource = (e) => {
        setQSource(e);
        setSkill('');
        setTopic('');
        dispatch(getSkills(e.value));
        dispatch(getQuestionLevels());

    }

    const selectSkill = (e) => {
        setSkill(e);
        dispatch(getSkillTopics(qSource?.value, e.value));
    }

    const checkDuplicates = (id) => {
        const found = automaticQues.find(el => ((el.skillId === skill?.value) && (el.skillTopicId === id)));
        if (found) {
            return true
        } else {
            return false
        }
    }

    const selectSkillTopic = async (e) => {
        if (checkDuplicates(e.value)) {
            setTopic('');
            setSuccessMessage(true);
            setsuccessMessageText(t('This topic is already added',{ns: 'mytest' }));
        } else {
            setTopic(e);
            setLevel({ label: 'All', value: 0 });
            const res = await dispatch(getQuestionCount(qSource?.value, skill?.value, e?.value, 0));
            if (res?.type === 'GET_COUNT') {
                let arr = Array.from({ length: res.payload }, (_, i) => i + 1).map(el => [{ label: el, value: el }][0]);
                setCountOption(arr);
                setQCount({ label: res.payload, value: res.payload })
            }
        }
    }


    const selectLevel = async (e) => {
        setLevel(e);
        const res = await dispatch(getQuestionCount(qSource?.value, skill?.value, topic?.value, e.value));
        if (res?.type === 'GET_COUNT') {
            let arr = Array.from({ length: res.payload }, (_, i) => i + 1).map(el => [{ label: el, value: el }][0]);
            setCountOption(arr);
            setQCount({ label: res.payload, value: res.payload })
        }
    }


    const addMore = async () => {
        if (!qSource?.value) {
            setSuccessMessage(true);
            setsuccessMessageText(t('Please Select Question Source',{ns: 'mytest' }));
        } else if (!qCount?.value) {
            setSuccessMessage(true);
            setsuccessMessageText(t('Please Select Question Count',{ns: 'mytest' }));
        }

        else {
            let obj = {
                "skillId": skill?.value,
                "skillTopicId": topic?.value,
                "questionCount": qCount?.value,
                "questionSourceId": qSource?.value,
                "questionLevel": level?.value,
                levelName: level?.label,
                sourceName: qSource?.label,
                skillName: skill?.label,
                topicName: topic?.label,
            }
            console.log(obj)
            await setIsLoading(true);
            await setAutomaticQues(prevAutomaticQues => [...prevAutomaticQues, obj]);
            // await setSkill('');
            // await setQSource('');
            await setTopic('');
            await setLevel('');
            await setQCount('');
            await setIsLoading(false);
        }
    }

    useEffect(() => {
        const updateDuration = async () => {
            const newDuration = testType === 4 ? await getTotal(automaticQues, 'ques') : (selectedQuestion?.length * 1);
            await setDuration(newDuration);
        }
        updateDuration()
    }, [automaticQues, selectedQuestion]);

    const clearResponse = async () => {
        await setSkill('');
        await setQSource('');
        await setTopic('');
        await setLevel('');
        await setQCount('');
    }

    const removeFromList = (data) => {
        const filter = automaticQues?.filter(el => el.skillId !== data.skillId)
        setAutomaticQues(filter);
    }

    const editAutoTopic = async (data) => {
        await setIsEditTopic(true);
        await setEditTopicDetail(data);
        await dispatch(getSkills(data?.questionSourceTypeId));
        await dispatch(getSkillTopics(data?.questionSourceTypeId, data.skillId));
        await dispatch(getQuestionLevels());
        const res = await dispatch(getQuestionCount(data?.questionSourceTypeId, data.skillId, data.skillTopicId, data.questionLevelId));
        await setEditTopicDetail({ ...editTopicDetail, ...data, questionCount: res.payload });
    }

    const handleEdit = async (e, field) => {
        console.log(editTopicDetail)
        if (field === 'skill') {
            setEditTopicDetail({ ...editTopicDetail, skillName: e.label, skillId: e.value, skillTopicName: null, skillTopicId: null, questionCount: '' });
            dispatch(getSkillTopics(editTopicDetail?.questionSourceTypeId, e.value));
        }
        else if (field === 'topic') {
            setEditTopicDetail({ ...editTopicDetail, skillTopicName: e.label, skillTopicId: e.value });
            const res = await dispatch(getQuestionCount(editTopicDetail?.questionSourceTypeId, editTopicDetail.skillId, editTopicDetail.skillTopicId, editTopicDetail?.questionLevelId));
        }
        else if (field === 'level') {
            setEditTopicDetail({ ...editTopicDetail, questionLevelName: e.label, questionLevelId: e.value });
            const res = await dispatch(getQuestionCount(editTopicDetail?.questionSourceTypeId, editTopicDetail.skillId, editTopicDetail.skillTopicId, e.value));
            await setEditTopicDetail({ ...editTopicDetail, questionCount: res.payload });
        }
        else if (field === 'quesCount') {
            setEditTopicDetail({ ...editTopicDetail, questionCount: (Number(e.value)) });
        }
    }


    const replaceObjectInArray = (array, _id, newObject) => {
        const idMap = new Map(array.map(obj => [obj.testTopicId, obj]));
        if (idMap.has(_id)) {
            idMap.set(_id, { ...newObject });
            return Array.from(idMap.values());
        }
        return array;
    }

    const updateTopicDetail = () => {
        setIsEditTopic(false);
        const found = replaceObjectInArray(automaticQues, editTopicDetail?.testTopicId, editTopicDetail)
        setAutomaticQues(found);
        setEditTopicDetail(null);
    }

    const saveTest = async () => {
        if (testType === 4 ? !automaticQues?.length : !selectedQuestion?.length) {
            setSuccessMessage(true);
            setsuccessMessageText(t('Please Select Questions',{ns: 'mytest' }));
        }
        else {
            await setLoading(true);
            let obj = testType === 4 ?
                {
                    "testName": testName,
                    "duration": (getTotal(automaticQues, 'ques') * 1),
                    "questionCount": getTotal(automaticQues, 'ques'),
                    "testType": testType,
                    "topics": automaticQues?.map(el => [{ skillId: el.skillId, skillTopicId: el.skillTopicId, questionCount: el.questionCount, questionSourceId: el.questionSourceId, questionLevel: el.questionLevel }][0]),
                    "questions": []
                } :
                {
                    "testName": testName,
                    "duration": selectedQuestion?.length * 1,
                    "questionCount": selectedQuestion?.length,
                    "testType": testType,
                    "topics": [],
                    "questions": selectedQuestion?.map(el => el.questionID)
                }

            const res = await dispatch(savedTest(obj));
            if (res?.type === 'CREATE_MY_TEST') {
                setPage(1);
                setQuery('');
                setTestType(-1);
                setEditDetails('');
                // res.payload.testId
                dispatch(getMyTests(-1, '', 1, rows));
            }

            await backTomyTest();
        }
        await setLoading(false);
    }

    const editMyTest = async () => {
        if (testType === 4 ? !automaticQues?.length : !selectedQuestion?.length) {
            setSuccessMessage(true);
            setsuccessMessageText(t('Please Select Questions',{ns: 'mytest' }));
        }
        else {
            await setLoading(true);
            let obj = testType === 4 ?
                {
                    testId: editDetails?.testId,
                    "testName": testName,
                    "duration": (getTotal(automaticQues, 'ques') * 1),
                    "questionCount": Number(getTotal(automaticQues, 'ques')),
                    "testType": testType,
                    "topics": automaticQues?.map(el => [{ skillId: el.skillId, skillTopicId: el.skillTopicId, questionCount: el.questionCount, questionSourceId: el.questionSourceId, questionLevel: el.questionLevelId }][0]),
                    "questions": []
                } :
                {
                    testId: editDetails?.testId,
                    "testName": testName,
                    "duration": selectedQuestion?.length * 1,
                    "questionCount": selectedQuestion?.length,
                    "testType": testType,
                    "topics": [],
                    "questions": selectedQuestion?.map(el => (el.questionId || el.questionID))
                }

            const res = await dispatch(updateTest(obj));
            if (res?.type === 'UPDATE_MY_TEST') {
                setPage(1);
                setQuery('');
                setTestType(-1);
                setEditDetails('');
                dispatch(getMyTests(-1, '', 1, rows));
            }
            await backTomyTest();
        }
        await setLoading(false);
    }


    const addToAssessment = async () => {
        if (testType === 4 ? !automaticQues?.length : !selectedQuestion?.length) {
            setSuccessMessage(true);
            setsuccessMessageText(t('Please Select Questions',{ns: 'mytest' }));
        } else {
            let obj = testType === 4 ?
                {
                    "testName": testName,
                    "duration": (getTotal(automaticQues, 'ques') * 1),
                    "questionCount": getTotal(automaticQues, 'ques'),
                    "testType": testType,
                    "topics": automaticQues?.map(el => [{ skillId: el.skillId, skillTopicId: el.skillTopicId, questionCount: el.questionCount, questionSourceId: el.questionSourceId, questionLevel: el.questionLevel }][0]),
                    "questions": []
                } :
                {
                    "testName": testName,
                    "duration": selectedQuestion?.length * 1,
                    "questionCount": selectedQuestion?.length,
                    "testType": testType,
                    "topics": [],
                    "questions": selectedQuestion?.map(el => el.questionID)
                }

            const res = await dispatch(savedTest(obj));
            if (res?.type === 'CREATE_MY_TEST') {
                setPage(1);
                setQuery('');
                setTestType(0);
                setEditDetails('');
                navigate(`/home/select-test`, {
                    state: {
                        gappeoTest: false,
                        detail: { referenceTypeId: 1, referenceId: res.payload.testId, referenceName: testName, testType: 0 },
                        next: true
                    }
                })
            }
        }

    }



    const backTomyTest = () => {
        setIsNewTestPage(false);
    }

    const snackClosed = () => {
        setSuccessMessage(false);
        setsuccessMessageText('');
    }


    const goToSelectTest = (items, type) => {
        navigate(`/home/select-test`, {
            state: {
                gappeoTest: false,
                detail: { referenceTypeId: 1, referenceId: items.testID, referenceName: items.testName, testType: 0 },
                next: true
            }
        })
    }

    const editDetail = (name, duration) => {
        if (!name) {
            setSuccessMessage(true);
            setsuccessMessageText(t('Please Enter Test name',{ns: 'mytest' }));
        } else if (!duration || duration === 0) {
            setSuccessMessage(true);
            setsuccessMessageText(t('Please Enter Duration',{ns: 'mytest' }));
        } else {
            setTestName(name);
            setDuration(duration);
            setIsEdit(false);
        }
    }

    const editTest = async (data) => {
        if (window.innerWidth > 767) {
            await setIsLoading(true);
            setIsNewTestPage(true);
            setSelectQuestionsMode(data?.testType === 4 ? 'auto' : 'manual');
            const res = await dispatch(getTestById(data.testID));
            if (res?.type === 'GET_TEST_BY_ID') {
                setEditDetails(res.payload);
                setAutomaticQues(res.payload.topics);
                setTestName(res.payload?.testName);
                setTestType(res.payload?.testType)

                if (res.payload?.testType === 5) {
                    await dispatch(resetQuestions());
                    await dispatch(getv2AdditionalQuestion('', skill?.value || 0, topic?.value || 0, level?.value || 0, 0, 1, rows));
                    await dispatch(getSkills(1));
                }
            }
            await setIsLoading(false);

        } else {
            setSuccessMessage(true);
            setsuccessMessageText(t('You can create test from Desktop.Mobile view coming soon',{ns: 'mytest' }));
        }
    }


    return (
        <div className='gappeo-my-tests' >
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                open={successMessage}
                onClose={snackClosed}
                autoHideDuration={4000}
            >
                <Alert onClose={snackClosed} severity="error" sx={{ width: '100%' }}>
                    {successMessageText}
                </Alert>
            </Snackbar>
            <Dialog
                className='screen-loader'
                open={loading}
                onClose={() => { }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                {t('Loading',{ns: 'mytest'})}...
            </Dialog>
            {isNewTestPage ? (
                selectQuestionsMode === 'auto' ? (
                    <div className='newtests'>
                        <div className='textclass d-flex justify-content-center align-items-center'>
                            <img className='backarrow manuallback' src='/images/back.svg' onClick={goBackNewTest} alt='' />
                            <h1 className='newtestpage'>{t('Test Summary',{ns: 'mytest'})}</h1>
                        </div>
                        <Card className='summarycard' >
                            <div className='summaryheader d-flex justify-content-center align-items-center'>
                                <div className='headeritem'>
                                    <img className='editimg' src='/images/info-green.svg' alt='' />&nbsp;&nbsp;
                                    <div className='' >
                                        <p>{t('Test Name',{ns: 'mytest'})}</p>
                                        <div className='d-flex mt-1 align-item-center' >
                                            <h4>{testName}</h4> &nbsp;&nbsp;
                                            <img src='/images/edit-icon.svg' className='cursor-pointer' alt='' onClick={() => setIsEdit(true)} />
                                        </div>
                                    </div>
                                </div>
                                <div className='headeritem'>
                                    <img className='editimg' src='/images/ph_question-light.svg' alt='' />&nbsp;
                                    <div className='' >
                                        <p>{t('Questions',{ns: 'mytest'})}</p>
                                        <div className='d-flex mt-1 align-item-center' >
                                            <h4>{getTotal(automaticQues, 'ques')}</h4> &nbsp;&nbsp;&nbsp;&nbsp;
                                        </div>
                                    </div>
                                </div>
                                <div className='headeritem'>
                                    <img className='editimg' src='/images/lets-icons_time-progress-light.svg' alt='' />&nbsp;
                                    <div className='' >
                                        <p>{t('Minutes',{ns: 'mytest'})}</p>
                                        <div className='d-flex mt-1 align-item-center' >
                                            <h4>{duration}</h4> &nbsp;&nbsp;&nbsp;&nbsp;
                                            <img className='cursor-pointer' src='/images/edit-icon.svg' onClick={() => setIsEdit(true)} alt='' />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='summarybody'>
                                <div className='summaryfiels d-flex justify-content-center align-items-center' >
                                    <div style={{ width: '100%' }}>
                                        <Select
                                            components={{ DropdownIndicator }}
                                            placeholder={t('Questions Source',{ns: 'mytest'})}
                                            options={questionSource}
                                            value={qSource}
                                            onChange={(e) => selectSource(e)}
                                        />
                                    </div>
                                    <div style={{ width: '100%' }}>
                                        <Select
                                            components={{ DropdownIndicator }}
                                            placeholder={t('Knowledge Area',{ns: 'mytest'})}
                                            options={skills}
                                            value={skill}
                                            onChange={(e) => selectSkill(e)}
                                            isDisabled={!qSource?.value}
                                        />
                                    </div>
                                    <div style={{ width: '100%' }}>
                                        <Select
                                            components={{ DropdownIndicator }}
                                            placeholder={t('Topics / Tags',{ns: 'mytest'})}
                                            options={topics}
                                            value={topic}
                                            onChange={(e) => selectSkillTopic(e)}
                                            isDisabled={!skill?.value}
                                        />
                                    </div>
                                    <div style={{ width: '100%' }}>
                                        <Select
                                            components={{ DropdownIndicator }}
                                            placeholder={t('Question Level',{ns: 'mytest'})}
                                            options={[{ label: 'All', value: 0 }, ...questionLevel?.map(el => [{ label: el.questionLevelName, value: el.questionLevelID }][0])]}
                                            value={level}
                                            onChange={(e) => selectLevel(e)}
                                            isDisabled={!topic?.value}
                                        />
                                    </div>
                                    <div style={{ width: '100%' }}>
                                        <Select
                                            components={{ DropdownIndicator }}
                                            placeholder={t('#Question',{ns: 'mytest'})}
                                            options={countOption}
                                            value={qCount}
                                            onChange={(e) => setQCount(e)}
                                            isDisabled={!level?.value}
                                        />
                                        {/* <input type='number' required style={{ height: 48, borderRadius: 0 }} disabled={!level?.value} className='form-control' placeholder='#Questions' value={qCount} onChange={(e) => setQCount(e.target.value)} /> */}
                                    </div>
                                </div>
                                <div className='d-flex align-items-center mt-3'>
                                    <button className='generate-btn' disabled={qSource === '' || skill === '' || topic === '' || level === '' || qCount === ''} onClick={addMore}>{t('Add More',{ns: 'mytest'})}</button>&nbsp;&nbsp;&nbsp;&nbsp;
                                    <h5 className='action' onClick={clearResponse} >{t('CLEAR SELECTION',{ns: 'mytest'})}</h5>
                                </div>
                                <hr />
                                <div id='scroll' style={{ maxHeight: 400, minHeight: 333, overflow: 'auto' }}>
                                    <div className='mt-2'>
                                        <div className='testupdate border-none' style={{ padding: '0px 20px' }} >
                                            <div className='testsummary'>
                                                <h3>{t('Knowledge Area',{ns: 'mytest'})}</h3>

                                            </div>
                                            <div className='testsummary'>
                                                <h3>{t('Topic/tags',{ns: 'mytest'})}</h3>
                                            </div>
                                            <div className='testlevel' >
                                                <p style={{ fontWeight: 700 }}>{t('Question Level',{ns: 'mytest'})}</p>

                                            </div>
                                            <div className='testlevel'>
                                                <p style={{ fontWeight: 700 }}>{t('Questions',{ns: 'mytest'})}</p>
                                            </div>
                                            <div className='testitems'>
                                                <p style={{ color: '#555c5e', fontWeight: 700 }}>{t('Action',{ns: 'mytest'})}</p>
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        automaticQues?.length > 0 ?
                                            automaticQues?.map((el, i) => {
                                                return (
                                                    <Card className='mt-2'>
                                                        <div className='testupdate' >
                                                            <div className='testsummary'>
                                                                <h3>{el.skillName}</h3>

                                                            </div>
                                                            <div className='testsummary'>
                                                                <p>{el.topicName || el.skillTopicName}</p>
                                                            </div>
                                                            <div className='testlevel' >
                                                                <p>{el.levelName || el.questionLevelName}</p>

                                                            </div>
                                                            <div className='testlevel'>
                                                                <p>{el.questionCount} {t('Questions',{ns: 'mytest'})}</p>
                                                            </div>
                                                            <div className='testitems'>
                                                                {editDetails && <img className='testicons' src='/images/edit-black-icon.svg' alt='' onClick={() => editAutoTopic(el)} />}
                                                                <img className='testicons cursor-pointer' src='/images/delete-icon.svg' alt='' onClick={(e) => removeFromList(el)} />
                                                            </div>
                                                        </div>
                                                    </Card>
                                                )
                                            }) :
                                            <div>
                                                <p>{t('You have not added any question yet',{ns: 'mytest'})}</p>
                                            </div>
                                    }

                                </div>
                            </div>
                        </Card>
                        <div className='d-flex' >
                            <button className='generate-btn' onClick={editDetails ? editMyTest : saveTest}>{t('SAVE & EXIT',{ns: 'mytest'})}</button>&nbsp;&nbsp;&nbsp;
                            <button className='outline-btn' onClick={addToAssessment}>{t('ADD TO ASSESSMENT',{ns: 'mytest'})}</button>
                        </div>
                    </div>
                ) : selectQuestionsMode === 'manual' ? (
                    <SelectQuestions
                        isQuestionLoading={isQuestionLoading}
                        additionalQuestions={additionalQuestions}
                        total={totalQues}
                        fetchData={fetchManualData}
                        goBackNewTest={goBackNewTest}
                        backTomyTest={backTomyTest}
                        selectOneQuestion={(val, checked, detail) => { dispatch(selectOneQuestion(val, checked, detail)) }}
                        selectedQuestion={selectedQuestion}
                        removeQuestion={(data) => dispatch(removeQuestion(data))}
                        saveSelectQuestion={(data) => dispatch(saveSelectQuestion(data))}
                        skills={skills}
                        setSkill={setskill}
                        setTags={setTags}
                        topics={topics}
                        testName={testName}
                        duration={duration}
                        openEdit={() => setIsEdit(true)}
                        saveTest={saveTest}
                        addToAssessment={addToAssessment}
                        handleSearch={handleSearch}
                        editDetails={editDetails}
                        editMyTest={editMyTest}
                        isLoading={isLoading}
                    />
                ) : (
                    <div className='newtests test-options'>
                        <div className='textclass d-flex justify-content-center align-items-center'>
                            <img className='backarrow' src='/images/back.svg' onClick={goBack} alt='' />
                            <h1 className='newtestpage'>{t('New Test',{ns: 'mytest'})}</h1>
                        </div>
                        <div className='search-tests  mt-3' style={{ paddingTop: "1.5rem" }}>
                            <div className="has-search">
                                <span className="form-control-feedback">
                                    <img src="/images/search.svg" alt='' width={12} />
                                </span>
                                <input type="text" autoComplete='off' className="form-control" placeholder={t('Enter Test name',{ns: 'mytest'})} value={testName} onChange={(e) => handleTestName(e.target.value)} />
                            </div>
                            <div style={{ color: 'red', marginTop: 5 }}>{testNameErr}</div>
                        </div>
                        <div className='d-flex'>
                            <Card className='testscart'>
                                <img src="/images/list-timeline.svg" alt='' width={32} />
                                <Card.Body className='cardbody'>
                                    <h3>{t('Select questions automatically',{ns: 'mytest'})}</h3>
                                    <p className='BodyText'>{t('Questions will be selected based on the knowledge area and topics you choose',{ns: 'mytest'})}</p>
                                    <button className='mytestbtn' disabled={testNameErr} onClick={() => setMode('auto')}>{t('CONTINUE',{ns: 'mytest'})}</button>
                                </Card.Body>
                            </Card> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <Card className='testscart'>
                                <img src="/images/choose 1.svg" alt='' width={32} />
                                <Card.Body className='cardbody'>
                                    <h3>{t('Select questions manually',{ns: 'mytest'})}</h3>
                                    <p className='BodyText'>{t('Select from a list of questions and add them to the test',{ns: 'mytest'})}</p>
                                    <button className='mytestbtn' disabled={testNameErr} onClick={() => setMode('manual')}>{t('CONTINUE',{ns: 'mytest'})}</button>
                                </Card.Body>
                            </Card>
                        </div>
                    </div>
                )
            ) : (

                //List
                <div className='newtests' >
                    <div className='d-flex justify-content-center align-items-center'>
                        <h1>{t('My Tests',{ns: 'mytest'})}</h1>
                    </div>
                    <p className='caption'>{t('Custom tests that you can create yourself and add to assessments',{ns: 'mytest'})}</p>
                    <div className={myTests?.length === 0 ? 'd-flex justify-content-end align-items-end mt-2' : 'd-flex justify-content-between align-items-end mt-2'} >
                        {myTests?.length === 0 ? '' : <p className='caption'>{t('Showing',{ns: 'mytest'})} {myTests?.length} {t('tests',{ns: 'mytest'})}</p>}
                        <button className='mytestbtn' onClick={createTest}>{t('NEW TEST',{ns: 'mytest'})}</button>
                    </div>
                    <div className='my-tests d-flex mt-3'>
                        <div className="has-search" style={{ width: '82%' }}>
                            <span className="form-control-feedback">
                                <img src="/images/search.svg" alt='' width={12} />
                            </span>
                            <input type="search" className="form-control" value={query} placeholder={t('Search for test',{ns: 'mytest'})} onChange={(e) => searchTest(e.target.value)} />
                        </div>
                        {/* <div style={{ width: '18%' }}>
                            <select>
                                <option hidden>Difficulty Level</option>
                            </select>
                        </div> */}
                        <div style={{ width: '18%' }}>
                            <select value={testType} onChange={(e) => selectTestType(e.target.value)} >
                                <option hidden>{t('Question Type',{ns: 'mytest'})}</option>
                                <option value={-1}>{t('All',{ns: 'mytest'})}</option>
                                {
                                    testTypes?.map(el => {
                                        return (
                                            <option value={el.id}>{el.name}</option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                    </div>
                    {isLoading ?
                        t('Loading..',{ns: 'home'}) :
                        (
                            <div className="table-admin">
                                <InfiniteScroll
                                    dataLength={myTests && myTests?.length} //This is important field to render the next data
                                    next={total > myTests?.length ? fetchData : ''}
                                    hasMore={true}

                                >
                                    {isTestLoading
                                        ?
                                        t('Loading..',{ns: 'home'})
                                        :
                                        myTests?.length > 0 ? (
                                            <Table responsive>
                                                <tbody className="table-body">
                                                    {myTests.map(el => (
                                                        <tr key={el.id}>
                                                            <td>
                                                                <h4>{el.testName}</h4>
                                                            </td>
                                                            <td>
                                                                <h5>{el.Level}</h5>
                                                            </td>
                                                            <td>
                                                                <h5>{el.testType === 4 ? 'Automatic' : 'Manual'}</h5>
                                                            </td>
                                                            <td>
                                                                <h5>{el.questionCount} Qs | {el.duration} {t('Mins',{ns: 'mytest'})}</h5>
                                                            </td>
                                                            <td className='cursor-pointer'>
                                                                <div className='create'>
                                                                    {el.canEdit === 1 ? <img className='' src='/images/edit-icon.svg' alt='' onClick={() => editTest(el)} /> : null}&nbsp;&nbsp;&nbsp;
                                                                    {/* <img className='' src='/images/ic_baseline-info.svg' alt='' />&nbsp;&nbsp;&nbsp; */}
                                                                    <img className='' src='/images/add-icon1.svg' alt='' onClick={() => goToSelectTest(el)} />
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </Table>
                                        ) : (
                                            <div className='empty-list'>
                                                <img src='/images/magnifier.svg' alt='' />
                                                <div className='mt-2'>
                                                    <h3>{t('There is no tests',{ns: 'mytest'})}</h3>
                                                </div>
                                            </div>
                                        )}
                                </InfiniteScroll>
                            </div>
                        )}
                </div>
            )}

            <EditTestModal
                isEdit={isEdit}
                setIsEdit={() => setIsEdit(false)}
                automaticQues={automaticQues}
                testName={testName}
                time={duration}
                editDetail={editDetail}
            />

            {/* ############################# Edit Topic Modal ########################### */}

            <Modal
                show={isEditTopic}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                dialogClassName={"add-test-content"}
            >
                <Modal.Body>
                    <div className='sticky-close'>
                        <img src='/images/close.svg' alt='' onClick={() => setIsEditTopic(false)} />
                    </div>
                    <div className='summaryfiels' >

                        <div className='form-group mt-2' style={{ width: '100%' }}>
                            <Select
                                components={{ DropdownIndicator }}
                                placeholder={t('Knowledge Area',{ns: 'mytest'})}
                                options={skills}
                                name='skill'
                                value={{ label: editTopicDetail?.skillName, value: editTopicDetail?.skillId }}
                                onChange={(e) => handleEdit(e, 'skill')}
                            // isDisabled={!qSource?.value}
                            />
                        </div>
                        <div className='form-group mt-2' style={{ width: '100%' }}>
                            <Select
                                components={{ DropdownIndicator }}
                                placeholder={t('Topics / Tags',{ns: 'mytest'})}
                                name='topic'
                                options={topics}
                                value={{ label: editTopicDetail?.skillTopicName, value: editTopicDetail?.skillTopicId }}
                                onChange={(e) => handleEdit(e, 'topic')}

                            />
                        </div>
                        <div className='form-group mt-2' style={{ width: '100%' }}>
                            <Select
                                components={{ DropdownIndicator }}
                                placeholder={t('Question Level',{ns: 'mytest'})}
                                name='level'
                                options={[{ label: 'All', value: 0 }, ...questionLevel?.map(el => [{ label: el.questionLevelName, value: el.questionLevelID }][0])]}
                                value={{ label: editTopicDetail?.questionLevelName, value: editTopicDetail?.questionLevelId }}
                                onChange={(e) => handleEdit(e, 'level')}
                            />
                        </div>
                        <div className='form-group mt-2' style={{ width: '100%' }}>
                            <Select
                                components={{ DropdownIndicator }}
                                placeholder={t('#Question',{ns: 'mytest'})}
                                options={countOption}
                                value={{ label: editTopicDetail?.questionCount, value: editTopicDetail?.questionCount }}
                                onChange={(e) => handleEdit(e, 'quesCount')}
                            />
                            {/* <input type='number' required style={{ height: 48, borderRadius: 0 }} className='form-control' placeholder='#Questions' value={editTopicDetail?.questionCount} onChange={(e) => handleEdit(e.target.value, 'count')} /> */}
                        </div>
                    </div>

                    <div className='form-group'>
                        <button className='form-control position-relative'
                            onClick={() => {
                                updateTopicDetail()
                            }}
                        >
                            {t('Update',{ns: 'mytest'})}
                        </button>
                    </div>
                </Modal.Body>

            </Modal>
        </div>
    );
}

export default MyTests;
