import { combineReducers } from "redux";
import authReducer from "./authReducer";
import errorReducer from "./errorReducer";
import layoutReducer from "./layoutReducer";
import testLibraryReducer from "./testLibraryReducer";
import candidateTestReducer from "./candidateTestReducer";
import myAssessmentReducer from "./myAssessmentReducer";
import reportReducer from "./reportReducer";
import settingReducer from "./settingReducer";
import usersReducer from "./userReducer";
import gappeoTestReducer from "./gappeoTestReducer";
import myTestsReducer from "./myTestsReducer";




export const rootReducer = combineReducers({
    layout: layoutReducer,
    auth: authReducer,
    testLibrary: testLibraryReducer,
    candidateTest: candidateTestReducer,
    myAssessment: myAssessmentReducer,
    myTests: myTestsReducer,
    report: reportReducer,
    setting: settingReducer,
    users: usersReducer,
    gappeoTest: gappeoTestReducer,
    error: errorReducer
});