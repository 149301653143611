import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
import { Snackbar, Alert } from '@mui/material';
import { clearErrors } from '../Redux/ActionCreators/errorAction';
import { useDispatch } from 'react-redux';
import Footer from './footer';
import { useTranslation } from 'react-i18next';

export default function CandidateHeader() {

    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { candidateDetails, testDetails } = useSelector(state => state.candidateTest);
    const error = useSelector(state => state.error);


    const snackClosed = () => {
        dispatch(clearErrors());
    }

    return (
        <div className='candidate-holder' style={{ overflow: 'auto', maxHeight: '100vh' }}>
            <div className='candidate-header bg-dark' style={{ zIndex: 1 }}>
                {candidateDetails?.logoImage ? <img src={candidateDetails?.logoImage} alt='' width='auto' height={50} /> : <img src='/images/logo.png' width='auto' height={50} alt='' />}
                {
                    window.innerWidth > 767 ?
                        <Fragment>
                            {window.location.pathname.split('/')[1] === 'candidate-invite-test' ? null : <h3>{t('Candidate Test Assessment', { ns: 'profile' })}</h3>}
                            {candidateDetails &&
                                <div className='d-flex align-items-center'>
                                    <img src='/images/user-icon.svg' alt='' />&nbsp;&nbsp;
                                    <div>
                                        <h6><span className='notranslate'>{candidateDetails?.name}</span></h6>
                                        <h6 style={{ fontWeight: 'normal' }}>{t('Assessment', { ns: 'profile' })}: {testDetails?.testName}</h6>

                                    </div>
                                </div>
                            }
                        </Fragment> :
                        <div className='d-flex flex-column'>
                            {window.location.pathname.split('/')[1] === 'candidate-invite-test' ? null : <h3>{t('Candidate Test Assessment', { ns: 'profile' })}</h3>}
                            {candidateDetails &&
                                <div className='d-flex align-items-center'>
                                    <img src='/images/user-icon.svg' alt='' />&nbsp;&nbsp;
                                    <div>
                                        <h6>{candidateDetails?.name}</h6>
                                        <h6 style={{ fontWeight: 'normal' }}>{t('Assessment', { ns: 'profile' })}: {testDetails?.testName}</h6>

                                    </div>
                                </div>
                            }
                        </div>
                }

            </div>
            <div className='layout-container'>
                <Snackbar
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center', }}
                    open={error.successMessage}
                    autoHideDuration={3000}
                    onClose={snackClosed}
                >
                    <Alert onClose={snackClosed} severity="error" sx={{ width: '100%' }}>
                        {error.successMessageText}
                    </Alert>
                </Snackbar>
                <Snackbar
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center', }}
                    open={error.successEnable}
                    onClose={() => snackClosed()}
                    autoHideDuration={4000}
                >
                    <Alert onClose={snackClosed} severity="success" sx={{ width: '100%' }}>
                        {error.successMessageText}
                    </Alert>
                </Snackbar>
                <React.Suspense fallback={''}>
                    <Outlet />
                </React.Suspense>
            </div>
            <div className='text-center mt-5 mb-3'>
                <Footer />
            </div>
        </div>
    )
}
