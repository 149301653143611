import React, { Fragment, useEffect, useState } from 'react';
import Table from "react-bootstrap/Table";
import './myAssessment.scss';
import { useLocation, useNavigate } from 'react-router-dom';
import TestDetailModal from '../../utils/testDetailModal';
import { useDispatch } from 'react-redux';
import { checkValidTest, createCustomTest, getSelectedTests } from '../../Redux/ActionCreators/testLibraryAction';
import { useSelector } from 'react-redux';
import { getMyTests, getNextTest, resetTestLoader } from '../../Redux/ActionCreators/myAssessmentAction';
import InfiniteScroll from 'react-infinite-scroll-component';
import Modal from 'react-bootstrap/Modal';
import AddTestModal from '../../utils/addTestModal';
import { useTranslation } from 'react-i18next';

export default function MyAssessment() {
    const [isOpen, setIsOpen] = useState(false);
    const [testName, setTestName] = useState('');
    const [testNameErr, setTestNameErr] = useState('');
    const [description, setDescription] = useState('');
    const [loading, setLoading] = useState(false)
    const [query, setQuery] = useState('');
    const [page, setPage] = useState(1);
    const [isDetail, setIsDetail] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isAlert, setIsAlert] = useState(false);
    const { t } = useTranslation();
    const { isTestLoading, myAssessments, total } = useSelector(state => state.myAssessment);
    const { testDetails, } = useSelector(state => state.testLibrary);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const timerRef1 = React.useRef(null);



    const rows = 12
    useEffect(() => {
        dispatch(getMyTests(query, page, rows))
    }, [])

    const fetchData = () => {
        setPage(page + 1);
        dispatch(getNextTest(query, page + 1, rows));
    }


    const searchTest = (val) => {
        clearTimeout(timerRef1.current);
        setQuery(val);
        setPage(1);
        timerRef1.current = setTimeout(() => delaySearch(val), 800);
    }

    const delaySearch = async (val) => {
        await dispatch(resetTestLoader());
        await dispatch(getMyTests(val, page, rows));
    }

    const openReport = async (id) => {
        navigate('/assessments-report', { state: { id: id } })
    }

    const openDetail = (id) => {
        setIsLoading(true);
        dispatch(getSelectedTests(id));
        setIsDetail(true);
        setIsLoading(false);
    }

    const editTest = (testId, type) => {
        if (type === 'invite') {
            navigate('/home/invite-preview', {
                state: {
                    testId: testId,
                    myTest: true
                }
            })
        } else {
            navigate('/home/select-test', {
                state: {
                    testId: testId,
                    next: false
                }
            })
        }
    }

    const openAlert = () => {
        setIsAlert(true);
    }

    const goToReport = () => {
        navigate('/reports?tab=old')
    }


    const validateNameViaApi = async (input) => {
        if (input !== '') {
            const res = await dispatch(checkValidTest(input));
            if (res?.payload?.isValid) {
                setTestNameErr('');
            } else {
                setTestNameErr(t('Test Name already exist'));
            }
        }
    };

    useEffect(() => {
        const delay = setTimeout(() => {
            validateNameViaApi(testName);
        }, 200);

        return () => clearTimeout(delay);
    }, [testName]);

    const goToQuestion = async () => {
        setLoading(true);
        if (testName.trim('') === '') {
            setTestNameErr(t('Please Enter Test Name'));
            setLoading(false);
        }
        else {
            let obj = {
                "testName": testName,
                "testLevel": 2,
                "tests": [],
                testDescription: description,
                questions: []
            }
            const res = await dispatch(createCustomTest(obj));
            if (res?.type === "CREATE_CUSTOM_TEST") {
                await setIsOpen(false);
                setTestName('');
                setTestNameErr('');
                navigate(`/home/select-test`, { state: { testId: res.payload, next: false } });
            }
        }
        setLoading(false);
    }

    return (
        <div className='gappeo-my-tests'>
            <Fragment>
                <div className='search-test d-flex justify-content-between align-items-center flex-wrap'>
                    {window.innerWidth > 767 && <h1>                          </h1>}
                    <h1 className='text-center'>
                        {t('My Assessments', { ns: 'mytest' })}
                    </h1>
                    <button className='float-right' onClick={() => setIsOpen(true)} >{t('Create Assessment', { ns: 'mytest' })}</button>
                </div>
                <div className={window.innerWidth > 767 ? 'search-test mt-3' : 'search-test mt-1'}>
                    <div className="has-search"  >
                        <span className="form-control-feedback" >
                            <img src="/images/search.svg" alt='' width={12} />
                        </span>
                        <input type="search" className="form-control" value={query} placeholder={t('Search for a test / job role / test category', { ns: 'mytest' })} onChange={(e) => searchTest(e.target.value)} />
                    </div>
                </div>
                <div className="table-admin">
                    <InfiniteScroll
                        dataLength={myAssessments && myAssessments?.length} //This is important field to render the next data
                        next={total > myAssessments?.length ? fetchData : ''}
                        hasMore={true}
                    >
                        {isTestLoading ?
                            t('Loading..', { ns: 'home' }) :
                            myAssessments?.length > 0 ?

                                <Table responsive>
                                    <tbody className="table-body">
                                        {
                                            myAssessments && myAssessments?.map(el => {
                                                return (
                                                    <tr>
                                                        <td>
                                                            <h4>{el.testName}</h4>
                                                            <p>{el.questionCount} Qs | {el.duration} {t('Mins', { ns: 'mytest' })}</p>
                                                        </td>
                                                        <td>
                                                            <div className='d-flex'>
                                                                <img src='/images/invited-icon.svg' alt='' />&nbsp;&nbsp;
                                                                <div>
                                                                    <h3>{el.isNewTest === 1 ? el.testsInvited : el.isNewTest === 0 ? 'NA' : el.testsInvited}</h3>
                                                                    <h5>{t('Invited', { ns: 'mytest' })}</h5>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className='d-flex'>
                                                                <img src='/images/test-taken.svg' alt='' />&nbsp;&nbsp;
                                                                <div>
                                                                    <h3>{el.isNewTest === 1 ? el.testsTaken : el.isNewTest === 0 ? 'NA' : el.testsTaken}</h3>
                                                                    <h5>{t('Completed', { ns: 'mytest' })}</h5>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td className='cursor-pointer'>
                                                            {el.isNewTest === 1 ?
                                                                <h4 style={{ color: (el.testsInvited === 0 || el.testsTaken === 0) ? 'gray' : '#009199' }} onClick={() => (el.testsInvited === 0 || el?.testsTaken === 0) ? '' : openReport(el.testID)}>
                                                                    {t('REPORTS', { ns: 'mytest' })}
                                                                </h4> :
                                                                <h4 style={{ color: (el.testsInvited === 0 || el.testsTaken === 0) ? 'gray' : '#009199' }} onClick={() => (el.testsInvited === 0 || el?.testsTaken === 0) ? '' : goToReport()}>
                                                                    {t('REPORTS', { ns: 'mytest' })}
                                                                </h4>
                                                            }
                                                        </td>
                                                        <td className='cursor-pointer'>
                                                            {el.isNewTest === 1 ?
                                                                <h4 style={{ color: '#009199' }} onClick={() => openDetail(el.testID)}>
                                                                    {t('DETAILS', { ns: 'mytest' })}
                                                                </h4> :
                                                                "NA"
                                                            }
                                                        </td>
                                                        <td className='cursor-pointer'>
                                                            <img src='/images/invite-icon.svg' alt='' onClick={() => el.isNewTest === 1 ? editTest(el.testID, 'invite') : openAlert()} />
                                                        </td>
                                                        <td className='cursor-pointer'>
                                                            {(el.isNewTest === 1 || el.isNewTest === 0) ?
                                                                el.isDraft === 1 ?
                                                                    <img src='/images/edit-icon.svg' alt='' onClick={() => editTest(el.testID, 'select-test')} /> :
                                                                    '' :
                                                                ''
                                                            }
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </Table> :
                                <div className='empty-list'>
                                    <img src='/images/magnifier.svg' alt='' />
                                    <div className='mt-2'>
                                        <h3>{t('There is no tests', { ns: 'mytest' })}</h3>
                                    </div>
                                </div>
                        }
                    </InfiniteScroll>
                </div>
            </Fragment>

            {/* ########################## Test Details modal #######################3 */}
            <AddTestModal
                edit={false}
                isOpen={isOpen}
                setIsOpen={() => setIsOpen(false)}
                testName={testName}
                testNameErr={testNameErr}
                setTestName={(e) => setTestName(e)}
                setTestNameErr={(e) => setTestNameErr(e)}
                goToQuestion={goToQuestion}
                loading={loading}
                description={description}
                setDescription={(val) => setDescription(val)}
                type='my-assessment'
            />

            {/* ########################## Test Details modal #######################3 */}
            <TestDetailModal
                isLoading={isLoading}
                isDetail={isDetail}
                setIsDetail={() => setIsDetail(false)}
                testDetails={testDetails}
            />

            {/* ########################## Alert modal #######################3 */}

            <Modal
                show={isAlert}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                // onHide={this.handleCloseModal}
                dialogClassName={"confirm-modal "}
            >
                <div className="p-4" >
                    <h1>
                        {t('We have upgraded systems, pls create a new test and invite candidates', { ns: 'mytest' })}
                    </h1>
                    <div className="save-btn text-center">
                        <button onClick={() => setIsAlert(false)}>{t('Ok', { ns: 'mytest' })}</button>
                    </div>
                </div>
            </Modal>
        </div>
    )
}
