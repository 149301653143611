import React, { useEffect, useState } from 'react';
import './style.scss';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { resetCodingQuestions, getCodingQuestions, getNextCodingQuestions, selectCodingQues } from '../../Redux/ActionCreators/testLibraryAction';
import { useTranslation } from 'react-i18next';

export default function CodingQuestionList(props) {
    const [query, setQuery] = useState('');
    const [page, setPage] = useState(1);
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { loadingCodingQuestion, total, codingQuestionListResponse, selectedCodingQuestion } = useSelector(state => state.testLibrary)

    useEffect(() => {
        dispatch(resetCodingQuestions());
        dispatch(getCodingQuestions(1, 20, 0, 0, '', selectedCodingQuestion?.map(el => el.id)));
    }, [])

    const fetchData = () => {
        setPage(page + 1);
        dispatch(getNextCodingQuestions(page + 1, 20, props.testLevel, props.language, query, []));

    }

    const searchQuestion = () => {

    }

    const selectQuestion = (e, el) => {
        dispatch(selectCodingQues(e.target.value, e.target.checked, el));
    }

    const selectDone = () => {
        props.saveSelectQuestion(selectedCodingQuestion);
    }
    return (
        <div className='coding-questions-list'>
            <div className='question-list-header'>
                <div className='d-flex justify-content-between align-items-center'>
                    <img src='/images/back.svg' alt='' className='cursor-pointer' onClick={props.backToAdd} />
                    <h4>{t('Add  Question From List')} </h4>
                    <button className='done-btn' onClick={selectDone} >{t('DONE')}</button>
                </div>
                <div className='d-flex justify-content-between align-items-center flex-wrap mt-5'>
                    <div className="has-search"  >
                        <span className="form-control-feedback" >
                            <img src="/images/search.svg" alt='' />
                        </span>
                        <input type="search" className="form-control" value={query} placeholder="Search for a quesstion title" onChange={(e) => searchQuestion(e.target.value)} />
                    </div>
                    <h5>{t('Selected Question')}({props.questions?.length})</h5>
                    <h5 style={{ color: '#009199', cursor: 'pointer' }} >{t('Add Question')}</h5>
                </div>
            </div>
            <div style={{
                maxHeight: window.innerWidth > 767 ? 350 : 510,
                overflow: 'auto',
                overflowX: 'hidden'
            }} id="scrollableDiv1">
                <InfiniteScroll
                    dataLength={codingQuestionListResponse && codingQuestionListResponse?.length} //This is important field to render the next data
                    next={total > codingQuestionListResponse?.length ? fetchData : ''}
                    hasMore={true}
                    style={{ overflow: 'unset' }}
                    scrollableTarget="scrollableDiv1"
                >
                    {loadingCodingQuestion ?
                        t('Loading..',{ns: 'home'}) :
                        codingQuestionListResponse?.length > 0 ?
                            codingQuestionListResponse?.map((el, i) => {
                                return (
                                    <div className='question-list'>
                                        <div >
                                            <input type='checkbox' style={{ width: 16, height: 16 }} value={el.id} checked={el.isSelected} onChange={(e) => selectQuestion(e, el)} />
                                        </div>&nbsp;&nbsp;&nbsp;
                                        <div className='elipsis-2' dangerouslySetInnerHTML={{ __html: el.name }} />


                                        <div >
                                            {el.difficultyLevelName}
                                        </div>
                                        <div >
                                            {el.duration} Mins
                                        </div>
                                        <div>
                                            {t('Score')} : {el.score}
                                        </div>
                                        {/* <div className='d-flex'>
                                            <img src='/images/view-icon.svg' alt='' />&nbsp;
                                            <p style={{ color: '#009199', fontWeight: 700 }}>VIEW/EDIT</p>

                                        </div> */}

                                    </div>
                                )
                            }) :
                            <div className='empty-list'>
                                <img src='/images/magnifier.svg' alt='' />
                                <div className='mt-2'>
                                    <h3>{t('No question found')}</h3>
                                </div>
                            </div>
                    }
                </InfiniteScroll>
            </div>
        </div>
    )
}
