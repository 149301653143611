import { returnErrors } from './errorAction';
import { LOGIN_USER, SIGNUP_USER, VERIFY_EMAIL, FORGOT_PASSWORD, RESET_PASSWORD } from '../Types/types';
import { getApiCall, postApiCall } from '../../utils/request';

export const userLogin = (login) => async (dispatch) => {
    try {
        const response = await postApiCall(`user/login`, login);


        if (response?.status === 200) {
            return dispatch({ type: LOGIN_USER, payload: response.data });
        }
        // window.location.href = response.data.data?.role.includes('admin') ? '/manage-content' : '/my-learning'
        else {
            returnErrors(dispatch, response?.data?.responseMessages[0]?.message, response?.status)
        }
    }
    catch (error) {
        returnErrors(dispatch, error.response?.data?.responseMessages[0]?.message, error?.response?.status);
    }
}

export const userSignup = (signup) => async (dispatch) => {
    try {
        const response = await postApiCall(`user/signup`, signup);

        if (response?.status === 200) {
            returnErrors(dispatch, 'Signup successfully', response?.status)
            localStorage.setItem('userId', response.data?.userSignUp.id)
            let obj = { ...signup, id: response.data?.userSignUp.id }
            await postApiCall(`user/signup`, obj);
            await dispatch({ type: SIGNUP_USER });
            let login = {
                emailId: signup?.emailId,
                password: btoa(signup?.password.toString())
            }
            await dispatch(userLogin(login));
            return dispatch({ type: SIGNUP_USER });
        }
        else {
            returnErrors(dispatch, response?.data?.responseMessages[0]?.message, response?.status)
        }
    }
    catch (error) {
        returnErrors(dispatch, error.response?.data?.responseMessages[0]?.message, error?.response?.status);
    }
}

export const userSignupwithCoupon = (signup) => async (dispatch) => {
    try {
        const response = await postApiCall(`user/coupon-signup`, signup);

        if (response?.status === 200) {
            returnErrors(dispatch, 'Signup successfully', response?.status)
            await dispatch({ type: SIGNUP_USER });
            let login = {
                emailId: signup?.emailId,
                password: btoa(signup?.password.toString())
            }
            await dispatch(userLogin(login));
            return dispatch({ type: SIGNUP_USER });
        }
        // window.location.href = response.data.data?.role.includes('admin') ? '/manage-content' : '/my-learning'
        else {
            returnErrors(dispatch, response?.data?.responseMessages[0]?.message, response?.status)
        }
    }
    catch (error) {
        returnErrors(dispatch, error.response?.data?.responseMessages[0]?.message, error?.response?.status);
    }
}

export const resentEmail = (obj) => async (dispatch) => {
    try {
        const response = await postApiCall(`user/re-send-verification`, obj);
        if (response?.status === 200) {
            returnErrors(dispatch, 'Email resend successfully', response?.status)
            // dispatch({ type: VERIFY_EMAIL });
        }
        else {
            returnErrors(dispatch, response?.data?.responseMessages[0]?.message, response?.status)
        }
    }
    catch (error) {
        returnErrors(dispatch, error.response?.data?.responseMessages[0]?.message, error?.response?.status);
    }
}

export const verifyEmail = (id) => async (dispatch) => {
    try {
        const response = await getApiCall(`user/verify-email?Id=${id}`);
        if (response?.status === 200) {
            returnErrors(dispatch, 'Email verified successfully', response?.status)
            localStorage.setItem('emailVerified', true);
            return dispatch({ type: VERIFY_EMAIL });
        }
        else {
            returnErrors(dispatch, "Please check link", response?.status)
        }
    }
    catch (error) {
        returnErrors(dispatch, "Please check link", error?.response?.status);
    }
}

export const forgotPassword = (email) => async (dispatch) => {
    try {
        const response = await postApiCall(`user/forget-password`, { emailId: email });
        if (response.status === 200) {
            dispatch({ type: FORGOT_PASSWORD, payload: response.data });
            returnErrors(dispatch, 'OTP is sent on your registered Email', response.status)
        }
        else {
            returnErrors(dispatch, response.data.message, response.status)
        }

    } catch (error) {
        returnErrors(dispatch, error.response?.data?.responseMessages[0]?.message, error?.response?.status);
    }
}

export const resetPasswords = (obj) => async (dispatch) => {
    try {
        const response = await postApiCall(`user/reset-password`, obj);
        if (response.status === 200) {
            returnErrors(dispatch, 'Password has been successfully changed', response.status)
            return dispatch({ type: RESET_PASSWORD, payload: response.data });
        }
        else {
            returnErrors(dispatch, response.data.message, response.status)
        }

    } catch (error) {
        returnErrors(dispatch, error.response?.data?.responseMessages[0]?.message, error?.response?.status);
    }
}

