import { GET_TESTS_DETAILS, VALIDATE_TOKEN, VALIDATE_CODE, INTITATE_TEST, TEST_DETAILS, GET_QUESTION, GET_STARTER_CODE } from "../Types/types";

let intialState = {
    candidateDetails: null,
    image: ''
}


export default function candidateTestReducer(state = intialState, action) {
    let stateCopy = JSON.parse(JSON.stringify(state));
    switch (action.type) {
        case GET_TESTS_DETAILS:
            stateCopy.testDetails = action.payload;
            return stateCopy;
        case VALIDATE_TOKEN:
            stateCopy.candidateDetails = action.payload.invitation;
            stateCopy.testDetails = action.payload.test;
            stateCopy.token = action.payload.token;
            localStorage.setItem('token', action.payload.user.token);
            localStorage.setItem('email', action.payload.user.emailId);
            localStorage.setItem('name', action.payload.user.name);
            localStorage.setItem('profileDetails', JSON.stringify(action.payload.user));
            localStorage.setItem('role', action.payload.user.roleId);
            localStorage.setItem('logo', action.payload.user.logoImage);
            // localStorage.setItem('token', action.payload.token);
            localStorage.getItem('token') === null && window.location.reload();
            return stateCopy;
        case VALIDATE_CODE:
            return stateCopy;
        case INTITATE_TEST:
            stateCopy.candidateTestDetailId = action.payload.candidateTestDetailId;
            return stateCopy;
        case TEST_DETAILS:
            stateCopy.testSummary = action.payload;
            return stateCopy;
        case GET_QUESTION:
            stateCopy.question = action.payload.question;
            stateCopy.isTestCompleted = action.payload.isTestCompleted;
            stateCopy.isNextTest = action.payload.isNextTest;
            return stateCopy;
        case GET_STARTER_CODE:
            stateCopy.question = { ...stateCopy.question, defaultCode: action.payload };
            return stateCopy;
        default:
            return stateCopy;
    }
}
