import React, { useRef, useState, useMemo, useEffect } from 'react';
import JoditEditor from 'jodit-react';
import { useSelector, useDispatch } from "react-redux";
import { changeTemplate, resetQuestion, setTitle } from '../Redux/ActionCreators/testLibraryAction';

export const Editor = (props) => {
    const title = useSelector((state) => state.testLibrary.qTitle);
    const dispatch = useDispatch()
    const editor = useRef(null)
    const [content, setContent] = useState('')
    const AuthStr = "Bearer " + localStorage.getItem("loginToken");
    const config = {
        toolbarInlineForSelection: true,
        toolbar: true,
        placeholder: '',
        readonly: false,// all options from https://xdsoft.net/jodit/doc/
        statusbar: false,
        showCharsCounter: props.type === 'title',
        showWordsCounter: props.type === 'title',
        uploader: {
            url: `${process.env.REACT_APP_API_KEY}admin/my-content/upload/image`,
            headers: { Authorization: AuthStr },
            filesVariableName: function (e) {
                return "image";
            },
            insertImageAsBase64URI: false,
            prepareData: function (data) {
                // data.append("image", );
                return data;
            },
            isSuccess: function (resp) {
                return resp;
            },
            process: function (resp) {
                return {
                    files: resp.image,
                    path: resp.image,
                    baseurl: resp.image,
                    error: resp.error,
                    message: resp.message,
                };
            },

            defaultHandlerSuccess: function (data) {
                if (this.componentName === 'jodit-uploader') {
                    this.jodit.selection.insertImage(data.baseurl, null, 200);
                }
                else {
                    this.selection.insertImage(data.baseurl, null, 200);
                }
            }
        },
        height: 100,
        width: '100%',
        autoFocus: true,
        removeButtons: ['eraser', 'fullsize'],
        askBeforePasteFromWord: false,
        askBeforePasteHTML: false,
        defaultActionOnPaste: 'insert_clear_html',
        defaultActionOnPasteFromWord: 'insert_as_html',
        enableDragAndDropFileToEditor: true,
        usePopupForSpecialCharacters: true,
        // cleanHTML: { removeEmptyElements: false, },
        sizeLG: 900,
        sizeMD: 700,
        sizeSM: 400,
        buttons: [
            'source', '|',
            'insertFormula',
            'bold',
            'italic',
            'underline', '|',
            'ul',
            'ol', '|',
            'font',
            'fontsize',
            'brush',
            'paragraph', '|',
            'image',
            'table',
            'link', '|',
            'left',
            'center',
            'right',
            'justify', '|',
            'undo', 'redo', '|',
        ],
        buttonsMD: [
            'source', '|',
            'bold',
            'italic',
            'underline', '|',
            'ul',
            'ol', '|',
            'font',
            'fontsize',
            'brush',
            'paragraph', '|',
            'image',
            'table',
            'link', '|',
            'left',
            'center',
            'right',
            'justify', '|',
            'undo', 'redo', '|',
        ],
        buttonsSM: [
            'bold',
            'italic',
            'underline', '|',
            'ul',
            'ol', '|',
            'fontsize',
            'brush',
            'superscript',
            'subscript',
            'paragraph', '|',
            'image',
            'table',
            'link', '|',
            'left',
            'center',
            'right', '|',
            'undo', 'redo', '|',
        ],
        buttonsXS: [
            'bold',
            'italic',
            'underline', '|',
            'brush',
            'paragraph', '|',
            'image',
            'left',
            'center',
            'right', '|',
            'undo', 'redo', '|',
            'eraser'
        ],
    }

    useEffect(() => {
        dispatch(resetQuestion());
        dispatch(setTitle(title));
    }, [])

    return useMemo(() => (
        <JoditEditor ref={editor} value={title} config={config} onChange={content => {
            setContent(content)
            dispatch(setTitle(content))
        }} />

    ), [])

}

export const TemplateEditor = (props) => {
    const { instruction } = useSelector((state) => state.testLibrary)
    const dispatch = useDispatch()
    const editor = useRef(null)
    const [content, setContent] = useState('');
    const config = {
        toolbarInlineForSelection: true,
        toolbar: true,
        placeholder: '',
        readonly: false,// all options from https://xdsoft.net/jodit/doc/
        statusbar: false,
        showCharsCounter: false,
        showWordsCounter: false,
        minHeight: 300,
        width: '100%',
        autoFocus: true,
        removeButtons: ['eraser', 'fullsize'],
        toolbarSticky: false,
        toolbarDisableStickyForMobile: false,
        allowResizeX: false,
        allowResizeY: false,
        askBeforePasteFromWord: false,
        askBeforePasteHTML: false,
        defaultActionOnPaste: 'insert_clear_html',
        defaultActionOnPasteFromWord: 'insert_as_html',
        enableDragAndDropFileToEditor: true,
        usePopupForSpecialCharacters: true,
        // cleanHTML: { removeEmptyElements: false, },
        sizeLG: 900,
        sizeMD: 700,
        sizeSM: 400,
        buttons: [
            'source', '|',
            'insertFormula',
            'bold',
            'italic',
            'underline', '|',
            'ul',
            'ol', '|',
            'font',
            'fontsize',
            'brush',
            'paragraph', '|',
            'image',
            'table',
            'link', '|',
            'left',
            'center',
            'right',
            'justify', '|',
            'undo', 'redo', '|',
        ],
        buttonsMD: [
            'source', '|',
            'bold',
            'italic',
            'underline', '|',
            'ul',
            'ol', '|',
            'font',
            'fontsize',
            'brush',
            'paragraph', '|',
            'image',
            'table',
            'link', '|',
            'left',
            'center',
            'right',
            'justify', '|',
            'undo', 'redo', '|',
        ],
        buttonsSM: [
            'bold',
            'italic',
            'underline', '|',
            'ul',
            'ol', '|',
            'fontsize',
            'brush',
            'superscript',
            'subscript',
            'paragraph', '|',
            'image',
            'table',
            'link', '|',
            'left',
            'center',
            'right', '|',
            'undo', 'redo', '|',
        ],
        buttonsXS: [
            'bold',
            'italic',
            'underline', '|',
            'brush',
            'paragraph', '|',
            'image',
            'left',
            'center',
            'right', '|',
            'undo', 'redo', '|',
            'eraser'
        ],
    }

    useEffect(() => {
        // dispatch(resetQuestion())
        setContent(content)
    }, [])

    return useMemo(() => (
        <JoditEditor ref={editor} value={instruction} config={config} onChange={content => {
            setContent(content)
            dispatch(changeTemplate(content))
        }} />

    ), [])

}