// test/list-my-test
import { returnErrors } from './errorAction';
import { RESET_GAPPEO_TEST, GET_CATEGORIES, GET_GAPPEO_TESTS, EMPTY_GAPPEO_TEST, GET_NEXT_GAPPEO_TESTS, RESET_SAMPLE_QUESTIONS, GET_SAMPLE_QUESTIONS, FILTERED_QUESTIONS } from '../Types/types';
import { getApiCall } from '../../utils/request';

export const resetGappeoTest = () => (dispatch) => {
    return dispatch({ type: RESET_GAPPEO_TEST })
}

export const getCategory = () => async (dispatch) => {
    try {
        const response = await getApiCall(`question/list-category`);
        if (response?.status === 200) {
            dispatch({ type: GET_CATEGORIES, payload: response.data });
        }
        else {
            returnErrors(dispatch, response?.data?.responseMessages[0]?.message, response?.status)
        }
    }
    catch (error) {
        returnErrors(dispatch, error.response?.data?.responseMessages[0]?.message, error?.response?.status);
    }
}


export const getGappeoTests = (level, type, category, query, page, row) => async (dispatch) => {
    try {
        const response = await getApiCall(`test/list-gappeo-test?TestLevel=${level}&TestType=${type}&CategoryId=${category}&SearchText=${query}&PageNumber=${page}&DisplayRows=${row}`);
        if (response?.status === 200) {
            dispatch({ type: GET_GAPPEO_TESTS, payload: response.data });
        } else if (response?.status === 204) {
            dispatch({ type: EMPTY_GAPPEO_TEST, payload: [] });
        }
        else {
            dispatch({ type: EMPTY_GAPPEO_TEST, payload: [] });
            returnErrors(dispatch, response?.data?.responseMessages[0]?.message, response?.status)
        }
    }
    catch (error) {
        returnErrors(dispatch, error.response?.data?.responseMessages[0]?.message, error?.response?.status);
    }
}

export const getNextGappeoTests = (level, type, category, query, page, row) => async (dispatch) => {
    try {
        const response = await getApiCall(`test/list-gappeo-test?TestLevel=${level}&TestType=${type}&CategoryId=${category}&SearchText=${query}&PageNumber=${page}&DisplayRows=${row}`);
        if (response?.status === 200) {
            dispatch({ type: GET_NEXT_GAPPEO_TESTS, payload: response.data });
        }
        else {
            returnErrors(dispatch, response?.data?.responseMessages[0]?.message, response?.status)
        }
    }
    catch (error) {
        returnErrors(dispatch, error.response?.data?.responseMessages[0]?.message, error?.response?.status);
    }
}

export const resetQuestions = () => (dispatch) => {
    return dispatch({ type: RESET_SAMPLE_QUESTIONS })
}

export const getSampleQuestions = (testId, level, page, row) => async (dispatch) => {
    try {
        const response = await getApiCall(`question/list-test-question?TestId=${testId}&TestLevel=${level}&PageNumber=${page}&DisplayRows=${row}`);
        if (response?.status === 200) {
            return dispatch({ type: GET_SAMPLE_QUESTIONS, payload: response.data.questionList });
        } else if (response?.status === 204) {
            return dispatch({ type: GET_SAMPLE_QUESTIONS, payload: [] });
        }
        else {
            dispatch({ type: EMPTY_GAPPEO_TEST, payload: [] });
            returnErrors(dispatch, response?.data?.responseMessages[0]?.message, response?.status)
        }
    }
    catch (error) {
        returnErrors(dispatch, error.response?.data?.responseMessages[0]?.message, error?.response?.status);
    }
}

export const getFilterQuestion = (id) => (dispatch) => {
    return dispatch({ type: FILTERED_QUESTIONS, payload: id })
}

