import React, { Fragment, useEffect, useState } from 'react';
import './users.scss';
import { Table } from 'react-bootstrap';
import { convertDate } from '../../utils/miscelleneous';
import Switch from 'react-switch';
import { useDispatch } from 'react-redux';
import { getUsers, getNextUsers, getUserSubscriptionPlan, updateSubscriptionPlan, resetUserList, addUser, changeStatus, getTenants, getNextTenants, getTenantsProfile, updateTenantStatus } from '../../Redux/ActionCreators/userAction';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useSelector } from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import { BallBeat } from '../../Loader/loader';
import moment from 'moment';
import { useTranslation } from 'react-i18next';


export default function Users() {
    const [active, setActive] = useState("users");
    const [activeTab, setActiveTab] = useState("user");
    const [query, setQuery] = useState("");
    const [page, setPage] = useState(1);
    const [tenantPage, setTenantPage] = useState(1);
    const [isDetail, setIsDetail] = useState(false);
    const [detail, setDetail] = useState(null);
    const [isAddUser, setIsAddUser] = useState(false);
    const [name, setName] = useState("");
    const [isLoading, setisLoading] = useState(false);
    const { t } = useTranslation();
    const [nameError, setNameError] = useState("");
    const [email, setEmail] = useState("");
    const [emailError, setEmailError] = useState("");
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [modelMessage, setModalMessage] = useState('User Plan Has Been Updated')

    const [loading, setLoading] = useState(false);

    const rows = 25;
    const dispatch = useDispatch();

    const { isUserLoading, usersList, total, tenantUsers, subscriptionPlanList, totalTenant, tenantIndividualUser } = useSelector((state) => state.users);
    const [selectedPlanId, setSelectedPlanId] = useState("");
    const [planTestCredits, setPlanTestCredits] = useState(null);
    const details = JSON.parse(localStorage.getItem("profileDetails"));

    useEffect(() => {
        dispatch(getUsers(query, 1, rows));
    }, [active]);

    useEffect(() => {
        dispatch(getUserSubscriptionPlan());
    }, []);

    useEffect(() => {
        setQuery("");
        setPage(1);
        setTenantPage(1);
        dispatch(resetUserList());
        if (activeTab === "user") {
            dispatch(getUsers('', 1, rows));
        } else {
            dispatch(getTenants('', 1, rows));
        }
    }, [activeTab]);

    const changeTab = (e) => {
        setActive(e);
    };

    const onTabChange = (e) => {
        setActiveTab(e);
    };

    const fetchData = () => {
        setPage(page + 1);
        dispatch(getNextUsers(query, page + 1, rows));
    };

    const fetchTenants = () => {
        setTenantPage(tenantPage + 1);
        dispatch(getNextTenants(query, tenantPage + 1, rows));
    };

    const timerRef1 = React.useRef(null);

    const searchUser = (val) => {
        clearTimeout(timerRef1.current);
        setQuery(val);
        setPage(1);
        timerRef1.current = setTimeout(() => delaySearch(val), 800);
    };

    const searchTennant = (val) => {
        clearTimeout(timerRef1.current);
        setQuery(val);
        setPage(1);
        timerRef1.current = setTimeout(() => delaySearchTennant(val), 800);
    };

    const delaySearch = async (val) => {
        await dispatch(resetUserList());
        await dispatch(getUsers(val, page, rows));
    };

    const delaySearchTennant = async (val) => {
        await dispatch(getTenants(val, page, rows));
    };

    const handleChange = (el, active) => {
        let obj = {
            userId: el?.userID,
            statusId: active === 0 ? 1 : 0,
        };
        console.log("🚀 ~ handleChange ~ obj: ", obj, active)

        dispatch(changeStatus(obj));
    };

    const handleStatusChangeTenant = async (el, active) => {
        let obj = {
            userId: el?.userID,
            statusId: active === 0 ? 1 : 0,
        };
        console.log("🚀 ~ handleStatusChangeTenant  ~ obj: ", obj, active)

        setisLoading(true)
        setLoading(true)

        try {
            const response = await dispatch(updateTenantStatus(obj));
            if (response) {
                setModalMessage(`${el?.name}`(t('Status Has Been Updated')));
                setShowSuccessModal(true);

                dispatch(getTenants(query, 1, rows));
                setisLoading(false)
                setLoading(false)
                console.log("\n Success ", response);
            }
            else {
                console.log("\n Failed ", response);
                setisLoading(false)
                setLoading(false)
            }
        } catch (error) {
            console.error((t('Error updating subscription:')), error);
            setisLoading(false)
            setLoading(false)
        }

    };

    const changeName = (val) => {
        setName(val);
        setNameError("");
    };

    const changeEmail = (val) => {
        setEmail(val);
        setEmailError("");
    };

    const saveUser = async () => {
        if (name === "") {
            setNameError(t(`Name can't empty`));
        } else if (email.trim('') === "") {
            setEmailError(t(`Email can't empty`));
        } else {
            setisLoading(true);
            const obj = {
                name: name.trim(''),
                emailId: email.trim(''),
            };
            console.log(obj)
            const res = await dispatch(addUser(obj));
            if (res?.type === "ADD_USERS") {
                setIsAddUser(false);
                setName('');
                setEmail('');
                dispatch(getUsers("", 1, rows));
            }
            setisLoading(false);
        }
    };

    const handleSuccessModal = () => {
        setShowSuccessModal(false);

    }

    const handleModal = () => {
        setName("");
        setNameError("");
        setEmail("");
        setEmailError("");
        setIsAddUser(false);
        setIsDetail(false);
        setSelectedPlanId("");
        setPlanTestCredits(null);
    };

    const openDetail = (el) => {
        setIsDetail(true);
        setDetail(el);

    };

    const copyLink = () => {
        var copyText = document.getElementById("pass")?.value;

        // Copy the text inside the text field
        navigator.clipboard.writeText(copyText);
        // setSuccessMessage(true);
        // setsuccessMessageText('Link Copied');
    };

    // !handle plan
    const handlePlanChange = (e) => {
        const selectedId = parseInt(e.target.value);
        setSelectedPlanId(selectedId);

        // Find the selected plan in the plans array
        const selectedPlan = subscriptionPlanList?.find(
            (plan) => plan.planId === selectedId
        );
        console.log("🚀 ~  selectedPlan: ", selectedPlan);

        // Extract testCredits from accessMatrix of the selected plan
        const testCredits = selectedPlan?.accessMatrix?.testCredits || "";
        setPlanTestCredits(testCredits);
    };

    const handleTestCreditsChange = (e) => {
        const newValue = parseInt(e.target.value);
        setPlanTestCredits(newValue);
    };

    const updateSubscriptionChange = async () => {

        const jsonObj = {
            tenantId: tenantIndividualUser?.tenantId,
            planId: selectedPlanId,
            testCredit: planTestCredits ?? tenantIndividualUser?.planTestCredit,
        };
        console.log("🚀 ~ tenantIndividualUser: ", tenantIndividualUser);
        setisLoading(true)
        setLoading(true)
        try {
            const response = await dispatch(updateSubscriptionPlan(jsonObj));
            if (response) {
                setModalMessage(`${tenantIndividualUser?.name}`(t('Plan Has Been Updated')));
                setIsAddUser(false);
                setIsDetail(false);
                setShowSuccessModal(true);
                dispatch(getTenants(query, 1, rows));
                setisLoading(false)
                setLoading(false)
                setSelectedPlanId("")
                setPlanTestCredits(null);
                console.log("\n Success ", response);
            }
            else {
                console.log("\n Failed ", response);
                setisLoading(false)
                setLoading(false)
                setSelectedPlanId("")
            }
        } catch (error) {
            console.error((t('Error updating subscription:')), error);
            setisLoading(false)
            setLoading(false)
            setSelectedPlanId("")
        }

    };

    const getTenantDetails = async (tenantDetail) => {
        setLoading(true)

        const tenantId = tenantDetail?.tenantId;
        const userID = tenantDetail?.userID;

        try {
            const response = await dispatch(getTenantsProfile(tenantId, userID));
            if (response) {
                setLoading(false);
                openDetail(tenantDetail)
            }
            else {
                console.log("\n Failed ", response);
                setLoading(false)
            }
        } catch (error) {
            console.error((t('Error updating subscription:')), error);
            setLoading(false)
        }


    }

    const handleAddUsers = () => {
        if (details?.accessMatrix?.canAddUser) {
            setIsAddUser(true);
        }
        else {
            setModalMessage(t('Please upgrade your plan to add users'));
            setShowSuccessModal(true)
        }
    }


    return (
        <div className="users-holder">
            {details?.tenentId === "-1" && details?.roleId === "1" ? (
                <Fragment>
                    <div className="users-header">

                        <h2>{t('Users', { ns: 'profile' })}</h2>

                        {activeTab === "user" ?
                            <div className="d-flex align-items-center flex-wrap">
                                <div className="has-search">
                                    <span className="form-control-feedback">
                                        <img src="/images/search.svg" alt="" width={12} />
                                    </span>
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={query}
                                        placeholder={t('Search for a user', { ns: "profile" })}
                                        onChange={(e) => searchUser(e.target.value)}
                                    />
                                </div>
                                &nbsp;&nbsp;
                                <button className="add-btn" onClick={handleAddUsers}>
                                    {t('ADD USERS', { ns: 'profile' })}
                                </button>
                            </div>
                            :
                            <div className="d-flex align-items-center flex-wrap">
                                <div className="has-search">
                                    <span className="form-control-feedback">
                                        <img src="/images/search.svg" alt="" width={12} />
                                    </span>
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={query}
                                        placeholder={t('Search for a tenant', { ns: 'profile' })}
                                        onChange={(e) => searchTennant(e.target.value)}
                                    />
                                </div>


                            </div>}


                        <div className="user-btns mt-4 d-flex align-items-center">
                            <button
                                className={activeTab === "user" ? "active" : ""}
                                onClick={() => onTabChange("user")}
                            >
                                {t('Users', { ns: 'profile' })}
                            </button>
                            &nbsp;&nbsp;
                            <button
                                className={activeTab === "tenant" ? "active" : ""}
                                onClick={() => onTabChange("tenant")}
                            >
                                {t('Tenants', { ns: 'profile' })}
                            </button>
                            &nbsp;
                        </div>
                    </div>
                    {/* Tab Switch For Admins */}
                    {activeTab === "user" ? (
                        <div className="table-admin">
                            <InfiniteScroll
                                dataLength={usersList && usersList?.length} //This is important field to render the next data
                                next={total > usersList?.length ? fetchData : ""}
                                hasMore={true}
                            >
                                <Table responsive>
                                    <thead>
                                        <th>{t('Name', { ns: 'profile' })}</th>
                                        <th>{t('Email', { ns: 'profile' })}</th>
                                        <th>{t('Role', { ns: 'profile' })}</th>
                                        <th>{t('Joined', { ns: 'profile' })}</th>
                                        <th>{t('Status', { ns: 'profile' })}</th>
                                        {/* <th>Action</th> */}
                                    </thead>
                                    <tbody className="table-body">
                                        {isUserLoading
                                            ? "Loading"
                                            : usersList &&
                                            usersList?.map((el) => {
                                                return (
                                                    <tr>
                                                        <td>{el.name}</td>
                                                        <td>{el.emailId}</td>
                                                        <td>{el.roleName}</td>
                                                        <td>{convertDate(new Date())}</td>
                                                        <td>
                                                            <p
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    e.stopPropagation();
                                                                    handleChange(el, el.isActive);
                                                                }}
                                                            >
                                                                {" "}
                                                                <Switch
                                                                    onChange={(e) => {
                                                                        console.log(e);
                                                                    }}
                                                                    width={30}
                                                                    height={16}
                                                                    uncheckedIcon={false}
                                                                    checkedIcon={false}
                                                                    onColor="#02c573"
                                                                    checked={el.isActive === 1 ? true : false}
                                                                />
                                                                &nbsp;&nbsp;
                                                                <br />
                                                                {el.isActive === 1 ? "Active" : "Inactive"}
                                                            </p>
                                                        </td>
                                                        {/* <td>
                                                   <img src='/images/info.svg' alt='' />&nbsp;&nbsp;
                                                   <img src='/images/add-icon.svg' width={20} height={20} alt='' />
                                               </td> */}
                                                    </tr>
                                                );
                                            })}
                                    </tbody>
                                </Table>
                            </InfiniteScroll>
                        </div>
                    ) : (
                        <div className="table-admin">
                            <InfiniteScroll
                                dataLength={tenantUsers && tenantUsers?.length} //This is important field to render the next data
                                next={totalTenant > tenantUsers?.length ? fetchTenants : ""}
                                hasMore={true}
                            >
                                <Table responsive>
                                    <thead>
                                        <th>{t('Name', { ns: 'profile' })}</th>
                                        <th>{t('Email', { ns: 'profile' })}</th>
                                        <th>{t('Joined', { ns: 'profile' })}</th>
                                        <th>{t('Plan', { ns: 'profile' })}</th>
                                        <th>{t('Status', { ns: 'profile' })}</th>
                                        <th>{t('Action', { ns: 'profile' })}</th>
                                    </thead>
                                    <tbody className="table-body">
                                        {isUserLoading
                                            ? "Loading"
                                            : tenantUsers &&
                                            tenantUsers?.map((el) => {
                                                return (
                                                    <tr>
                                                        <td>{el.name}</td>
                                                        <td>{el.emailId}</td>

                                                        <td>{moment(el?.joinedDate).format('DD MMMM YYYY')}</td>
                                                        <td>{el.planName}</td>
                                                        <td>
                                                            <p
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    e.stopPropagation();
                                                                    handleStatusChangeTenant(el, el.isActive)
                                                                }}
                                                            >
                                                                {" "}
                                                                <Switch
                                                                    onChange={(e) => {
                                                                        console.log(e);
                                                                    }}
                                                                    width={30}
                                                                    height={16}
                                                                    uncheckedIcon={false}
                                                                    checkedIcon={false}
                                                                    onColor="#02c573"
                                                                    checked={el.isActive === 1 ? true : false}
                                                                />
                                                                &nbsp;&nbsp;
                                                                <br />
                                                                {el.isActive === 1 ? "Active" : "Inactive"}
                                                            </p>
                                                        </td>
                                                        <td className="text-center">
                                                            <img
                                                                src="/images/info.svg"
                                                                alt=""
                                                                onClick={() => getTenantDetails(el)}
                                                                className="pointer"
                                                            />
                                                            &nbsp;&nbsp;
                                                            {/* <img src='/images/add-icon.svg' width={20} height={20} alt='' /> */}
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                    </tbody>
                                </Table>
                            </InfiniteScroll>
                        </div>
                    )}
                </Fragment>
            ) : (
                <Fragment>
                    <div className="users-header">
                        <h2>{t('Users', { ns: 'profile' })}</h2>
                        {/* <div className='d-flex justify-content-center mt-2'>
                       <button className={active === 'users' ? 'active' : ''} onClick={() => changeTab('users')}>USERS</button>
                       <button className={active === 'lists' ? 'active' : ''} onClick={() => changeTab('lists')}>LISTS</button>
                   </div> */}
                        <div className="d-flex align-items-center flex-wrap">
                            <div className="has-search">
                                <span className="form-control-feedback">
                                    <img src="/images/search.svg" alt="" width={12} />
                                </span>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={query}
                                    placeholder={t('Search for a user', { ns: 'profile' })}
                                    onChange={(e) => searchUser(e.target.value)}
                                />
                            </div>
                            &nbsp;&nbsp;
                            <button className="add-btn" onClick={handleAddUsers}>
                                {t('ADD USERS', { ns: 'profile' })}
                            </button>
                        </div>
                    </div>
                    <div className="table-admin">
                        <InfiniteScroll
                            dataLength={usersList && usersList?.length} //This is important field to render the next data
                            next={total > usersList?.length ? fetchData : ""}
                            hasMore={true}
                        >
                            <Table responsive>
                                <thead>
                                    <th>{t('Name', { ns: 'profile' })}</th>
                                    <th>{t('Email', { ns: 'profile' })}</th>
                                    <th>{t('Role', { ns: 'profile' })}</th>
                                    <th>{t('Joined', { ns: 'profile' })}</th>
                                    <th>{t('Status', { ns: 'profile' })}</th>
                                    {/* <th>Action</th> */}
                                </thead>
                                <tbody className="table-body">
                                    {isUserLoading
                                        ? "Loading"
                                        : usersList &&
                                        usersList?.map((el) => {
                                            return (
                                                <tr>
                                                    <td>{el.name}</td>
                                                    <td>{el.emailId}</td>
                                                    <td>{el.roleName}</td>
                                                    <td>{convertDate(new Date())}</td>
                                                    <td>
                                                        <p
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                e.stopPropagation();
                                                                handleChange(el, el.isActive);
                                                            }}
                                                        >
                                                            {" "}
                                                            <Switch
                                                                onChange={(e) => {
                                                                    console.log(e);
                                                                }}
                                                                width={30}
                                                                height={16}
                                                                uncheckedIcon={false}
                                                                checkedIcon={false}
                                                                onColor="#02c573"
                                                                checked={el.isActive === 1 ? true : false}
                                                            />
                                                            &nbsp;&nbsp;
                                                            <br />
                                                            {el.isActive === 1 ? "Active" : "Inactive"}
                                                        </p>
                                                    </td>
                                                    {/* <td>
                                                   <img src='/images/info.svg' alt='' />&nbsp;&nbsp;
                                                   <img src='/images/add-icon.svg' width={20} height={20} alt='' />
                                               </td> */}
                                                </tr>
                                            );
                                        })}
                                </tbody>
                            </Table>
                        </InfiniteScroll>
                    </div>
                </Fragment>
            )}

            {/* ############## add and edit user modal ############### */}
            <Modal
                show={isAddUser}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                dialogClassName={"add-user-modal"}
            >
                <div class="modal-header" style={{ background: "#009199" }}>
                    <h5 class="modal-title">{t('Add User')} </h5>

                    <div className="sticky-close" onClick={handleModal}>
                        <img src="/images/close-white.svg" width="15px" alt="" />
                    </div>
                </div>
                <Modal.Body style={{ padding: "22px 40px 22px 40px" }}>
                    <div>
                        <label>{t('Name', { ns: 'profile' })}*</label>
                        <input
                            className="form-control mt-2"
                            value={name}
                            onChange={(e) => changeName(e.target.value)}
                        />
                    </div>
                    <small>{nameError}</small>
                    <div className="mt-2">
                        <label>{t('Email', { ns: 'profile' })}*</label>
                        <input
                            className="form-control mt-2"
                            value={email}
                            onChange={(e) => changeEmail(e.target.value)}
                        />
                    </div>
                    <small>{emailError}</small>

                    <div className="save-btn">
                        <button disabled={isLoading} onClick={saveUser}>
                            {isLoading ? (
                                <div className="ballbeat">
                                    <BallBeat color="#fff" />
                                </div>
                            ) : (
                                t('Save')
                            )}
                        </button>
                        <button onClick={() => handleModal()}>{t('Cancel', { ns: 'profile' })}</button>
                    </div>
                </Modal.Body>
            </Modal>

            {/* ############## add and edit tenant modal ############### */}
            <Modal
                show={isDetail}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                dialogClassName={"detail-modal"}
            >
                <div class="modal-header" style={{ background: "#009199" }}>
                    <h5 class="modal-title">{"Tenant Details"} </h5>

                    <div className="sticky-close" onClick={handleModal}>
                        <img src="/images/close-white.svg" width="15px" alt="" />
                    </div>
                </div>
                <Modal.Body style={{ padding: "22px" }}>
                    <div className="row">
                        <div className="col-md-6 col-12">
                            <div>
                                <label>{t('Name', { ns: 'profile' })}*</label>
                                <input
                                    className="form-control mt-2"
                                    value={tenantIndividualUser?.name}
                                    disabled
                                />
                            </div>
                        </div>
                        <div className="col-md-6 col-12">
                            <label>{t('Email', { ns: 'profile' })}*</label>
                            <input
                                className="form-control mt-2"
                                value={tenantIndividualUser?.emailId}
                                disabled
                            />
                        </div>
                    </div>
                    <div className="row mt-2">
                        <div className="col-md-6 col-12">
                            <div className="position-relative">
                                <label>{t('Password', { ns: 'profile' })}*</label>
                                <input
                                    type="password"
                                    className="form-control mt-2"
                                    id="pass"
                                    value={tenantIndividualUser?.password}
                                    disabled
                                />
                                <div
                                    style={{
                                        position: "absolute",
                                        cursor: "pointer",
                                        top: 38,
                                        right: 0,
                                        zIndex: 1,
                                    }}
                                >
                                    <img
                                        src="/images/copy.png"
                                        alt=""
                                        onClick={() => copyLink()}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-12">
                            <label>{t('Current Plan', { ns: 'profile' })}*</label>
                            <input
                                className="form-control mt-2"
                                value={tenantIndividualUser?.planName}
                                disabled
                            />
                        </div>
                    </div>


                    {/* Upgrade Plan Logic */}
                    <div className="row mt-2">
                        <div className="col-md-6 col-12 select-wrapper">
                            <label>{t('Upgrade Plan', { ns: 'profile' })}*</label>
                            {/* <input className='form-control mt-2' value={detail?.planName} disabled /> */}
                            <select
                                className="form-control mt-2 custom-select"
                                value={selectedPlanId}
                                onChange={handlePlanChange}
                            >
                                <option value="" disabled selected>Select Plan</option>
                                {subscriptionPlanList?.map((plan) => (
                                    <option key={plan.planId} value={plan.planId}>
                                        {plan.planName}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="col-md-6 col-12">

                            {
                                (selectedPlanId === 10)
                                    ? <div className='position-relative'>
                                        <label>{t('Plan Test Credits', { ns: 'profile' })}*</label>
                                        <input type='text' className='form-control mt-2' id='pass' value={'Unlimited'} disabled />
                                    </div>
                                    : (tenantIndividualUser?.planId === 10 && !planTestCredits)
                                        ? <div className='position-relative'>
                                            <label>{t('Plan Test Credits', { ns: 'profile' })}*</label>
                                            <input type='text' className='form-control mt-2' id='pass' value={'Unlimited'} disabled />
                                        </div>
                                        : <div className="position-relative">
                                            <label>{t('Plan Test Credits', { ns: 'profile' })}*</label>
                                            <input
                                                type="number"
                                                className="form-control mt-2"
                                                id="pass"
                                                value={planTestCredits ?? tenantIndividualUser?.planTestCredit}
                                                onChange={handleTestCreditsChange}
                                            />
                                        </div>
                            }



                            {/* <div className='position-relative'>
                                <label>Plan Test Credits*
                                </label>
                                <input type='text' className='form-control mt-2' id='pass' value={detail?.planTestCredit} disabled />
                            </div> */}

                        </div>
                    </div>

                    <div className="row mt-2">
                        <div className="col-md-6 col-12">
                            <label>{t('Plan Balance credit', { ns: 'profile' })}*</label>
                            <input
                                className="form-control mt-2"
                                value={tenantIndividualUser?.balanceCredit}
                                disabled
                            />
                        </div>
                        <div className="col-md-6 col-12">
                            <label>{t('Test taken', { ns: 'profile' })}</label>
                            <input
                                className="form-control mt-2"
                                value={tenantIndividualUser?.testTaken}
                                disabled
                            />
                        </div>
                    </div>

                    <div className="row mt-2">
                        <div className="col-md-6 col-12">
                            <label>{t('Total Invites', { ns: 'profile' })}</label>
                            <input
                                className="form-control mt-2"
                                value={tenantIndividualUser?.totalInvites}
                                disabled
                            />
                        </div>
                        <div className="col-md-6 col-12">
                            <label>{t('Number Of Users', { ns: 'profile' })}</label>
                            <input
                                className="form-control mt-2"
                                value={tenantIndividualUser?.userCount}
                                disabled
                            />
                        </div>
                    </div>

                    {selectedPlanId ? <div className="text-center mt-4  ">
                        <button
                            disabled={isLoading}
                            className="custom-btns"
                            onClick={() => updateSubscriptionChange()}
                        >
                            {isLoading ? (
                                <div className="ballbeat">
                                    <BallBeat color="#fff" />
                                </div>
                            ) : (
                                "Save"
                            )}
                        </button>
                    </div> : null}


                </Modal.Body>
            </Modal>


            {/* ############## Success Modal ############### */}
            <Modal
                show={showSuccessModal}
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                dialogClassName={"detail-modal"}
            >
                <div class="modal-header" style={{ background: "#009199" }}>
                    <h5 class="modal-title">{t("Updated", { ns: 'profile' })} </h5>

                    <div className="sticky-close" onClick={handleSuccessModal}>
                        <img src="/images/close-white.svg" width="15px" alt="" />
                    </div>
                </div>
                <Modal.Body style={{ padding: "22px", textAlign: "center" }}>
                    <h1>😃</h1>
                    <h3>{modelMessage}</h3>
                </Modal.Body>
            </Modal>

            {loading && (
                <div className="gp-loader-container">
                    <div className="gp-loader"></div>
                </div>
            )}
        </div>
    );
}
