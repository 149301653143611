import React from 'react';
import { Modal } from 'react-bootstrap';
import { BallBeat } from '../Loader/loader';
import { useTranslation } from 'react-i18next';


export default function AddTestModal({ type, edit, isOpen, testName, testNameErr, description, setDescription, setIsOpen, setTestName, setTestNameErr, goToQuestion, loading }) {
    const { t } = useTranslation();
    return (
        <div>
            {/* Create custom test modal */}
            <Modal
                show={isOpen}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                dialogClassName={"add-test-content"}
            >
                <Modal.Body>
                    <div className='sticky-close'>
                        <img src='/images/close.svg' alt='' onClick={() => setIsOpen(false)} />
                    </div>
                    <div className='text-center'>
                        <img src='/images/tick-mark.svg' alt='' width={42} />
                        <h3 className='mt-2'>{edit ? 'Edit Assessment' : (type === 'my-assessment' ? t('Create New Assessment') : t('New Assessment Created'))}</h3>
                        {edit ? '' : <p>{t('A draft assessment has been created. You can access this test from the My Assessments page anytime')}</p>}
                    </div>
                    <div className='form-group mt-3'>
                        <label>*{t('Assessment Name')}</label>
                        <input type='text' value={testName} className='form-control mt-1' onChange={(e) => {
                            setTestName(e.target.value)
                            setTestNameErr('')
                        }} />
                        <div style={{ marginTop: 5, color: 'red' }}>{testNameErr}</div>
                    </div>
                    <div className='form-group mt-3'>
                        <label>{t('Description')}</label>
                        <textarea
                            value={description} className='form-control mt-1' onChange={(e) => {
                                setDescription(e.target.value)
                            }}
                        />
                    </div>
                    <div className='form-group mt-3'>
                        <button className='form-control position-relative' disabled={(testNameErr || loading)} onClick={goToQuestion}>
                            {loading ? <BallBeat color={'#fff'} /> : (t('Continue'))}
                        </button>
                    </div>
                </Modal.Body>

            </Modal>
        </div>
    )
}
