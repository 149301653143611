import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { getTotal } from '../../utils/miscelleneous';
import { useDispatch } from 'react-redux';
import { checkValidTest } from '../../Redux/ActionCreators/testLibraryAction';
import { useTranslation } from 'react-i18next';

export default function EditTestModal({ isEdit, testName, time, setIsEdit, automaticQues, editDetail }) {
    const [name, setName] = useState(testName);
    const [nameErr, setNameErr] = useState('');
    const [duration, setDuration] = useState(getTotal(automaticQues, 'ques'));
    const [durationError, setDurationError] = useState('');
    const [shouldValidate, setShouldValidate] = useState(false);
    const { t } = useTranslation();

    const dispatch = useDispatch();
    useEffect(() => {
        setName(testName);
        setDuration(time)
    }, [testName, time])


    useEffect(() => {
        if (!shouldValidate) return

        const delay = setTimeout(() => {
            validateNameViaApi(name);
        }, 200);

        return () => clearTimeout(delay);
    }, [name, shouldValidate]);



    const validateNameViaApi = async (input) => {

        const res = input !== '' && await dispatch(checkValidTest(input));
        if (res?.payload?.isValid) {
            setNameErr('');
        } else {
            setNameErr(t('Test Name already exist'));
        }
    };

    return (
        <div>
            <Modal
                show={isEdit}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                dialogClassName={"add-test-content"}
            >
                <Modal.Body>
                    <div className='sticky-close'>
                        <img src='/images/close.svg' alt='' onClick={() => setIsEdit(false)} />
                    </div>
                    <div className='form-group mt-3'>
                        <label>*{t('Test Name',{ns: 'mytest'})}</label>
                        <input type='text' value={name} className='form-control mt-1' onChange={(e) => {
                            setName(e.target.value);
                            setShouldValidate(true);
                            setNameErr('')
                        }} />
                        <div style={{ marginTop: 5, color: 'red' }}>{nameErr}</div>
                    </div>
                    <div className='form-group mt-3'>
                        <label>*{t('Test duration (mins)',{ns: 'mytest'})}</label>
                        <input type='text' value={duration} className='form-control mt-1' onChange={(e) => {
                            setDuration(e.target.value)
                            setDurationError('')
                        }} />
                        <div style={{ marginTop: 5, color: 'red' }}>{durationError}</div>
                    </div>
                    <div className='form-group'>
                        <button className='form-control position-relative' disabled={((!name && !duration) || nameErr)}
                            onClick={() => {
                                editDetail(name, duration);
                            }}
                        >
                            {t('Continue',{ns: 'mytest'})}
                        </button>
                    </div>
                </Modal.Body>

            </Modal>
        </div>
    )
}
