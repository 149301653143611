import React, { useEffect, useState } from 'react';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { getAccountDetail, updateCompanyProfile } from '../../Redux/ActionCreators/settingAction';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import "./settings.scss";
import { getLanguages } from '../../Redux/ActionCreators/testLibraryAction';


export default function Accounts() {
    const [lang, setLang] = useState(localStorage.getItem('i18nextLng'))
    const [name, setName] = useState('');
    const [nameError, setNameError] = useState('');
    const [imageUrl, setImageUrl] = useState('');
    const [image, setImage] = useState('');
    const [imageName, setImageName] = useState('');
    const [website, setWebsite] = useState('');
    const [update, setUpdate] = useState(false);
    const [successMessageText, setsuccessMessageText] = useState('');
    const [successMsg, setSuccessMsg] = useState(false);
    const [imageError, setImageError] = useState('');
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();
    const { accountDetail } = useSelector(state => state.setting);
    const { languages } = useSelector(state => state.testLibrary);

    useEffect(() => {
        const firstRender = async () => {
            await dispatch(getLanguages());
            const res = await dispatch(getAccountDetail());
            if (res?.type === 'GET_ACCOUNT_DETAIL') {
                setName(res?.payload?.displayName);
                setImage(res?.payload?.logoImage);
                setImageUrl(res?.payload?.logoImage);
                setWebsite(res?.payload.website === null ? '' : res?.payload.website);
                setLang({ languageCode: res.payload.languageCode, label: res.payload.languageName, value: res.payload?.languageId })
            }
        }
        firstRender();
    }, [])




    const changeCampusName = (val) => {
        setName(val)
        setNameError('')
    }

    const changeImage = (e) => {
        if (e.target.files[0].size > 2097152) {
            setSuccessMsg(true);
            setsuccessMessageText(t("The file size cannot exceed 2MB"))
        }
        else {
            const file = e.target.files[0]
            const reader = new FileReader();
            reader.onloadend = () => {
                setImageUrl(reader.result);
            }
            if (file) {
                reader.readAsDataURL(file);
                setImageUrl(reader.result);
                setImageName(file.name);
                setImage(file)
                setUpdate(true)
            }
            else {
                setImageUrl('');
                setImageName('');
                setUpdate(false);
            }
        }

    }

    const editCompany = async () => {
        if (name === "") {
            setNameError(t('Please enter Company name'))

        } else if (image === '') {
            setImageError(t('Please upload image for logo'))
        }
        else {
            let data
            if (update) {
                data = {
                    name: name,
                    website: website,
                    image: image,
                    tenantId: accountDetail?.tenantID,
                    languageId: lang.value
                }
                await dispatch(updateCompanyProfile(data));
                setSuccessMsg(true);
                setsuccessMessageText(t("Company Profile has been successfully updated"))
            }
            else {
                data = {
                    name: name,
                    website: website,
                    tenantId: accountDetail?.tenantID,
                    languageId: lang.value
                }
                await dispatch(updateCompanyProfile(data));
                setSuccessMsg(true);
                setsuccessMessageText(t("Company Profile has been successfully updated"))
            }
        }
    }

    const handleChangeLanguage = async (e) => {
        console.log(e)
        await setLang(e);
        i18n.changeLanguage(e.languageCode);
        let data = {
            name: name,
            website: website,
            tenantId: accountDetail?.tenantID,
            languageId: e.value
        }
        await dispatch(updateCompanyProfile(data));
        localStorage.setItem("i18nextLng", e.languageCode);
        setSuccessMsg(true);
        setsuccessMessageText(t("Company Profile has been successfully updated"))
    }

    const snackClosed = () => {
        setSuccessMsg(false);
        setsuccessMessageText('');
    }


    return (
        <div className='account-holder row'>
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                open={successMsg}
                onClose={snackClosed}
                autoHideDuration={4000}
            >
                <Alert onClose={snackClosed} severity="success" sx={{ width: '100%' }}>
                    {successMessageText}
                </Alert>
            </Snackbar>
            <h3 className='d-flex justify-content-between align-items-center'>{t('Account Details', { ns: 'profile' })}
                <div className='d-flex  align-items-center' style={{ width: '30%' }} >
                    <p style={{ fontSize: 12, width: '60%' }}> {t('App Language', { ns: 'profile' })}</p>&nbsp;
                    <Select className='form-control select-input'
                        value={lang}
                        onChange={handleChangeLanguage}
                        placeholder='Select Language'
                        options={languages?.map(el => [{ ...el, label: el.languageName, value: el.languageId }][0])} />
                </div></h3>
            <div id="google_translate_element"></div>
            {/* <p>Update account details. Personal profile details can be updated in the profile section.</p> */}
            <p className='mt-2'>{t('Fields marked * are mandatory', { ns: 'profile' })}</p>
            <div className='col-md-5'>
                <div className='form-group mt-3'>
                    <label>{t('Company Name', { ns: 'profile' })}*
                    </label>
                    <input className='form-control mt-2' value={name} onChange={(e) => changeCampusName(e.target.value)} pattern="[A-Za-z]" />
                    {nameError}
                </div>
                <div className='form-group mt-4'>
                    <label>{t('Website(Optional)', { ns: "profile" })}
                    </label>
                    <input className='form-control mt-2' value={website} onChange={(e) => setWebsite(e.target.value)} />
                </div>
            </div>
            <div className='col-md-6 offset-md-1'>
                <div className='mt-2'>
                    <label>{t('Company Logo', { ns: 'profile' })}*</label>
                    <div className='d-flex align-items-center '>
                        {
                            image ?
                                <div className='program-image'>
                                    <img src={imageUrl} alt='' width='139px' height='78px' />
                                </div> :
                                <div className='program-image'>
                                    <img src={imageUrl} alt='' />
                                </div>
                        }

                        <div className='d-flex flex-column mt-3 position-relative'>
                            <span> <img src='/images/upload-icon.svg' alt='' /> &nbsp;{t('Upload Image', { ns: 'profile' })} &nbsp; <small>{imageName}</small>
                                <input type='file' className='file-upload'
                                    name="selectedFile" accept="image/png, image/jpeg" onChange={changeImage} />
                            </span>
                        </div>
                    </div>
                    {imageError}
                </div>
            </div>
            <hr style={{ marginTop: 15 }} />
            <div className='save-btn'>
                <button onClick={editCompany}>{t('Save Changes', { ns: 'profile' })}</button>
                {/* <button onClick={(handleModal)}>Cancel</button> */}
            </div>
        </div>
    )
}
