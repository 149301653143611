import { RESET_MY_TESTS, GET_MY_TESTS, GET_NEXT_MY_TESTS, NO_MY_TESTS, GET_QUESTION_SOURCE, RESET_QUESTION, GET_SKILLS, GET_TOPICS, EMPTY_TOPICS, GET_COUNT } from "../Types/types";

let intialState = {
    myTests: [],
    testTypes: [{ name: 'Automatic', id: 4 }, { name: 'Manual', id: 5 }],
    questionSource: [],
    isTestLoading: true,
    total: ''
}


export default function myTestsReducer(state = intialState, action) {
    let stateCopy = JSON.parse(JSON.stringify(state));
    switch (action.type) {
        case RESET_MY_TESTS:
            stateCopy.isTestLoading = true;
            return stateCopy;
        case GET_MY_TESTS:
            stateCopy.myTests = action.payload.myTestLists;
            stateCopy.total = action.payload.totalCount;
            stateCopy.isTestLoading = false;
            return stateCopy;
        case GET_NEXT_MY_TESTS:
            stateCopy.myTests = stateCopy.myTests.concat(action.payload.myTestLists);
            stateCopy.isTestLoading = false;
            return stateCopy;
        case NO_MY_TESTS:
            stateCopy.myTests = [];
            stateCopy.isTestLoading = false;
            return stateCopy;
        case GET_QUESTION_SOURCE:
            stateCopy.questionSource = action.payload.map(el => [{ label: el.sourceName, value: el.sourceTypeId }][0]);
            return stateCopy;
        case GET_SKILLS:
            stateCopy.skills = action.payload.map(el => [{ label: el.knowledgeAreaName, value: el.knowledgeAreaId }][0]);
            return stateCopy;
        case GET_TOPICS:
            stateCopy.topics = action.payload.map(el => [{ label: el.topicName, value: el.topicId }][0]);
            return stateCopy;
        case EMPTY_TOPICS:
            stateCopy.topics = [];
            return stateCopy;
        case GET_COUNT:
            stateCopy.count = action.payload;
            return stateCopy;
        case RESET_QUESTION:
            stateCopy.skills = [];
            return stateCopy;
        default:
            return stateCopy;
    }
}