import React, { useEffect, useState, Fragment } from 'react';
import './testResult.scss';
import { useLocation, useNavigate } from 'react-router-dom';
import Stepper from './stepper';
import { Modal } from 'react-bootstrap';
import Suggestions from '../../../utils/suggestion';
import { useDispatch, useSelector } from 'react-redux';
import { getLanguages, handleLanguage, resetTestDetails, getSearchResults, getNextSearchResults, resetTestLoading, getTestList, getNoCoreTestList, checkOne, confirmReplace, removeFromSelected, resetSelectedTest, checkValidTest, createCustomTest, reorderTest, getSelectedTests, updateTest, resetSearchResult, getCodingTestByID } from '../../../Redux/ActionCreators/testLibraryAction';
import $ from 'jquery';
import { getTotal } from '../../../utils/miscelleneous';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import AddTestModal from '../../../utils/addTestModal';
import TestDetailDrawer from '../../../utils/testDetailDrawer';
import SelectCodingTest from '../selectCodingTest';
import { returnErrors } from '../../../Redux/ActionCreators/errorAction';
import { Drawer, Dialog } from '@mui/material';
import { getAccountDetail } from '../../../Redux/ActionCreators/settingAction';
import { getCategory } from '../../../Redux/ActionCreators/gappeoTestAction';
import { useTranslation } from 'react-i18next';

export default function SelectTest() {
    const [isLoading, setIsLoading] = useState(false);
    const [isGuide, setIsGuide] = useState(false)
    const [isOpen, setIsOpen] = useState(false);
    const [testName, setTestName] = useState('');
    const [testNameErr, setTestNameErr] = useState('');
    const [description, setDescription] = useState('');
    const [query, setQuery] = useState('');
    const [rows, setRows] = useState(10);
    const [page, setPage] = useState(1);
    const [level, setLevel] = useState(0)
    const [refId, setRefId] = useState('');
    const [refTypeId, setRefTypeId] = useState('');
    const [loading, setLoading] = useState(false);
    const [openDetail, setOpenDetail] = useState(false);
    const [edit, setEdit] = useState(false);
    const [codingTestId, setCodingTestId] = useState('');
    const [testid, setTestid] = useState('');
    const { t } = useTranslation();
    const [coding, setCoding] = useState(false);
    const [testType, setTestType] = useState(0);
    const [langId, setLangId] = useState('');
    const [isConfirm, setIsConfirm] = useState(false);
    const [selectData, setSelectData] = useState('');
    const [newData, setNewData] = useState('');
    const [isFilter, setIsFilter] = useState(false);
    const [lang, setLang] = useState(1);
    const [isLanguage, setIsLanguage] = useState(false);
    const [nonCoreTags, setNonCoreTags] = useState([
        { id: 18, name: 'Personality And Culture', src: '/images/personality.svg', isActive: true },
        { id: 16, name: 'Cognitive and Analytical', src: '/images/core.svg', isActive: false },
        { id: 17, name: 'Language and Communication', src: '/images/behavioural.svg', isActive: false }

    ]);

    const [open, setOpen] = useState(false)

    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const { languages, suggestions, totalCount, noFound, testLoading, tests, languageData, nonCoreTests, selectedTests, testDetails, deleteIds, testId, testLibStats } = useSelector(state => state.testLibrary);
    const { categories } = useSelector(state => state.gappeoTest);
    const { accountDetail } = useSelector(state => state.setting);


    useEffect(() => {
        const handleBeforeUnload = (event) => {
            const message = (t('Are you sure you want to leave?'));
            event.returnValue = message; // Standard for most browsers
            return 'Are you sure you want to .map(el => [{ ...el, languageId: 1 }])leave?'; // For some older browsers
        };

        // Add the event listener when the component mounts
        window.addEventListener('beforeunload', handleBeforeUnload);

        // Remove the event listener when the component unmounts
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []);

    useEffect(() => {
        const firstRender = async () => {
            await dispatch(getAccountDetail());
            await dispatch(getCategory());
            await dispatch(getLanguages());
            await setIsLoading(true);
            if (!location.state) {
                navigate('/home')
            }
            if (location.state?.next && location.state.gappeoTest) {
                toggleDrawer(location.state.detail);
                setTestType(0);
                setRefTypeId(1);
                setRefId(0);
            }
            else if (location.state?.next) {
                localStorage.setItem('testId', '');
                const { detail } = location.state
                $(".suggestion-container").hide();

                setQuery(detail.referenceName);
                setRefId(detail?.referenceId);
                setRefTypeId(detail?.referenceTypeId);
                setTestType(detail.testType);
                await dispatch(resetTestDetails());
                await dispatch(resetSelectedTest());
                const res = await dispatch(getTestList(level, detail?.referenceTypeId, detail?.referenceId, '', 1, -1, detail?.testType));
                if (detail && detail?.referenceTypeId === 1) {
                    await getCheckedId(res.payload[0]?.testID, true, { ...res.payload[0], languageId: 1 }, 'test')
                }
                await setIsConfirm(false);
                // SearchReferenceType = 3,
                // ReferenceId = 18
                // await dispatch(getNoCoreTestList(level, 3, 18, '', 1, -1));
            } else {
                const { testId } = location.state
                await dispatch(getTestList(0, 1, 0, '', 1, -1, 0));
                await dispatch(getSelectedTests(testId));
            }
            await setIsLoading(false);
        }
        firstRender();
    }, [])


    const timerRef1 = React.useRef(null);

    const changeType = async (code) => {
        await dispatch(resetTestLoading());
        await setTestType(Number(code));
        await setRefTypeId(1);
        setRefId(0);
        setQuery('');
        if (code === 2) {
            await dispatch(getTestList(level, 1, 0, '', 1, -1, Number(code)))
        } else {
            await dispatch(getTestList(level, refTypeId, refId, '', 1, -1, Number(code)))
        }
    }

    const searchTest = (val) => {
        clearTimeout(timerRef1.current);
        setQuery(val);
        setPage(1);
        timerRef1.current = setTimeout(() => delaySearch(val), 800);
    }


    const delaySearch = async (val) => {
        await dispatch(resetSearchResult());
        await dispatch(getSearchResults(val, page, rows));
        $(".suggestion-container").show();
    }

    const fetchData = () => {
        setPage(page + 1);
        dispatch(getNextSearchResults(query, page + 1, rows));
    }

    const goToSelectTest = async (el) => {
        setQuery(el.referenceName);
        setRefId(el?.referenceId);
        setRefTypeId(el?.referenceTypeId);
        await dispatch(resetTestLoading());
        await dispatch(getTestList(level, el?.referenceTypeId, el?.referenceId, '', 1, -1, testType))
    }

    const selectLang = async (val) => {
        setLang(Number(val));
        await dispatch(handleLanguage(Number(val)))
    }

    const selectCategory = async (val) => {
        const detail = categories.filter(el => el.categoryId === Number(val))[0]
        if (detail) {
            await setRefId(detail?.categoryId);
            await setRefTypeId(3);
            await dispatch(resetTestLoading());
            await dispatch(getTestList(level, 3, detail?.categoryId, '', 1, -1, testType));
        }
        window.innerWidth < 767 && setIsFilter(false);
    }

    const closeSuggestion = () => {
        $(".suggestion-container").fadeOut(200);
    };

    $("body").click((e) => {
        closeSuggestion();
    });

    const getCheckedId = async (val, checked, el, type) => {
        if (!checked) {
            await dispatch(checkOne(val, checked, el, type));
        } else {
            if (selectedTests?.length > 4) {
                returnErrors(dispatch, (t(`You can't select more than 5 tests`)), 500)
            } else {
                const found = selectedTests.find(el1 => el1.testID === el.testID);
                setNewData(el);
                if (found) {
                    setIsConfirm(true);
                    setSelectData(found);
                } else {
                    setIsConfirm(false);
                    await dispatch(checkOne(val, checked, el, type));
                }
            }
        }

    }



    const removeSkill = async (el) => {
        await dispatch(removeFromSelected(el));
    }

    useEffect(() => {
        const delay = setTimeout(() => {
            validateNameViaApi(testName);
        }, 200);

        return () => clearTimeout(delay);
    }, [testName]);



    const validateNameViaApi = async (input) => {

        const res = input !== '' && await dispatch(checkValidTest(input));
        if (res?.payload?.isValid) {
            setTestNameErr('');
        } else {
            setTestNameErr(t('Test Name already exist'));
        }
    };

    const toggleDrawer = (id) => {
        setCoding(true);
        setLangId(id);
        if (location.state.testId || localStorage.getItem('testId') !== null && localStorage.getItem('testId')) {
            setOpen(true);
            setEdit(false);
        } else {
            goNext1();
        }
    };

    const goNext = () => {
        if (location.state.testId || localStorage.getItem('testId') !== null && localStorage.getItem('testId')) {
            goNextWithTest();
        } else {
            setIsOpen(true);
            setCoding(false)
            setTestName('');
            setTestNameErr('');
        }
    }
    const goNext1 = () => {
        if (localStorage.getItem('testId') !== null && localStorage.getItem('testId')) {
            goNextWithTest();
        } else {
            setIsOpen(true);
            setTestName('');
            setTestNameErr('');
        }
    }


    const goToQuestion = async () => {
        setLoading(true);
        if (testName.trim('') === '') {
            setTestNameErr(t('Please Enter Test Name'));
            setLoading(false);
        }
        // else if (!location.state.gappeoTest && !selectedTests?.length) {
        //     setTestNameErr('Please select Tests');
        //     setLoading(false);
        // }
        else {
            let obj = {
                "testName": testName,
                "testLevel": level,
                "tests": selectedTests?.map((el, i) => [{ testId: el.testID, levelId: el.testLevel, languageId: el.languageId, orderId: i + 1 }][0]),
                questions: [],
                testDescription: description
            }
            const res = await dispatch(createCustomTest(obj));
            if (res?.type === "CREATE_CUSTOM_TEST") {
                await setIsOpen(false);
                if (coding) {
                    setOpen(true);
                    setTestid(res.payload);
                    await dispatch(getSelectedTests(res.payload));
                } else {
                    navigate(`/home/add-question`, { state: { testId: res.payload, isQuestionVisible: false } });
                }
                setTestNameErr('');
            }
        }
        setLoading(false);
    }



    const handleDragEnd = async (result) => {
        if (!result.destination) return
        await dispatch(reorderTest(result));
    }

    const goBack = () => {
        navigate('/home')
    }

    const goNextWithTest = async () => {
        if (!selectedTests?.length) {
            setTestNameErr(t('Please select Tests'));
        }
        else {
            setLoading(true);
            let obj = {
                "testId": testid || testId || location.state.testId,
                "testName": testDetails?.testName,
                "tests": selectedTests?.map((el, i) => [{ testId: el.testID, levelId: (el.testLevel || el.testLevelId), orderId: i + 1, testDetailId: el.testDetailId ? el.testDetailId : 0, languageId: el.languageId }][0]),
                "deletedTestDetailIds": [...new Set(deleteIds)],
                questions: []
            }
            const res = await dispatch(updateTest(obj));
            if (res?.type === "UPDATE_TEST") {
                await setIsOpen(false);
                navigate(`/home/add-question`, { state: { testId: location.state.testId || testId, isQuestionVisible: false } });
                setTestNameErr('');
            }
        }
        setLoading(false);
    }

    // Function to make one tag active and the rest inactive
    const toggleNonCoreTag = async (id) => {
        const updatedTags = nonCoreTags.map(tag => {
            return { ...tag, isActive: tag.id === id };
        });
        setNonCoreTags(updatedTags);
        // await dispatch(getNoCoreTestList(level, 3, id, '', 1, -1));

    };



    const goToTest = () => {
        document.body.style.overflow = "auto"
        setOpen(false);
        document.body.style.overflow = "auto"
    }

    const editTest = async (el) => {
        await dispatch(getCodingTestByID(el.testID));
        setCodingTestId(el.testID);
        setEdit(true);
        setOpen(true);
    }

    const openFilter = () => {
        setIsFilter(true);
        dispatch(getCategory());
    }
    return (
        <div className='gappeo-test-results'>
            {<Stepper
                type='test'
                goBack={goBack}
                goNext={location.state?.next ? goNext : goNextWithTest}
            />}

            <Dialog
                className='screen-loader'
                open={isLoading}
                onClose={() => { }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                {t('Loading', { ns: 'home' })}...
            </Dialog>
            <div className='gappeo-test-lists'>

                <div className='gappeo-test-data'>
                    {window.innerWidth > 767 ? null :
                        <div className='gappeo-mobile-header'>
                            <div className='selected-list-header d-flex justify-content-between align-items-end'>
                                <div>
                                    <h4>{t('Assessment Summary', { ns: 'home' })}</h4>
                                    <h5>{selectedTests?.length} {t('test', { ns: 'home' })} | {getTotal(selectedTests, 'ques')} {t('Qs', { ns: 'home' })} | {getTotal(selectedTests, 'duration')} {t('Mins', { ns: 'home' })}</h5>
                                </div>
                                <button className='outline-btn' onClick={() => setOpenDetail(true)}>{t('VIEW DETAILS', { ns: 'home' })}</button>
                            </div>
                        </div>
                    }
                    {window.innerWidth > 767 ?
                        <div className='skill-search'>
                            <div className="d-flex align-items-center mb-3">

                                <p>{t('Test Type', { ns: 'home' })} : </p>

                                <div className='ps-3' >
                                    <div className={testType === 0 ? 'type-btn active-type' : 'type-btn'} onClick={() => changeType(0)}>
                                        <img src='/images/core.svg' width={20} alt='' /> &nbsp;MCQ
                                    </div>
                                </div>
                                <div className='ps-3'>
                                    <div className={testType === 1 ? 'type-btn active-type' : 'type-btn'} onClick={() => changeType(1)}>
                                        <img src='/images/coding.svg' width={20} alt='' /> &nbsp;{t('Coding', { ns: 'home' })}
                                    </div>
                                </div>
                                <div className='ps-3'>
                                    <div className={testType === 2 ? 'type-btn active-type' : 'type-btn'} onClick={() => changeType(2)}>
                                        <img src='/images/personality.svg' width={15} alt='' /> &nbsp;{t('Psychometric', { ns: 'home' })}
                                    </div>
                                </div>
                            </div>


                            <div className='d-flex '>
                                <div className="has-search"  >
                                    <span className="form-control-feedback" >
                                        <img src="/images/search.svg" alt='' />
                                    </span>
                                    <input type="search" className="form-control" autoFocus value={query} placeholder={t('Search for a test / job role / test category', { ns: 'home' })} onChange={(e) => searchTest(e.target.value)} />
                                    {
                                        (suggestions !== undefined && suggestions.length > 0) ?
                                            <Suggestions
                                                type='select-test'
                                                suggestions={suggestions}
                                                goToSelectTest={goToSelectTest}
                                                fetchData={fetchData}
                                                noFound={noFound}
                                                totalCount={totalCount}
                                            />
                                            :
                                            null
                                    }
                                </div>
                                <div style={{ width: '22%', marginLeft: window.innerWidth > 767 ? 15 : '' }}>
                                    <select className='form-control' value={lang} onChange={(e) => selectLang(e.target.value)}>
                                        <option hidden>{t('Select Language', { ns: 'home' })}</option>
                                        {
                                            languages?.map(el => {
                                                return (
                                                    <option value={el.languageId} >{el.languageName}</option>
                                                )
                                            })
                                        }
                                    </select>
                                </div>
                                <div style={{ width: '22%', marginLeft: window.innerWidth > 767 ? 15 : '' }}>
                                    <select className='form-control' disabled={(testType === 1) || (testType === 2)} value={refId} onChange={(e) => selectCategory(e.target.value)}>
                                        <option hidden>{t('Select Category', { ns: 'home' })}</option>
                                        {/* <option value={0} >All</option> */}
                                        {
                                            categories?.map(el => {
                                                return (
                                                    <option value={el.categoryId} >{el.categoryName}</option>
                                                )
                                            })
                                        }
                                    </select>
                                </div>
                            </div>
                            {/* <div className='d-flex justify-content-between mt-2 ms-2 w-100'>
                                {
                                    query &&
                                    <div className='d-flex  align-items-center'>
                                        Related searches :&nbsp;
                                        <p className='search-line'>
                                            UX Designer, Content Writer, HR & Admin
                                        </p>
                                    </div>
                                }

                                <h4 className='cursor-pointer mt-2' style={{ pointerEvents: query ? 'auto' : 'none' }} onClick={() => setQuery('')}>CLEAR SEARCH</h4>
                            </div> */}

                        </div>
                        :
                        <div className='skill-search'>

                            <p>{t('Test Type', { ns: 'home' })} : </p>
                            <div className="d-flex align-items-center overflow-auto mb-3">
                                <div className='ps-3' >
                                    <div className={testType === 0 ? 'type-btn active-type' : 'type-btn'} onClick={() => changeType(0)}>
                                        <img src='/images/core.svg' width={20} alt='' /> &nbsp;MCQ
                                    </div>
                                </div>
                                <div className='ps-3'>
                                    <div className={testType === 1 ? 'type-btn active-type' : 'type-btn'} onClick={() => changeType(1)}>
                                        <img src='/images/coding.svg' width={20} alt='' /> &nbsp;{t('Coding', { ns: 'home' })}
                                    </div>
                                </div>
                                <div className='ps-3'>
                                    <div className={testType === 2 ? 'type-btn active-type' : 'type-btn'} onClick={() => changeType(2)}>
                                        <img src='/images/personality.svg' width={15} alt='' /> &nbsp;{t('Psychometric', { ns: 'home' })}
                                    </div>
                                </div>
                            </div>
                            <div className="has-search"  >
                                <span className="form-control-feedback" >
                                    <img src="/images/search.svg" alt='' />
                                </span>
                                <input type="search" className="form-control" value={query} placeholder={t('Search for a test / job role / test category', { ns: 'home' })} onChange={(e) => searchTest(e.target.value)} />

                                {
                                    (suggestions !== undefined && suggestions.length > 0) ?
                                        <Suggestions
                                            type='select-test'
                                            suggestions={suggestions}
                                            goToSelectTest={goToSelectTest}
                                            fetchData={fetchData}
                                            noFound={noFound}
                                        />
                                        :
                                        null
                                }
                            </div>
                            <img src='/images/filter-icon.svg' alt='' onClick={openFilter} className='ms-2' />
                            {/* <div className='d-flex align-item-center mt-2'>
                                <div className={level === 0 ? 'level-tab active' : 'level-tab'} onClick={() => selectLevel(0)}>ALL</div>&nbsp;&nbsp;
                                <div className={level === 1 ? 'level-tab active' : 'level-tab'} onClick={() => selectLevel(1)}>Beginner</div>&nbsp;&nbsp;
                                <div className={level === 2 ? 'level-tab active' : 'level-tab'} onClick={() => selectLevel(2)}>Advanced</div>
                            </div> */}
                        </div>
                    }


                </div>
                <div className={window.innerWidth > 767 ? 'gappeo-test-data d-flex' : 'gappeo-test-data'}>
                    {

                        <Fragment>

                            <div className='select-list'>
                                {query && <p>{t('Search Results', { ns: 'home' })} </p>}
                                {/* <p>Core tests</p> */}
                                <div className='list-skill' >
                                    {testLoading ?
                                        t('Loading..', { ns: 'home' }) :
                                        testType === 1 ?
                                            languageData?.map((el, i) => {
                                                return (
                                                    <div className='list-skill-item' style={{
                                                        width: window.innerWidth > 767 ? '23%' : '100%', height: window.innerWidth > 767 ? '70px' : '80px'
                                                    }} onClick={() => toggleDrawer(el)}>
                                                        <div>
                                                            <img src='/images/plus-circle.svg' alt='' />
                                                        </div>
                                                        < div className='d-flex flex-column align-items-start ms-3' >
                                                            <div className='skill-type' style={{ width: window.innerWidth > 767 ? '' : '75%' }}>
                                                                {<img src='/images/coding.svg' width={12} alt='' />}&nbsp;&nbsp;{'Coding Language'}
                                                            </div>
                                                            <h4>{el.displayLanguage}</h4>

                                                        </div>
                                                    </div>
                                                )
                                            })
                                            :
                                            (tests?.length > 0 ?
                                                tests?.map((el, i) => {
                                                    return (
                                                        <div key={el.testID} className='list-skill-item'>
                                                            <div>
                                                                <input type='checkbox' onChange={(e) => getCheckedId(e.target.value, e.target.checked, el, 'test')} style={{ width: 16, height: 16 }} value={el.testID}
                                                                    checked={el.isSelected} />
                                                            </div>
                                                            <div className='d-flex flex-column align-items-start ms-3'>
                                                                <div className='skill-type' style={{ width: window.innerWidth > 767 ? '' : '75%' }}>
                                                                    {el.categoryId === 16 ? <img src='/images/cultural.svg' alt='' /> : el.categoryId === 17 ? <img src='/images/behavioural.svg' alt='' /> : el.categoryId === 3 ? <img src='/images/coding.svg' alt='' width={12} /> : el.categoryId === 18 ? <img src='/images/personality.svg' alt='' /> : <img src='/images/core.svg' alt='' />}&nbsp;&nbsp;{el.categoryName}
                                                                </div>
                                                                <h4>{el.testName}</h4>
                                                                <p><strong style={{ color: el.testLevelName === 'Advanced' ? '#f6b200' : '#009199' }}>{el.testLevelName}</strong> | {el.questionCount} Qs | {el.duration} {t('Mins', { ns: 'home' })} | {el.languageId === 1 ? 'en' : el.languageId === 2 ? 'es' : (el.languageId === 3) ? 'pt' : (el.languageId === 4) ? 'pt' : 'it'} </p>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                                :
                                                <div className='empty-list' style={{ width: '76%' }}>
                                                    <img src='/images/magnifier.svg' alt='' />
                                                    <div className='mt-2'>
                                                        <h3>{t('Search for a test / job role / test category', { ns: 'home' })}</h3>
                                                        {/* <div className={window.innerWidth > 767 ? 'd-flex justify-content-center  align-items-center mt-2' : 'd-flex justify-content-start  align-items-center mt-2'}>
                                        Search by categories :&nbsp;
                                        <p className='search-line'>
                                            Core, Personality,Cultural,HR
                                        </p>
                                    </div>
                                    <div className={window.innerWidth > 767 ? 'd-flex justify-content-center  align-items-center mt-2' : 'd-flex justify-content-start  align-items-center mt-2'}>
                                        Poular Job roles :&nbsp;
                                        <p className='search-line'>
                                            UX Designer, Content Writer, HR & Admin
                                        </p>
                                    </div>
                                    <div className={window.innerWidth > 767 ? 'd-flex justify-content-center  align-items-center mt-2' : 'd-flex justify-content-start  align-items-center mt-2'}>
                                        Popular tests :&nbsp;
                                        <p className='search-line'>
                                            Javascript, UX Design, Python
                                        </p>
                                    </div> */}
                                                    </div>
                                                </div>)
                                    }

                                </div>

                            </div>
                            {/* <div className='select-list mt-2'>
                                    {nonCoreTests?.length
                                        ?
                                        <div className='mt-4'>
                                            <p>Suggested non-core tests</p>
                                            <div className='non-core-container'>
                                                {nonCoreTags?.map(tag => (
                                                    <div
                                                        key={tag.id}
                                                        className={`non-core-tags ${tag.isActive ? 'active' : ''}`}
                                                        onClick={() => toggleNonCoreTag(tag.id)}
                                                    >
                                                        <img src={tag.src} alt='' />&nbsp;&nbsp;{tag.name}
                                                    </div>
                                                ))}
                                            </div>
                                            <div className='list-skill'>
                                                {
                                                    nonCoreTests?.map((el, i) => {
                                                        return (
                                                            <div className='list-skill-item'>
                                                                <div>
                                                                    <input type='checkbox' onChange={(e) => getCheckedId(e.target.value, e.target.checked, el, 'noncoretest')} style={{ width: 16, height: 16 }} value={el.testID}
                                                                        checked={el.isSelected} />
                                                                </div>
                                                                <div className='d-flex flex-column align-items-start ms-3'>
                                                                    <div className='skill-type'>
                                                                        <img src={el.categoryName === 'core' ? '/images/core.svg' : el.categoryId === 18 ? '/images/personality.svg' :
                                                                            el.categoryName === 'cultural' ? '/images/cultural.svg' : el.categoryName === 'behavioural' ? '/images/behavioural.svg' : null} alt='' />&nbsp;&nbsp;{el.categoryName}
                                                                    </div>
                                                                    <h4>{el.testName}</h4>
                                                                    <p>{el.testLevelName} | {el.questionCount} Qs | {el.duration} Mins </p>
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }

                                            </div>
                                        </div> :
                                        null
                                    }
                                </div> */}
                        </Fragment>

                    }
                    {window.innerWidth > 767 &&
                        <div className='selected-test'>
                            <div className='selected-list-header'>
                                <h4>{t('Assessment Summary', { ns: 'home' })}</h4>
                                {testDetails?.testName &&
                                    <h4 className='d-flex justify-content-center align-items-center' style={{ color: '#fff', fontSize: 18 }}>{testDetails?.testName}</h4>}
                                <h5>{selectedTests?.length} {t('test', { ns: 'home' })} | {getTotal(selectedTests, 'ques')} Qs | {getTotal(selectedTests, 'duration')} {t('Mins', { ns: 'home' })}</h5>
                                {/* <p>Level:Advanced</p> */}
                            </div>
                            <div className='selected-list'>
                                <h1>{t('Selected Tests', { ns: 'home' })}</h1>
                                {/* <p>{selectedTests?.length} test | {getTotal(selectedTests, 'ques')} Qs | {getTotal(selectedTests, 'time')} Mins</p> */}
                                <DragDropContext onDragEnd={handleDragEnd}>
                                    <Droppable droppableId={'2111'}>
                                        {
                                            (provided) => (
                                                <div {...provided.droppableProps} ref={provided.innerRef}>
                                                    {selectedTests && selectedTests?.map((el, index) => {
                                                        return (
                                                            <Draggable key={el.testID} draggableId={`${el.testID}-${el.testLevel}`} index={index} >
                                                                {
                                                                    (provided) => (
                                                                        <div {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef} className='selected-list-item'>
                                                                            <div className='d-flex'>
                                                                                <img src='/images/drag.svg' alt='' width={16} />&nbsp;&nbsp;
                                                                                <div>
                                                                                    <h1>{el.testName}</h1>
                                                                                    <p>{el.testLevelName} | {el.questionCount} Qs | {el.duration} {t('Mins', { ns: 'home' })} | {el.languageId === 1 ? 'en' : el.languageId === 2 ? 'es' : (el.languageId === 3) ? 'pt' : (el.languageId === 4) ? 'pt' : 'it'}</p>
                                                                                </div>
                                                                            </div>
                                                                            {el.canEdit ? <img src='/images/edit-icon.svg' onClick={() => editTest(el)} alt='' width={16} /> : null}
                                                                            <img src='/images/delete-icon.svg' onClick={() => removeSkill(el)} alt='' width={16} />

                                                                        </div>
                                                                    )
                                                                }
                                                            </Draggable>
                                                        )
                                                    })}
                                                    {provided.placeholder}
                                                </div>

                                            )}
                                    </Droppable>
                                </DragDropContext>

                            </div>
                            {/* {((accountDetail?.planId === 4) || (accountDetail?.planId === 5) || (accountDetail?.planId === 6)) ? null : */}
                            <div className='px-3'>
                                <button className='add-btn form-control' onClick={() => toggleDrawer()}>{t('ADD CODING TEST', { ns: 'home' })}</button>
                            </div>
                        </div>
                    }
                </div>
                {
                    window.innerWidth > 767 &&
                    <div className='next-btn'>
                        <button disabled={loading} onClick={location.state?.next ? goNext : goNextWithTest} >{t('NEXT', { ns: 'home' })}</button>
                    </div>
                }
            </div >


            {
                window.innerWidth < 767 &&
                <TestDetailDrawer
                    openDetail={openDetail}
                    setOpenDetail={() => setOpenDetail(false)}
                    handleDragEnd={handleDragEnd}
                    removeSkill={removeSkill}
                    selectedTests={selectedTests}
                    testDetails={testDetails}
                />
            }

            {/* Intro Modal */}
            <Modal
                show={isGuide}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                dialogClassName={"intro-modal"}
            >
                <Modal.Body>
                    <div className='sticky-close'>
                        <img src='/images/close-white.svg' alt='' onClick={() => setIsGuide(false)} />
                    </div>
                    <img src='/images/up-arrow.svg' alt='' />
                    <h5>{t('Search for more tests here.', { ns: 'home' })}</h5>
                    <div className='d-flex align-items-center mt-3'>
                        <h5 >
                            {t('Select a test card to addb to the Assessment summary', { ns: 'home' })}
                        </h5>
                        <span className='d-flex ms-3'> <img src='/images/left-arrow.svg' alt='' /> &nbsp; <img src='/images/left-arrow.svg' alt='' /></span>
                    </div>
                    <div className='ok'>
                        <button className='outlin-btn' onClick={() => setIsGuide(false)}>{t('Ok', { ns: 'home' })}</button>
                    </div>
                </Modal.Body>

            </Modal>

            <AddTestModal
                edit={false}
                isOpen={isOpen}
                setIsOpen={() => setIsOpen(false)}
                testName={testName}
                testNameErr={testNameErr}
                description={description}
                setDescription={(val) => setDescription(val)}
                setTestName={(e) => setTestName(e)}
                setTestNameErr={(e) => setTestNameErr(e)}
                goToQuestion={goToQuestion}
                loading={loading}
            />

            <Drawer
                className='select-drawer'
                anchor={'top'}
                open={open}
                onClose={() => setOpen(!open)}
            >
                <div className="p-4" >
                    <SelectCodingTest
                        goToTest={goToTest}
                        edit={edit}
                        testid={codingTestId}
                        langId={langId}
                    />
                </div>
            </Drawer>

            {/* ########## Filter modal ############### */}
            <Modal
                show={isFilter}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                dialogClassName={"update-modal"}
            >
                <div class="modal-header" style={{ background: '#009199' }}>
                    <h5 class="modal-title">{t('Select Category', { ns: 'home' })}</h5>

                    <div className="sticky-close" onClick={() => setIsFilter(false)}>
                        <img src="/images/close-white.svg" width='15px' alt='' /></div>

                </div>
                <Modal.Body style={{ padding: '22px', }}>
                    <div>
                        <select className='form-control' disabled={(testType === 1) || (testType === 2)} value={refId} onChange={(e) => setRefId(e.target.value)}>
                            <option hidden>{t('Select Category', { ns: 'home' })}</option>
                            {/* <option value={0} >All</option> */}
                            {
                                categories?.map(el => {
                                    return (
                                        <option value={el.categoryId} >{el.categoryName}</option>
                                    )
                                })
                            }
                        </select>
                    </div>
                    <div className='save-btn'>
                        <button disabled={!refId} onClick={() => selectCategory(refId)}  >{t('Apply Filter', { ns: 'home' })}</button>
                    </div>
                </Modal.Body>
            </Modal>

            {/* ########## Confirm Delete modal ############### */}
            {selectData &&
                <Modal
                    show={isConfirm}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    // onHide={this.handleCloseModal}
                    dialogClassName={"confirm-modal "}
                >
                    <div className="p-4" >
                        <h1 style={{ lineHeight: "25px" }}>
                            {t('You have already added', { ns: 'home' })} <strong>{selectData?.testLevelName}</strong> {t('level', { ns: 'home' })} <strong>{selectData?.testName}</strong> {t('test. Do you want to replace it with', { ns: 'home' })} <strong>{newData?.testLevelName}</strong> {t('level test?', { ns: 'home' })}
                        </h1>
                        <div className="save-btn">
                            <button onClick={() => {
                                dispatch(confirmReplace(selectData, newData))
                                setIsConfirm(false)
                            }} >{t('Yes', { ns: 'home' })}</button>&nbsp;&nbsp;
                            <button onClick={() => setIsConfirm(false)}>{t('No', { ns: 'home' })}</button>
                        </div>
                    </div>
                </Modal>
            }
        </div >
    )
}