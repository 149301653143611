import React from 'react'
import { useTranslation } from 'react-i18next';

export default function NotFound() {
    const { t } = useTranslation();
    return (
        <div>
            <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%,-50%)' }}>
                <img src='/images/404.gif' alt='' width={200} />
                <h1>{t('Page not found',{ns: 'profile'})}</h1>
            </div>

        </div>
    )
}
