import { RESET_LOADING, GET_REPORT, GET_REPORT1, GET_NEXT_REPORT, GET_OLD_REPORT, GET_OLD_NEXT_REPORT, GET_DETAILED_REPORT } from "../Types/types";

let intialState = {
    testReport: [],
    isReportLoading: true,
    reportVal: false
}


export default function reportReducer(state = intialState, action) {
    let stateCopy = JSON.parse(JSON.stringify(state));
    switch (action.type) {
        case RESET_LOADING:
            stateCopy.isReportLoading = true;
            return stateCopy;
        case GET_REPORT:
            stateCopy.testReport = action.payload.candidateReportList;
            stateCopy.totalCount = action.payload.totalCount;
            stateCopy.isReportLoading = false;
            return stateCopy;
        case GET_REPORT1:
            stateCopy.testReport = [];
            stateCopy.totalCount = 0;
            stateCopy.isReportLoading = false;
            return stateCopy;
        case GET_NEXT_REPORT:
            stateCopy.testReport = stateCopy.testReport.concat(action.payload.candidateReportList);
            stateCopy.totalCount = action.payload.totalCount;
            stateCopy.isReportLoading = false;
            return stateCopy;
        case GET_OLD_REPORT:
            stateCopy.oldTestReport = action.payload;
            stateCopy.isReportLoading = false;
            return stateCopy;
        case GET_OLD_NEXT_REPORT:
            stateCopy.oldTestReport = stateCopy.oldTestReport.concat(action.payload);
            stateCopy.isReportLoading = false;
            return stateCopy;
        case GET_DETAILED_REPORT:
            stateCopy.detailReport = action.payload;
            return stateCopy;
        default:
            return stateCopy;
    }
}