import React, { useRef } from 'react';
import './App.scss';
import { Routes, Route, useNavigate } from 'react-router-dom';
import Login from './Components/Login/login';
import Header from './Layout/header';
import { useEffect } from 'react';
import RouteItems from './Routes/routes';
import CandidateHeader from './Layout/candidateHeader';
import Signup from './Components/Signup/signup';
import CouponRedeem from './Components/Signup/couponRedeem';
import Modal from 'react-bootstrap/Modal';
import { resentEmail } from './Redux/ActionCreators/authAction';
import { useDispatch } from 'react-redux';
import Candidatetest from './Components/candidatetest';
import NotFound from './Components/notFound';
import Home from './Components/home';
import { useTranslation } from 'react-i18next';

import $ from 'jquery';

function App() {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [scriptLoaded, setScriptLoaded] = React.useState(false);

  // language translation script
  useEffect(() => {
    if (window.location.pathname.split('/')[1] === 'candidate-test') {
      const googleTranslateElementInit = () => {
        try {
          new window.google.translate.TranslateElement(
            {
              // pageLanguage: 'en',
              // multilanguagePage: true,
              autoDisplay: false,
              includedLanguages: 'en,es,pt,it,de,fr',
            },
            'google_translate_element'
          );
          setTimeout(() => {
            const selectElement = document.querySelector('.goog-te-combo');
            if (selectElement) {
              selectElement.value = 'en';
              selectElement.dispatchEvent(new Event('change'));
            }
          }, 1000);
          setTimeout(() => {
            const skipTranslateElement = document.querySelector('.skiptranslate');
            if (skipTranslateElement) {
              skipTranslateElement.style.display = 'none';
            }
          }, 1000);
        } catch (error) {
          console.error('Error during Google Translate initialization:', error);
        }
      };

      window.googleTranslateElementInit = googleTranslateElementInit;

      const addGoogleTranslateScript = () => {
        if (!document.querySelector('script[src="//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"]')) {
          const script = document.createElement('script');
          script.src = '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit';
          script.async = true;
          script.onload = () => {
            console.log('Google Translate script loaded successfully.');
            setScriptLoaded(true);
          };
          script.onerror = () => {
            console.error('Google Translate script failed to load.');
          };
          document.body.appendChild(script);
        } else {
          setScriptLoaded(true);
        }
      };

      if (!scriptLoaded) {
        addGoogleTranslateScript();
      } else if (window.google && window.google.translate && window.google.translate.TranslateElement) {
        googleTranslateElementInit();
      }
    } else {
      // Remove the Google Translate element
      var translateElement = document.getElementById('google_translate_element');
      if (translateElement) {
        translateElement.parentNode.removeChild(translateElement);
      }

      // Remove the Google Translate script
      var translateScript = document.getElementById('google_translate_script');
      if (translateScript) {
        translateScript.parentNode.removeChild(translateScript);
      }

      var translateScriptSrc = document.getElementById('google_translate_script_src');
      if (translateScriptSrc) {
        translateScriptSrc.parentNode.removeChild(translateScriptSrc);
      }

      // Optionally, remove any iframes created by Google Translate
      var iframes = document.getElementsByTagName('iframe');
      for (var i = iframes.length - 1; i >= 0; i--) {
        if (iframes[i].src.indexOf('translate.google.com') > -1) {
          iframes[i].parentNode.removeChild(iframes[i]);
        }
      }
    }
  }, [scriptLoaded]);



  useEffect(() => {

    if (localStorage.getItem('token')) {
      if (localStorage.getItem("profileDetails")) {
        const details = JSON.parse(localStorage.getItem("profileDetails"));
        if (details?.roleId === "1") {
          window.location.pathname === '/' && (
            navigate('/home')
          )
        }
        else {
          window.location.pathname === '/' && (
            navigate('/home')
          )
        }
      }
      else {
        window.location.pathname === '/' && (
          navigate('/home')
        )
      }


    } else {
      (window.location.pathname === '/') && (
        navigate('/login')
      )
    }
  }, [])
  const resendEmail = async () => {
    let obj = {
      id: localStorage.getItem('userId'),
      emailVerificationUrl: window.location.protocol + '//' + window.location.host + "/login/id"
    }
    await dispatch(resentEmail(obj))
  }

  const goToLogin = () => {
    localStorage.clear();
    navigate('/login');
  }
  if (process.env.NODE_ENV === 'production') {
    console.log = function no_console() { };
  }

  return (
    <div>
      {/* <Header /> */}
      <Routes>
        <Route path='/signup' element={<Signup />} />
        <Route path='/couponredeem' element={<CouponRedeem />} />
        <Route path='/login' element={<Login />} />
        <Route path='/login/:code' element={<Home />} />
        <Route path='/candidatetest/:token' element={<Candidatetest />} />
        <Route element={<Header />}>
          {(RouteItems || []).map((item) => {
            if (item.layout === "mainLayout") {
              return (
                <Route
                  key={item.path}
                  path={item.path}
                  element={item.component}
                />
              );
            }

          })
          }
        </Route>
        <Route element={<CandidateHeader />}>
          {(RouteItems || []).map((item) => {
            if (item.layout === "candidate-layout") {
              return (
                <Route
                  key={item.path}
                  path={item.path}
                  element={item.component}
                />
              );
            }

          })
          }
        </Route>
        <Route path='*' element={<NotFound />} />
      </Routes>
      {/* emailVerified */}

      {/* ########## Confirm Delete modal ############### */}
      <Modal
        show={localStorage.getItem('emailVerified') === 'false' ? true : false}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        // onHide={this.handleCloseModal}
        dialogClassName={"confirm-modal"}
      >
        <div className="p-4" >
          <div className='sticky-close'>
            <img src='/images/close.svg' alt='' onClick={goToLogin} />
          </div>
          <br />
          <div class="row" style={{ color: '#000000' }}>

            <div class="col-12">
              <h1 class="maintxt" style={{ color: '#000000' }}> {t('Please verify your email')}.</h1><br />
            </div>

            <div class="col-12">
              <span style={{ textAlign: 'center', fontSize: 14, fontWeight: 300, color: '#000000', }}>
                {t('Youre almost there! We sent an email to')}
              </span>
              <h6 class="subheading f-14" style={{ paddingTop: '1%' }}>{localStorage.getItem('email')}</h6>
            </div>

            <div class="col-12" >
              <span style={{ textAlign: 'center', fontSize: 14, fontWeight: 300, color: '#000000', paddingTop: '1%' }}>
                {t('just click on the link in that email to complete your signup. You may need to')} <strong>{t('check your spam folder if you dont see it')}.</strong>
                <br /><br />
                {t('Do you still need help finding the email? No problem')}.<br />
              </span>
            </div>
            <div class="col-12">
              <div className="save-btn text-center">
                <button onClick={resendEmail} style={{ paddingTop: '1%', width: 230 }}
                  type="button"> {t('Resend Verification Email')}
                </button>
                {/* onClick={() => dispatch(verifyEmail(localStorage.getItem('userId')))} */}
              </div>
            </div>
          </div>
        </div>
      </Modal >
    </div >
  );
}

export default App;
