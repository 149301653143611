

export const freshsales_new_contact = function (firstname, email, source, couponCode = '') {
    var new_contact =
    {
        "First name": firstname, //Replace with first name of the user
        "Last name": firstname,
        "Email": email, //Replace with email of the user
        "Source": source,
        "External ID": couponCode,
        "company": {
            "Name": "gappeo", //Replace with company name
            "Website": "https://gappeo.com/" //Replace with website of company
        }
    };
    var identifier = email;
    window.fwcrm.identify(identifier, new_contact);
    // localStorage.setItem('freshchat', JSON.stringify(new_contact))
    console.log("freshsales_new_contact", new_contact);
}

export const freshsales_trackCustomEvent = function () {
    window.FM.trackCustomEvent("Added to cart",
        {
            "email": "david.thompson@sample.com",
            "price": 100.21,
            "currency": "USD",
            "is_mobile_transaction": true,
            "return date": "2019-12-30"
        })
    console.log("freshsales_trackCustomEvent");
}
