import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useTranslation } from 'react-i18next';

export default function Suggestions({ type, noFound, suggestions, totalCount, fetchData, goToSelectTest }) {
    const { t } = useTranslation();
    return (
        <div className='suggestion-container' style={{ top: type === 'select-test' ? 52 : '' }}>
            <ul>
                <InfiniteScroll
                    dataLength={suggestions && suggestions?.length} //This is important field to render the next data
                    next={totalCount > suggestions?.length ? fetchData : ''}
                    hasMore={true}
                >
                    {
                        suggestions !== undefined && suggestions.length > 0 ?
                            suggestions.map((items, i) => {

                                return (

                                    <li onClick={() => goToSelectTest({ ...items, testType: 0 })} >
                                        {
                                            noFound ?
                                                <div className='text-center'>
                                                    {t('No Results found')}
                                                </div> :
                                                <div className="container-fluid">
                                                    <div className="d-flex justify-content-between align-items-center" >

                                                        <h3>
                                                            {items?.referenceName}
                                                        </h3>
                                                        <div className='skill-tags'>
                                                            {items?.referenceTypeId === 1 ?
                                                                <div className='skill-tag'>
                                                                    <img src='/images/test-icon.svg' alt='' /> &nbsp;{t('Test')}
                                                                </div> :
                                                                items?.referenceTypeId === 2 ?
                                                                    <div className='skill-tag'>
                                                                        <img src='/images/role.svg' alt='' /> &nbsp;{t('Role')}
                                                                    </div> :
                                                                    items?.referenceTypeId === 3 ?
                                                                        <div className='skill-tag'>
                                                                            <img src='/images/category.svg' alt='' /> &nbsp;{t('Category')}
                                                                        </div> :
                                                                        items?.referenceTypeId === 4 ?
                                                                            <div className='skill-tag'>
                                                                                <img src='/images/coding.svg' alt='' /> &nbsp;{t('Coding Language')}
                                                                            </div> :
                                                                            null
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                        }
                                    </li>
                                )
                            }) : null
                    }
                </InfiniteScroll>
            </ul>
        </div>
    )
}
