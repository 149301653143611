import React from 'react';
import { useEffect } from 'react';

export default function Candidatetest() {

    useEffect(() => {
        if ((window.location.pathname.split('/')[1] === 'candidatetest') && window.location.pathname.split('/')[2] !== '') {
            window.location.href = `https://app1.gappeo.com/candidatetest/${window.location.pathname.split('/')[2]}`
        }
    }, []);

    return (
        <div>

            <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%,-50%)' }}>
                <img src='/images/spinner.svg' alt='' />
            </div>
        </div>
    )
}


// import {
//     GET_GAPPEO_STATS, GET_SEARCH_RESULTS, GET_NEXT_SEARCH_RESULTS, NO_FOUND, RESET_SEARCH_RESULT, RESET_TEST_LOADING, RESET_TEST_DETAIL, GET_TEST_LIST, GET_NON_CORE_TEST, SELECT_ONE, REMOVE_FROM_SELECTED,
//     RESET_SELECTED_TEST, REORDER_TEST, CREATE_CUSTOM_TEST, UPDATE_TEST_NAME, GET_TEST_DETAILS,
//     GET_ADDITIONAL_QUESTION, GET_NEXT_ADDITIONAL_QUESTION, NO_QUESTION, SAVE_SELECTED_QUESTION, SELECT_ONE_QUESTION, REMOVE_FROM_SELECTED_QUESTION, GET_QUESTION_TYPES, GET_QUESTION_LEVELS,
//     RESET_QUESTION, SET_QUES_TITLE, SET_OPTIONS, SET_RIGHT_ANSWER, SAVED_QUESTION, DELETE_QUESTION, SET_ERRORS, SET_QUESTION_DETAILS,
//     GET_TENENT_USERS, GET_TEMPLATE, CLOSE_INVITE_MODAL, CHANGE_TEMPLATE, UPDATE_TEMPLATE
// } from '../Types/types';

// let intialState = {
//     noFound: false,
//     selectedTests: [],
//     testLoading: true,
//     tests: [],
//     deleteIds: [],
//     nonCoreTests: [{ title: 'Workplace Attitude', level: 'Advanced', question: 20, time: 20, type: 'behavioural', isSelected: false },
//     { title: 'Leadership skills', level: 'Advanced', question: 20, time: 20, type: 'personality', isSelected: false },
//     { title: 'Communication skills', level: 'Advanced', question: 20, time: 20, type: 'personality', isSelected: false },
//     { title: 'Time Management', level: 'Advanced', question: 20, time: 20, type: 'behavioural', isSelected: false },
//     { title: 'Human Resource', level: 'Advanced', question: 20, time: 20, type: 'cultural', isSelected: false },
//     { title: 'People skills', level: 'Advanced', question: 20, time: 20, type: 'cultural', isSelected: false },
//     { title: 'Team Spirit', level: 'Advanced', question: 20, time: 20, type: 'personality', isSelected: false }
//     ],
//     questionType: [],
//     questionLevel: [],
//     additionalQuestions: [],
//     isQuestionLoading: true,
//     noQuestion: [],
//     selectedQuestion: [],
//     questionOptions: [{ index: 0, questionOption: '', iscorrect: false, optionError: '' },
//     { index: 1, questionOption: '', iscorrect: false, optionError: '' },
//     { index: 2, questionOption: '', iscorrect: false, optionError: '' },
//     { index: 3, questionOption: '', iscorrect: false, optionError: '' }
//     ],
//     isTemplate: false,
//     editTemplate: false,
//     templates: ''
// }


// export default function testLibraryReducer(state = intialState, action) {
//     let stateCopy = JSON.parse(JSON.stringify(state));
//     switch (action.type) {
//         case GET_GAPPEO_STATS:
//             stateCopy.testLibStats = action.payload;
//             return stateCopy;
//         case GET_SEARCH_RESULTS:
//             stateCopy.suggestions = action.payload.searchTestLibraryList;
//             stateCopy.totalCount = action.payload.totalCount;
//             stateCopy.noFound = false;
//             return stateCopy;
//         case GET_NEXT_SEARCH_RESULTS:
//             stateCopy.suggestions = stateCopy.suggestions.concat(action.payload.searchTestLibraryList);
//             stateCopy.totalCount = action.payload.totalCount;
//             stateCopy.noFound = false;
//             return stateCopy;
//         case NO_FOUND:
//             stateCopy.suggestions = [];
//             stateCopy.noFound = true;
//             return stateCopy;
//         case RESET_SEARCH_RESULT:
//             stateCopy.suggestions = [];
//             return stateCopy;
//         case RESET_TEST_LOADING:
//             stateCopy.testLoading = true;
//             return stateCopy;
//         case GET_TEST_LIST:
//             action.payload.forEach(item => {
//                 const foundIndex = stateCopy.selectedTests.find(i => ((i.testID === item.testID) && (i.testLevel === item.testLevel)));
//                 if (foundIndex) {
//                     item.isSelected = true;
//                 } else {
//                     item.isSelected = false;
//                 }
//             })
//             stateCopy.tests = action.payload;
//             stateCopy.testLoading = false;
//             return stateCopy;
//         case GET_NON_CORE_TEST:
//             action.payload.forEach(item => {
//                 const foundIndex = stateCopy.selectedTests.find(i => i.testID === item.testID);
//                 if (foundIndex) {
//                     item.isSelected = true;
//                 } else {
//                     item.isSelected = false;
//                 }
//             })
//             stateCopy.nonCoreTests = action.payload;
//             stateCopy.testLoading = false;
//             return stateCopy;
//         case SELECT_ONE:
//             if (action.payload.checked) {
//                 const found = stateCopy.selectedTests.find(el => ((el.testLevel === action.payload.data.testLevel) && (el.testID === Number(action.payload.id))));
//                 if (found === undefined) {
//                     stateCopy.selectedTests = [...stateCopy.selectedTests, { ...action.payload.data, isSelected: true, orderId: stateCopy.selectedTests?.length + 1 }];
//                 }
//                 stateCopy.deleteIds = stateCopy.deleteIds.filter(el => ((el.testLevel !== action.payload.data.testLevel) && (el.testID !== Number(action.payload.id))));
//                 if (action.payload.type === 'test') {
//                     stateCopy.tests.forEach((el) => {
//                         if (((el.testLevel === action.payload.data.testLevel)) && (el.testID === Number(action.payload.id))) {
//                             el.isSelected = true
//                         }
//                     })
//                 } else {
//                     stateCopy.nonCoreTests.forEach((el) => {
//                         if (el.testID === Number(action.payload.id)) {
//                             el.isSelected = true
//                         }
//                     })
//                 }
//             }
//             else {
//                 stateCopy.selectedTests = stateCopy.selectedTests.filter(el => ((el.testLevel !== action.payload.data.testLevel) && (el.testID !== Number(action.payload.id)))).map((el, i) => ({ ...el, orderId: i }));
//                 if (action.payload.testDetailId) {
//                     stateCopy.deleteIds = [...stateCopy.deleteIds, Number(action.payload.testDetailId)];
//                 }
//                 if (action.payload.type === 'test') {
//                     stateCopy.tests.forEach(el => {
//                         if ((el.testLevel === action.payload.data.testLevel) && ((el.testID === Number(action.payload.id)))) {
//                             el.isSelected = false
//                         }
//                     })
//                 } else {
//                     stateCopy.nonCoreTests.forEach((el) => {
//                         if (el.testID === Number(action.payload.id)) {
//                             el.isSelected = false
//                         }
//                     })
//                 }
//             }

//             return stateCopy;
//         case REMOVE_FROM_SELECTED:
//             stateCopy.selectedTests = stateCopy.selectedTests.filter(el => ((el.testLevel !== action.payload.testLevel) && (el.testID !== Number(action.payload.id)))).map((el, i) => ({ ...el, orderId: i }));

//             if (action.payload.testDetailId) {
//                 stateCopy.deleteIds = [...stateCopy.deleteIds, Number(action.payload.testDetailId)];
//             }
//             stateCopy.tests.forEach(el => {
//                 if ((el.testLevel === action.payload.testLevel) && (el.testID === Number(action.payload.testID))) {
//                     el.isSelected = false
//                 }
//             })
//             stateCopy.nonCoreTests.forEach((el) => {
//                 if (el.testID === Number(action.payload.testID)) {
//                     el.isSelected = false
//                 }
//             })
//             return stateCopy;
//         case RESET_SELECTED_TEST:
//             stateCopy.selectedTests = [];
//             return stateCopy;
//         case REORDER_TEST:
//             const selectedTests = Array.from(stateCopy.selectedTests);
//             const [reorderItem] = selectedTests.splice(action.payload.source.index, 1);
//             selectedTests.splice(action.payload.destination.index, 0, reorderItem);
//             selectedTests.forEach((el, i) => {
//                 if (el.orderId !== i + 1) {
//                     el.orderId = i + 1;
//                 }
//             });
//             stateCopy.selectedTests = selectedTests;
//             return stateCopy;
//         case CREATE_CUSTOM_TEST:
//             stateCopy.deleteIds = [];
//             return stateCopy;
//         case UPDATE_TEST_NAME:
//             stateCopy.testDetails.testName = action.payload.testName;
//             return stateCopy;
//         case RESET_TEST_DETAIL:
//             stateCopy.testDetails = null;
//             return stateCopy;
//         case GET_TEST_DETAILS:
//             stateCopy.testDetails = action.payload;
//             stateCopy.tests.forEach(item => {
//                 const foundIndex = stateCopy.selectedTests.find(i => i.testID === item.testID);
//                 if (foundIndex) {
//                     item.isSelected = true;
//                 } else {
//                     item.isSelected = false;
//                 }
//             })
//             stateCopy.selectedTests = action.payload.testDetails?.tests;
//             stateCopy.selectedQuestion = action.payload.testDetails?.questions;
//             return stateCopy;
//         case GET_ADDITIONAL_QUESTION:
//             action.payload.questionList.forEach(item => {
//                 const foundIndex = stateCopy.selectedQuestion.find(i => i.questionID === item.questionID);
//                 if (foundIndex) {
//                     item.isChecked = true;
//                 } else {
//                     item.isChecked = false;
//                 }
//             })
//             stateCopy.additionalQuestions = action.payload.questionList;
//             stateCopy.totalQues = action.payload.totalCount;
//             stateCopy.noQuestion = false;
//             stateCopy.isQuestionLoading = false;
//             return stateCopy;
//         case GET_NEXT_ADDITIONAL_QUESTION:
//             action.payload.questionList.forEach(item => {
//                 const foundIndex = stateCopy.selectedQuestion.find(i => i.questionID === item.questionID);
//                 if (foundIndex) {
//                     item.isChecked = true;
//                 } else {
//                     item.isChecked = false;
//                 }
//             })
//             stateCopy.additionalQuestions = stateCopy.additionalQuestions.concat(action.payload.questionList);
//             stateCopy.totalQues = action.payload.totalCount;
//             stateCopy.noQuestion = false;
//             stateCopy.isQuestionLoading = false;
//             return stateCopy;
//         case NO_QUESTION:
//             stateCopy.additionalQuestions = [];
//             stateCopy.noQuestion = true;
//             stateCopy.isQuestionLoading = false;
//             return stateCopy;
//         case SAVE_SELECTED_QUESTION:
//             stateCopy.selectedQuestion = action.payload;
//             return stateCopy;
//         case SELECT_ONE_QUESTION:
//             if (action.payload.checked) {
//                 const found = stateCopy.selectedQuestion.find(el => el.questionID === Number(action.payload.id));
//                 if (found === undefined) {
//                     stateCopy.selectedQuestion = [...stateCopy.selectedQuestion, { ...action.payload.data, isChecked: true }];
//                 }
//                 console.log(stateCopy.selectedQuestion)
//                 stateCopy.deleteIds = stateCopy.deleteIds.filter(el => el.questionID !== Number(action.payload.id));
//                 stateCopy.additionalQuestions.forEach((el) => {
//                     if (el.questionID === Number(action.payload.id)) {
//                         el.isChecked = true
//                     }
//                 })
//             }
//             else {
//                 stateCopy.selectedQuestion = stateCopy.selectedQuestion.filter(el => el.questionID !== Number(action.payload.id));
//                 if (action.payload.testDetailId) {
//                     stateCopy.deleteIds = [...stateCopy.deleteIds, Number(action.payload.testDetailId)];
//                 }
//                 stateCopy.additionalQuestions.forEach(el => {
//                     if (el.questionID === Number(action.payload.id)) {
//                         el.isChecked = false
//                     }
//                 })
//             }
//             return stateCopy;
//         case REMOVE_FROM_SELECTED_QUESTION:
//             stateCopy.selectedQuestion = stateCopy.selectedQuestion.filter(el => el.questionID !== Number(action.payload.questionID));
//             if (action.payload.testDetailId) {
//                 stateCopy.deleteIds = [...stateCopy.deleteIds, Number(action.payload.testDetailId)];
//             }
//             return stateCopy;
//         case RESET_QUESTION:
//             stateCopy.qTitle = '';
//             stateCopy.templates = '';
//             stateCopy.questionOptions = [{ index: 0, questionOption: '', iscorrect: false, optionError: '' },
//             { index: 1, questionOption: '', iscorrect: false, optionError: '' },
//             { index: 2, questionOption: '', iscorrect: false, optionError: '' },
//             { index: 3, questionOption: '', iscorrect: false, optionError: '' }
//             ]
//             return stateCopy;
//         case GET_QUESTION_TYPES:
//             stateCopy.questionType = action.payload;
//             return stateCopy;
//         case GET_QUESTION_LEVELS:
//             stateCopy.questionLevel = action.payload;
//             return stateCopy;
//         case SET_QUES_TITLE:
//             stateCopy.qTitle = action.payload;
//             return stateCopy;
//         case SET_OPTIONS:
//             console.log(action.payload)
//             if (action.payload.key === 0) {
//                 const answersns = stateCopy.questionOptions.map(answer => {
//                     if (answer.index === action.payload.idx) return { index: answer.index, questionOption: action.payload.value, iscorrect: answer.iscorrect, optionError: '' }
//                     return answer
//                 })
//                 stateCopy.questionOptions = answersns;
//             }
//             else if (action.payload.key === 1) {
//                 const answersns = stateCopy.questionOptions.map(answer => {
//                     if (answer.index === action.payload.idx) return { index: answer.index, questionOption: action.payload.value, iscorrect: answer.iscorrect, optionError: '' }
//                     return answer
//                 })
//                 stateCopy.questionOptions = answersns;
//             }
//             else if (action.payload.key === 2) {
//                 const answersns = stateCopy.questionOptions.map(answer => {
//                     if (answer.index === action.payload.idx) return { index: answer.index, questionOption: action.payload.value, iscorrect: answer.iscorrect, optionError: '' }
//                     return answer
//                 })
//                 stateCopy.questionOptions = answersns;
//             }
//             else if (action.payload.key === 3) {
//                 const answersns = stateCopy.questionOptions.map(answer => {
//                     if (answer.index === action.payload.idx) return { index: answer.index, questionOption: action.payload.value, iscorrect: answer.iscorrect, optionError: '' }
//                     return answer
//                 })
//                 stateCopy.questionOptions = answersns;
//             }
//             return stateCopy;
//         case SET_RIGHT_ANSWER:
//             if (Number(action.payload.quesType) === 2) {
//                 stateCopy.questionOptions[action.payload.key].iscorrect = !action.payload.checked;
//             } else {
//                 stateCopy.questionOptions.forEach(el => {
//                     if (el.index === action.payload.idx) {
//                         el.iscorrect = true
//                     } else {
//                         el.iscorrect = false
//                     }
//                 })
//             }
//             return stateCopy;
//         case SET_ERRORS:
//             const answersns = stateCopy.questionOptions.map(answer => {
//                 if (answer.index === action.payload) return { index: answer.index, questionOption: action.payload.value, iscorrect: answer.iscorrect, optionError: `Option can't be empty` }
//                 return answer
//             })
//             stateCopy.questionOptions = answersns;
//             return stateCopy;
//         case SAVED_QUESTION:
//             stateCopy.additionalQuestions = [...stateCopy.additionalQuestions, action.payload];
//             stateCopy.deleteIds = [];
//             return stateCopy;
//         case DELETE_QUESTION:
//             console.log(action.payload)
//             stateCopy.additionalQuestions = stateCopy.additionalQuestions.filter(el => el.questionID !== action.payload.questionID)
//             return stateCopy;
//         case SET_QUESTION_DETAILS:
//             stateCopy.qTitle = action.payload?.question;
//             stateCopy.questionOptions = action.payload?.questionOptions ? action.payload?.questionOptions.map((el, i) => ({ ...el, index: i })) : [{ index: 0, questionOption: '', iscorrect: false, optionError: '' },
//             { index: 1, questionOption: '', iscorrect: false, optionError: '' },
//             { index: 2, questionOption: '', iscorrect: false, optionError: '' },
//             { index: 3, questionOption: '', iscorrect: false, optionError: '' }
//             ];
//             return stateCopy;
//         case GET_TENENT_USERS:
//             stateCopy.tenentUsers = action.payload.map(el => [{ label: el.name, value: el.emailId }][0])
//             return stateCopy;
//         case GET_TEMPLATE:
//             stateCopy.isTemplate = true;
//             stateCopy.editTemplate = action.payload.edit;
//             stateCopy.templateType = action.payload.type;
//             stateCopy.templateId = action.payload.data.templateId;
//             stateCopy.instruction = action.payload.data.additionalInstruction;
//             // stateCopy.templates = 
//             stateCopy.templates = action.payload.data.template;
//             return stateCopy;
//         case CLOSE_INVITE_MODAL:
//             stateCopy.isTemplate = false;
//             stateCopy.editTemplate = false;
//             stateCopy.instruction = ''
//             stateCopy.templates = '';
//             return stateCopy;
//         case CHANGE_TEMPLATE:
//             stateCopy.instruction = action.payload;
//             return stateCopy;
//         case UPDATE_TEMPLATE:
//             stateCopy.templates = action.payload.template;
//             stateCopy.instruction = action.payload.additionalInstruction;
//             stateCopy.editTemplate = false;
//             return stateCopy;
//         default:
//             return stateCopy;
//     }
// }
