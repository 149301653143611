import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { useTranslation } from 'react-i18next';


export default function TestDetailModal({ isLoading, isDetail, setIsDetail, testDetails }) {
    const { t } = useTranslation();
    return (
        <div>
            <Modal
                show={isDetail}
                size={window.innerWidth > 767 ? "lg" : 'md'}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                dialogClassName={"test-details"}
            >
                <Modal.Body>
                    <div className='sticky-close'>
                        <img src='/images/close.svg' alt='' onClick={() => setIsDetail(false)} />
                    </div>
                    {
                        isLoading ?
                            'Loading... ' :
                            <div className='d-flex flex-wrap'>
                                <div className='selected-test'>
                                    <div className='selected-list-header'>
                                        <h4>{t('Assessment Summary')}</h4>
                                        <h5>{testDetails?.testDetails?.tests?.length} {t('test')} | {testDetails?.questionCount} Qs | {testDetails?.duration} {t('Mins')}</h5>
                                        {/* <p>Level:Advanced</p> */}
                                    </div>
                                    <div className='selected-list'>
                                        <h1>{t('Selected Tests')}</h1>

                                        {

                                            <div>
                                                {
                                                    testDetails?.testDetails?.tests && testDetails?.testDetails?.tests?.map((el, index) => {
                                                        return (
                                                            <div className='selected-list-item'>
                                                                <div className='d-flex'>

                                                                    <div>
                                                                        <h1>{el.testName}</h1>
                                                                        <p>{el.testLevelName} | {el.questionCount} Qs | {el.duration} {t('Mins')} | {el.languageCode}</p>
                                                                    </div>
                                                                </div>
                                                                {/* <img src='/images/delete-icon.svg' onClick={() => removeSkill(el)} alt='' width={16} /> */}
                                                            </div>

                                                        )
                                                    })}
                                            </div>

                                        }
                                        <div className='mt-4'>
                                            <h5>{t('Additional questions')}</h5>
                                            <p>{testDetails?.testDetails?.questions?.length ? `${testDetails?.testDetails?.questions?.length} Qs` : 'No questions added yet'}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='selected-question'>
                                    <h3>{t('Additional Question')}</h3>
                                    <p>{t('These questions are shown before the test')}</p>
                                    {testDetails?.testDetails?.questions?.length > 0 ?
                                        testDetails?.testDetails?.questions && testDetails?.testDetails?.questions?.map((el, i) => {
                                            return (
                                                <div className='question d-flex justify-content-between align-items-center' key={el?.questionID}>
                                                    <h4 className='elipsis-2' dangerouslySetInnerHTML={{ __html: el?.question }} />
                                                    <div>
                                                        {el?.questionTypeName === 'Multiple Choice' ?
                                                            <img src='/images/mcq.svg' alt='' /> :
                                                            <img src='/images/essay.svg' alt='' width={20} />
                                                        }
                                                        <span> {el?.questionTypeName}</span>
                                                    </div>
                                                    <div>
                                                        <img src='/images/info.svg' className='cursor-pointer' alt='' />&nbsp;&nbsp;&nbsp;
                                                        {/* <img src='/images/delete-icon-gray.svg' className='cursor-pointer' onClick={() => removeQues(el)} alt='' /> */}
                                                    </div>
                                                </div>
                                            )
                                        }) :
                                        <p>0 Qs 0 {t('Mins')}</p>
                                    }
                                </div>
                            </div>
                    }
                </Modal.Body>

            </Modal>
        </div>
    )
}
