// test/list-my-test
import { returnErrors } from './errorAction';
import { RESET_MY_TESTS, GET_MY_TESTS, GET_NEXT_MY_TESTS, NO_MY_TESTS, CREATE_MY_TEST, UPDATE_MY_TEST, GET_QUESTION_SOURCE, GET_SKILLS, GET_TOPICS, EMPTY_TOPICS, GET_COUNT } from '../Types/types';
import { getApiCall, postApiCall } from '../../utils/request';

export const resetMYTest = () => async (dispatch) => {
    try {
        dispatch({ type: RESET_MY_TESTS });
    }
    catch (error) {
        returnErrors(dispatch, error.response?.data?.responseMessages[0]?.message, error?.response?.status);
    }
}

export const getMyTests = (testType, query, page, row) => async (dispatch) => {
    try {
        const response = await getApiCall(`mytest/list-test?TestType=${testType}&SearchText=${query}&PageNumber=${page}&DisplayRows=${row}`);
        console.log(response.data)
        if (response?.status === 200) {
            dispatch({ type: GET_MY_TESTS, payload: response.data });
        } else if (response?.status === 204) {
            dispatch({ type: NO_MY_TESTS });
        }
        else {
            dispatch({ type: NO_MY_TESTS });
            returnErrors(dispatch, response?.data?.responseMessages[0]?.message, response?.status)
        }
    }
    catch (error) {
        returnErrors(dispatch, error.response?.data?.responseMessages[0]?.message, error?.response?.status);
    }
}

export const getNextMyTests = (testType, query, page, row) => async (dispatch) => {
    try {
        const response = await getApiCall(`mytest/list-test?TestType=${testType}&SearchText=${query}&PageNumber=${page}&DisplayRows=${row}`);
        console.log(response.data)
        if (response?.status === 200) {
            dispatch({ type: GET_NEXT_MY_TESTS, payload: response.data });
        } else if (response?.status === 204) {
            dispatch({ type: NO_MY_TESTS });
        }
        else {
            // returnErrors(dispatch, response?.data?.responseMessages[0]?.message, response?.status)
        }
    }
    catch (error) {
        returnErrors(dispatch, error.response?.data?.responseMessages[0]?.message, error?.response?.status);
    }
}



export const savedTest = (obj) => async (dispatch) => {
    try {
        const response = await postApiCall(`mytest/create-test`, obj);
        console.log(response.data)
        if (response?.status === 200) {
            returnErrors(dispatch, 'Test has been created', 200);
            return dispatch({ type: CREATE_MY_TEST, payload: response.data });
        }
        else {
            returnErrors(dispatch, response?.data?.responseMessages[0]?.message, response?.status)
        }
    }
    catch (error) {
        returnErrors(dispatch, error.response?.data?.responseMessages[0]?.message, error?.response?.status);
    }
}

export const updateTest = (obj) => async (dispatch) => {
    try {
        const response = await postApiCall(`mytest/update-test`, obj);
        console.log(response.data)
        if (response?.status === 200) {
            returnErrors(dispatch, 'Test has been updated', 200);
            return dispatch({ type: UPDATE_MY_TEST, payload: response.data });
        }
        else {
            returnErrors(dispatch, response?.data?.responseMessages[0]?.message, response?.status)
        }
    }
    catch (error) {
        returnErrors(dispatch, error.response?.data?.responseMessages[0]?.message, error?.response?.status);
    }
}

export const getQuestionSource = () => async (dispatch) => {
    try {
        const response = await getApiCall(`mytest/list-question-source`);
        if (response.status === 200) {
            return dispatch({ type: GET_QUESTION_SOURCE, payload: response.data.questionSources })
        }
    }
    catch (error) {
        returnErrors(dispatch, 'Something went wrong', error?.response?.status);
    }
}

export const getSkills = (sourceType) => async (dispatch) => {
    try {
        const response = await getApiCall(`mytest/list-knowledge-area?QuestionSourceType=${sourceType}`);
        console.log(response.data.data)
        if (response.status === 200) {
            return dispatch({ type: GET_SKILLS, payload: response.data.knowledgeAreas })
        }
    }
    catch (error) {
        returnErrors(dispatch, 'Something went wrong', error?.response?.status);
    }
}

export const getSkillTopics = (sourceType, skillId) => async (dispatch) => {
    try {
        const response = await getApiCall(`mytest/list-topic?QuestionSourceType=${sourceType}&KnowledgeAreaId=${skillId}`);
        console.log(response.data.data)
        if (response.status === 200) {
            return dispatch({ type: GET_TOPICS, payload: response.data.topics })
        }
    }
    catch (error) {
        returnErrors(dispatch, 'Something went wrong', error?.response?.status);
    }
}

export const emptyTopics = () => async (dispatch) => {
    dispatch({
        type: EMPTY_TOPICS
    })
}

export const getQuestionCount = (sourceType, skillId, topicId, level) => async (dispatch) => {
    try {
        const response = await getApiCall(`mytest/get-question-count?QuestionSourceType=${sourceType}&KnowledgeAreaId=${skillId}&TopicId=${topicId}&QuestionLevelId=${level}`);
        console.log(response.data)
        if (response.status === 200) {
            return dispatch({ type: GET_COUNT, payload: response.data.count })
        }
    }
    catch (error) {
        returnErrors(dispatch, 'Something went wrong', error?.response?.status);
    }
}

// export const getTestById = (id) => async (dispatch) => {
//     try {
//         const response = await getApiCall(`mytest/get-test?TestId=${id}`);
//         console.log(response.data)
//         if (response.status === 200) {
//             return dispatch({ type: GET_TEST_BY_ID, payload: response.data.myTest })
//         }
//     }
//     catch (error) {
//         returnErrors(dispatch, 'Something went wrong', error?.response?.status);
//     }
// }